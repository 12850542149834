import { useEffect, useState } from "react"

import { getQ11ePreview } from "../../../api/Q11eContentManager"

const useQ11ePreview = (name: string, version: string): IQ11ePreview | undefined => {
    const [preview, setPreview] = useState<IQ11ePreview>()

    useEffect(() => {
        getQ11ePreview(name, +version).then(setPreview)
    }, [name, version])

    return preview
}

export default useQ11ePreview
