import { ChangeEvent, FormEvent, KeyboardEvent, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import {
    Dialog,
    FormControl,
    FormHelperText,
    IconButton,
    InputAdornment,
    Divider,
    InputLabel,
    OutlinedInput,
    TextField,
    Checkbox,
    FormControlLabel,
} from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"

import { auth } from "../../../firebase"
import Logo from "../../Logo/Logo"
import { Error } from "./model"
import { createUser, resolveError } from "./functions"

interface Props {
    loggedIn: boolean
}

const PageSignUp = ({ loggedIn }: Props) => {
    const [loading, setLoading] = useState(false)
    const [firstName, setFirstName] = useState("")
    const [surname, setSurname] = useState("")
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [checkedPrivacy, setCheckedPrivacy] = useState(false)
    const [checkedMarketing, setCheckedMarketing] = useState(false)
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [modalOpen, setModalOpen] = useState(false)
    const [marketingModalOpen, setMarketingModalOpen] = useState(false)

    const handleFirstNameChange = (event: ChangeEvent<HTMLInputElement>) => setFirstName(event.target.value)

    const handleSurnameChange = (event: ChangeEvent<HTMLInputElement>) => setSurname(event.target.value)

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value.toLowerCase())

    const handleEmailKeyDown = (event: KeyboardEvent<HTMLInputElement>) => {
        if (event.key === " ") {
            event.preventDefault()
        }
    }

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)

    const handlePrivacyCheckedChange = (event: ChangeEvent<HTMLInputElement>) => setCheckedPrivacy(event.target.checked)

    const handleMarketingCheckedChange = (event: ChangeEvent<HTMLInputElement>) => setCheckedMarketing(event.target.checked)

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()

        if (loading) return
        setLoading(true)

        try {
            const userCredential = await auth.createUserWithEmailAndPassword(email, password)
            const user = userCredential.user!
            await user.updateProfile({
                displayName: `${firstName.trim()} ${surname.trim()}`,
            })
            await createUser(user.uid, user.email!, firstName, surname, checkedMarketing)
            await user.sendEmailVerification()
        } catch ({ code }) {
            setLoading(false)
            setError(resolveError(code as string))
            setModalOpen(true)
            return
        }
    }

    if (loggedIn) {
        return <Redirect to={"/"} />
    }

    return (
        <>
            <div className="SignUp bg-lightblue">
                <Logo />
                <div className="SignUp__form rounded-borders bg-white">
                    <h1 className="SignUp__title">Opret din profil</h1>
                    <form className="la-form" onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            required
                            value={firstName}
                            autoComplete="given-name"
                            name="given-name"
                            id="given-name"
                            label="Fornavn"
                            onChange={handleFirstNameChange}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            required
                            value={surname}
                            autoComplete="family-name"
                            name="family-name"
                            id="family-name"
                            label="Efternavn"
                            onChange={handleSurnameChange}
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            required
                            value={email}
                            autoComplete="email"
                            name="email"
                            id="email"
                            label="E-mail"
                            onChange={handleEmailChange}
                            onKeyDown={handleEmailKeyDown}
                            type="email"
                            margin="normal"
                            fullWidth
                        />
                        <FormControl variant="outlined" margin="normal" fullWidth required>
                            <InputLabel htmlFor="new-password">Adgangskode</InputLabel>
                            <OutlinedInput
                                type={passwordVisible ? "text" : "password"}
                                value={password}
                                autoComplete="new-password"
                                name="new-password"
                                id="new-password"
                                label="Adgangskode"
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                            <FormHelperText>Min. 6 tegn</FormHelperText>
                            {/* <Divider style={{ marginTop: "30px", marginBottom: "30px" }} /> */}

                            <FormControlLabel style={{ marginTop: "10px", }} control={<Checkbox required checked={checkedPrivacy} onChange={handlePrivacyCheckedChange} />} label={<div>Jeg accepterer Adjø's <a
                                href="https://adjo.dk/privatlivs-politik/"
                                target="_blank"
                                rel="noopener noreferrer"
                                style={{ textAlign: "center", textDecoration: "underline" }}
                            >
                                brugervilkår og privatlivspolitik
                            </a></div>} />
                            <FormControlLabel control={<Checkbox checked={checkedMarketing} onChange={handleMarketingCheckedChange} />} label={<div>Jeg accepterer at modtage Adjø
                                <button type="button" style={{ textAlign: "center", textDecoration: "underline" }} onClick={() => setMarketingModalOpen(true)}>
                                    nyheder og tilbud
                                </button>
                              {/* <div style={{color: "dimgray", fontSize: "small"}}>Du kan til enhver tid framelde dig igen.</div>   */}
                            </div>}

                            />
                            
                            
                            
                            
                        </FormControl>
                        <input
                            type="submit"
                            className="Button"
                            value={loading ? "Vent..." : "Opret profil"}
                            disabled={loading}
                            style={{ cursor: loading ? "wait" : "pointer" }}
                        />
                    </form>
                    <div>
                        <p className="SignUp__alternate">
                            Har du allerede en profil? <Link to="/login">Log ind</Link>
                        </p>
                    </div>
                </div>
            </div>
            <Dialog
                className="la-dialog"
                open={modalOpen}
                onClose={() => {
                    setError(undefined)
                    setModalOpen(false)
                }}
            >
                <div className="la-modal">
                    <h2>{error?.title}</h2>
                    <p>{error?.txt}</p>
                    <div className="la-modal__actions">
                        <button
                            className="Button"
                            autoFocus={true}
                            aria-labelledby="close-modal"
                            onClick={() => setModalOpen(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Dialog>
            <Dialog
                className="la-dialog"
                open={marketingModalOpen}
                onClose={() => {
                    setMarketingModalOpen(false)
                }}
            >
                <div className="la-modal">
                    <h2>Adjø nyheder og tilbud</h2>
                    <p>Ved at acceptere Adjø nyheder og tilbud, siger du ja til information, som hjælper dig til en endnu bedre brugeroplevelse. Samtidig siger du ja til at modtage nyheder og eventuelle tilbud fra Adjø. Dette kan også inkludere tilbud, som vi kommunikerer på vegne af en af vores partnere.
                        <br /><br />
                        Du kan til enhver tid framelde dig via link i mails eller ved at sende en mail til support@adjo.dk


                    </p>
                    <div style={{ margin: "auto" }}>
                        <button
                            className="Button"
                            autoFocus={true}
                            aria-labelledby="close-modal"
                            onClick={() => setMarketingModalOpen(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PageSignUp
