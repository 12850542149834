import { firestore } from "../firebase"
import firebase from "firebase/app"
import FieldValue = firebase.firestore.FieldValue

const getStateDocRef = (userId: string, q11eName: string, q11eVersion: string) =>
    firestore
        .collection("users")
        .doc(userId)
        .collection("q11eState")
        .doc(q11eName + "_" + q11eVersion)

export const restart = async (userId: string, q11eName: string, q11eVersion: string) => {
    const q11eStateDocRef = getStateDocRef(userId, q11eName, q11eVersion)

    try {
        q11eStateDocRef
            .collection("route")
            .get()
            .then((querySnapshot) => querySnapshot.docs.forEach((documentSnapshot) => documentSnapshot.ref.delete()))

        return q11eStateDocRef.set({ startTime: new Date(), name: q11eName, version: +q11eVersion })
    } catch (e) {
        console.error(e)
    }
}

export const updateStartTime = (userId: string, q11eName: string, q11eVersion: string) => {
    try {
        getStateDocRef(userId, q11eName, q11eVersion).set(
            { startTime: new Date(), name: q11eName, version: +q11eVersion },
            { merge: true }
        )
    } catch (e) {
        console.error(e)
    }
}

export const updateEndTime = async (userId: string, q11eName: string, q11eVersion: string) => {
    try {
        await getStateDocRef(userId, q11eName, q11eVersion).set({ endTime: new Date() }, { merge: true })
    } catch (e) {
        console.error(e)
    }
}

export const isQ11eStarted = async (userId: string, q11eName: string, q11eVersion: string) => {
    const q11eStateDocRef = getStateDocRef(userId, q11eName, q11eVersion)
    const docSnap = await q11eStateDocRef.get()
    if (!docSnap.exists) return false

    const startQuestion = await q11eStateDocRef.collection("route").doc("q1").get()
    return startQuestion.exists
}

export const isQ11eCompleted = async (userId: string, q11eId: string): Promise<boolean> => {
    const docSnap = await firestore.collection("users").doc(userId).collection("q11eState").doc(q11eId).get()

    if (!docSnap.exists) return false
    const q11eState = docSnap.data() as IQ11eLabelState
    return !!q11eState.endTime
}

export const setRadioAnswer = (
    userId: string,
    q11eName: string,
    q11eVersion: string,
    questionId: string,
    answer: IQ11eRadioAnswer
) => {
    try {
        getStateDocRef(userId, q11eName, q11eVersion)
            .collection("route")
            .doc(questionId)
            .set({ radioAnswerId: answer.id, radioAnswerTxt: answer.txt }, { merge: true })
    } catch (e) {
        console.error(e)
    }
}

export const setRadiosAnswer = (
    userId: string,
    q11eName: string,
    q11eVersion: string,
    questionId: string,
    answer: IQ11eRadiosAnswer,
    label: string
) => {
    try {
        getStateDocRef(userId, q11eName, q11eVersion)
            .collection("route")
            .doc(questionId)
            .set({ radiosAnswerId: answer.id, label }, { merge: true })
    } catch (e) {
        console.error(e)
    }
}

export const setDistributionAnswer = (
    userId: string,
    q11eName: string,
    q11eVersion: string,
    questionId: string,
    answer: IQ11eDistributionAnswer,
    distribution: Array<number>
) => {
    try {
        getStateDocRef(userId, q11eName, q11eVersion)
            .collection("route")
            .doc(questionId)
            .set({ distribution, answerId: answer.id }, { merge: true })
    } catch (e) {
        console.error(e)
    }
}

export const setTextAnswer = (
    userId: string,
    q11eName: string,
    q11eVersion: string,
    question: IQ11eQuestion,
    answer: IQ11eTextAnswer,
    txt: string
) => {
    const q11eStateDocRef = getStateDocRef(userId, q11eName, q11eVersion)
    const routeColRef = q11eStateDocRef.collection("route")

    try {
        const batch = firestore.batch()

        batch.set(routeColRef.doc(question.id), {
            textAnswer: txt,
        })

        if (answer.label) {
            const labels = { [answer.label]: txt }
            batch.set(q11eStateDocRef, { labels: labels }, { merge: true })
        }

        if (answer.deleteLabels) {
            const labels: { [label: string]: FieldValue } = {}
            for (const label of answer.deleteLabels) {
                labels[label] = FieldValue.delete()
            }
            batch.set(q11eStateDocRef, { labels: labels }, { merge: true })
        }

        if (question.mutexQuestionId) {
            // TODO: support an array of mutex IDs
            batch.delete(routeColRef.doc(question.mutexQuestionId))
        }

        return batch.commit()
    } catch (e) {
        console.error(e)
    }
}

export const deleteTextAnswer = (
    userId: string,
    q11eName: string,
    q11eVersion: string,
    questionId: string,
    answer: IQ11eTextAnswer
) => {
    const q11eStateDocRef = getStateDocRef(userId, q11eName, q11eVersion)
    const questionDocRef = q11eStateDocRef.collection("route").doc(questionId)

    try {
        const batch = firestore.batch()

        batch.delete(questionDocRef)

        if (answer.label) {
            const labels = { [answer.label]: firebase.firestore.FieldValue.delete() }
            batch.set(q11eStateDocRef, { labels: labels }, { merge: true })
        }

        return batch.commit()
    } catch (e) {
        console.error(e)
    }
}

export const setMultiTextAnswer = async (
    userId: string,
    q11eName: string,
    q11eVersion: string,
    question: IQ11eQuestion,
    answer: IQ11eMultiTextAnswer,
    multiTxt: Array<string | null>
): Promise<IQ11eLabelState> => {
    const q11eStateDocRef = getStateDocRef(userId, q11eName, q11eVersion)
    const routeColRef = q11eStateDocRef.collection("route")

    const batch = firestore.batch()

    if (answer.labels) {
        const labels: { [label: string]: string | firebase.firestore.FieldValue } = {}
        answer.labels.forEach((label, idx) => {
            if (multiTxt[idx] && multiTxt[idx] !== null) {
                labels[label] = multiTxt[idx] as string
            } else {
                labels[label] = firebase.firestore.FieldValue.delete()
            }
        })
        batch.set(q11eStateDocRef, { labels: labels }, { merge: true })
    }

    const q11eRouteDocRef = routeColRef.doc(question.id)

    if (multiTxt.every((txt) => txt === null)) {
        /* If all entries of multiTxt are null: delete the document */
        batch.delete(q11eRouteDocRef)
    } else {
        batch.set(q11eRouteDocRef, {
            multiTextAnswer: multiTxt,
        })
        if (question.mutexQuestionId) {
            batch.delete(routeColRef.doc(question.mutexQuestionId))
        }
    }

    await batch.commit()

    const q11eStateDocSnap = await q11eStateDocRef.get()
    return q11eStateDocSnap.data() as IQ11eLabelState
}
