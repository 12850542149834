import TopicPage from "../Topic/TopicPage"
import { PUBLIC_BUCKET } from "../../routes/paths"
import WithCategoriesLife from "../hoc/WithCategoriesLife"

interface Props {
    categoriesLife: any[]
}

const PageLife = ({ categoriesLife }: Props) => (
    <TopicPage
        title="Mit liv"
        text="Her har du mulighed for at angive diverse praktiske oplysninger omkring dig og dit liv. Denne sektion kan du vælge at dele med en eller flere af dine kære."
        illustration={`${PUBLIC_BUCKET}/illustrations/mit_liv_v2.png`}
        color="pink"
        topics={categoriesLife}
    />
)

export default WithCategoriesLife(PageLife)
