import ProfileIntro from "../Intro/ProfileIntro"
import ProfileForm from "../Profile/ProfileForm"
import ProfileActions from "../Profile/ProfileActions"

const PageProfile = () => (
    <>
        <ProfileIntro />
        <div className="profile-wrapper padding-xy margin-bottom rounded-borders">
            <h3 style={{ marginBottom: 30 }}>
                Redigér dine
                <br />
                oplysninger
            </h3>
            <div className="profile-form-wrapper">
                <ProfileForm />
            </div>
        </div>
        <div className="profile-wrapper padding-xy margin-bottom rounded-borders">
            <h3>Avanceret</h3>
            <div className="profile-form-wrapper">
                <ProfileActions />
            </div>
        </div>
    </>
)

export default PageProfile
