import { useEffect, useState } from "react"

import { getStore } from "../../../api/Q11eContentManager"

const useQ11eAmount = (paramString?: string): number | undefined => {
    const [amount, setAmount] = useState<number>()

    useEffect(() => {
        if (!paramString) return

        let paramArray = paramString.split("_")
        if (paramArray.length !== 2) return
        
        getStore(paramString).then((store) => {
            store && setAmount(store.amount)
        })
    }, [paramString])

    return amount
}

export default useQ11eAmount
