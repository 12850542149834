import Q11eOverviewChart from "./Q11eOverviewChart"

const renderOverview = (overview: IQ11eOverview, routeState?: IQ11eRouteState, labels?: IQ11eLabels) => {
    if (!routeState || !labels) return ""

    switch (overview.type) {
        case "childrenEqual":
            return renderOverviewChildrenEqual(routeState, labels)
        case "childrenLeast":
            return renderOverviewChildrenLeast(overview, routeState, labels)
        case "childrenMost":
            return renderOverviewChildrenMost(overview, routeState, labels)
        case "heirsEqual":
            return renderOverviewHeirsEqual(routeState, labels)
        case "heirsSkewed":
            return renderOverviewHeirsSkewed(overview, routeState, labels)
        default:
            return "Unhandled overview case..."
    }
}

const renderOverviewChildrenEqual = (routeState: IQ11eRouteState, labels: IQ11eLabels) => {
    const children = [labels["%1stChild%"], labels["%2ndChild%"], labels["%3rdChild%"], labels["%4thChild%"]].filter(
        (child) => Boolean(child)
    )

    let chart
    let caption

    if (children.length === 2) {
        chart = [50, 50]
        caption = `${children[0]} og ${children[1]} deler arven ligeligt.`
    } else if (children.length === 3) {
        chart = [33.3, 33.3, 33.3]
        caption = `${children[0]}, ${children[1]} og ${children[2]} deler arven ligeligt og de arver dermed en tredjedel hver.`
    } else if (children.length === 4) {
        chart = [25, 25, 25, 25]
        caption = `${children[0]}, ${children[1]}, ${children[2]} og ${children[3]} deler arven ligeligt og arver dermed 25% hver.`
    }

    if (!chart || !caption) {
        return "renderOverviewChildrenEqual: unhandled case..."
    }

    return <Q11eOverviewChart values={chart} labels={children} caption={caption} />
}

const renderOverviewChildrenLeast = (overview: IQ11eOverview, routeState: IQ11eRouteState, labels: IQ11eLabels) => {
    if (!overview.dependency) {
        return "overview.dependency is undefined"
    }

    const children = [labels["%1stChild%"], labels["%2ndChild%"], labels["%3rdChild%"], labels["%4thChild%"]].filter(
        (child) => Boolean(child)
    )

    const selectedChild = labels[(routeState[overview.dependency] as IQ11eRouteStateRadiosAnswer).label]

    const otherChildren = children.filter((child) => child !== selectedChild)

    let chart
    let caption

    if (children.length === 2) {
        chart = [12.5, 87.5]
        caption = `${selectedChild} arver mindst muligt (tvangsarv på 12,5%), mens ${otherChildren[0]} arver den resterende arv (87,5%).`
    } else if (children.length === 3) {
        chart = [8.3, 45.8, 45.8]
        caption = `${selectedChild} arver mindst muligt (tvangsarv på 8,3%), mens den resterende arv deles mellem ${otherChildren[0]} og ${otherChildren[1]} (45,8% hver).`
    } else if (children.length === 4) {
        chart = [6.3, 31.3, 31.3, 31.3]
        caption = `${selectedChild} arver mindst muligt (tvangsarv på 6,3%), mens den resterende arv deles mellem ${otherChildren[0]}, ${otherChildren[1]} og ${otherChildren[1]} (31,3% hver).`
    }

    if (!chart || !caption) {
        return "renderOverviewChildrenLeast: unhandled case..."
    }

    return <Q11eOverviewChart values={chart} labels={[selectedChild, ...otherChildren]} caption={caption} />
}

const renderOverviewChildrenMost = (overview: IQ11eOverview, routeState: IQ11eRouteState, labels: IQ11eLabels) => {
    if (!overview.dependency) {
        return "overview.dependency is undefined"
    }

    const children = [labels["%1stChild%"], labels["%2ndChild%"], labels["%3rdChild%"], labels["%4thChild%"]].filter(
        (child) => Boolean(child)
    )

    const selectedChild = labels[(routeState[overview.dependency] as IQ11eRouteStateRadiosAnswer).label]

    const otherChildren = children.filter((child) => child !== selectedChild)

    let chart
    let caption

    if (children.length === 2) {
        chart = [87.5, 12.5]
        caption = `${selectedChild} arver mest muligt, mens ${otherChildren[0]} udelukkende arver tvangsarven på 12,5%.`
    } else if (children.length === 3) {
        chart = [83.3, 8.3, 8.3]
        caption = `${selectedChild} arver mest muligt, mens ${otherChildren[0]} og ${otherChildren[1]} udelukkende arver deres tvangsarv på 8,3% hver.`
    } else if (children.length === 4) {
        chart = [81.1, 6.3, 6.3, 6.3]
        caption = `${selectedChild} arver mest muligt, mens ${otherChildren[0]}, ${otherChildren[1]} og ${otherChildren[2]} udelukkende arver deres tvangsarv på 6,3% hver.`
    }

    if (!chart || !caption) {
        return "renderOverviewChildrenMost: unhandled case..."
    }

    return <Q11eOverviewChart values={chart} labels={[selectedChild, ...otherChildren]} caption={caption} />
}

const renderOverviewHeirsEqual = (routeState: IQ11eRouteState, labels: IQ11eLabels) => {
    const heirs = [labels["%1stHeir%"], labels["%2ndHeir%"], labels["%3rdHeir%"], labels["%4thHeir%"]].filter((heir) =>
        Boolean(heir)
    )

    let chart
    let caption

    if (heirs.length === 2) {
        chart = [50, 50]
        caption = `${heirs[0]} og ${heirs[1]} deler arven ligeligt.`
    } else if (heirs.length === 3) {
        chart = [33.3, 33.3, 33.3]
        caption = `${heirs[0]}, ${heirs[1]} og ${heirs[2]} deler arven ligeligt og de arver dermed en tredjedel hver.`
    } else if (heirs.length === 4) {
        chart = [25, 25, 25, 25]
        caption = `${heirs[0]}, ${heirs[1]}, ${heirs[2]} og ${heirs[3]} deler arven ligeligt og arver dermed 25% hver.`
    }

    if (!chart || !caption) {
        return "renderOverviewHeirsEqual: unhandled case..."
    }

    return <Q11eOverviewChart values={chart} labels={heirs} caption={caption} />
}

const renderOverviewHeirsSkewed = (overview: IQ11eOverview, routeState: IQ11eRouteState, labels: IQ11eLabels) => {
    if (!overview.dependency) {
        return "overview.dependency is undefined"
    }

    const distribution = (routeState[overview.dependency] as IQ11eRouteStateDistributionAnswer).distribution

    const heirs = [labels["%1stHeir%"], labels["%2ndHeir%"], labels["%3rdHeir%"], labels["%4thHeir%"]].filter((heir) =>
        Boolean(heir)
    )

    return <Q11eOverviewChart values={distribution} labels={heirs} />
}

const distributionSumsTo100 = (distribution: Array<number>) =>
    distribution.reduce((previousValue, currentValue) => previousValue + currentValue, 0) === 100

const resolveReplacements = (str: string, replacements: IQ11eLabels | undefined): string => {
    if (!replacements) return str

    return str.replace(/%[A-Za-z0-9_æøåÆØÅ]+%/g, function (all) {
        return replacements[all] || all
    })
}

const resolveNextQuestionId = (branches: Array<IQ11eBranch>, labelState: IQ11eLabelState): string | undefined => {
    const [branch, ...rest] = branches
    if (branch.label) {
        const userLabel = labelState.labels?.[branch.label]
        switch (branch.predicate) {
            case "isDefined":
                if (userLabel) {
                    if (typeof branch.yield === "string") {
                        return branch.yield
                    } else {
                        return resolveNextQuestionId([...branch.yield, ...rest], labelState)
                    }
                }
                break
            case "isNotDefined":
                if (!userLabel) {
                    if (typeof branch.yield === "string") {
                        return branch.yield
                    } else {
                        return resolveNextQuestionId([...branch.yield, ...rest], labelState)
                    }
                }
                break
            default:
                return undefined
        }
    } else if (branch.labels) {
        switch (branch.predicate) {
            case "noneAreDefined":
                const noneAreDefined = branch.labels.every((label) => labelState.labels?.[label] === undefined)
                if (noneAreDefined) {
                    if (typeof branch.yield === "string") {
                        return branch.yield
                    } else {
                        return resolveNextQuestionId([...branch.yield, ...rest], labelState)
                    }
                }
                break
            // TODO: test "allAreDefined"
            case "allAreDefined":
                const allAreDefined = branch.labels.every((label) => !!labelState.labels?.[label])
                if (allAreDefined) {
                    if (typeof branch.yield === "string") {
                        return branch.yield
                    } else {
                        return resolveNextQuestionId([...branch.yield, ...rest], labelState)
                    }
                }
                break
            default:
                return undefined
        }
    } else {
        /* The default case of the branching. */
        if (typeof branch.yield === "string") {
            return branch.yield
        } else {
            return resolveNextQuestionId([...branch.yield, ...rest], labelState)
        }
    }

    return resolveNextQuestionId(rest, labelState)
}

export { distributionSumsTo100, renderOverview, resolveNextQuestionId, resolveReplacements }
