import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { ArrowBackRounded, LockRounded } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import { Dialog, Typography } from "@mui/material"
import classNames from "classnames"

import { createPayment, getLink, handleZeroPayment } from "../../api/PaymentManager"
import useQ11eStore from "../hooks/Q11e/useQ11eStore"
import InlineLoader from "../Loader/InlineLoader"
import Guide from "../Guide/Guide"
import { DOCUMENT } from "../../routes/paths"
import { cloudfunctions } from "../../firebase"
import useQ11eAmount from "../hooks/Q11e/useQ11eAmount"

interface Params {
    documentName: string
    documentVersion: string
    extraParam?: string
}

const PageBuy = () => {
    const history = useHistory()
    const { documentName, documentVersion, extraParam } = useParams<Params>()
    const q11eStore = useQ11eStore(documentName, documentVersion)
    const q11eAmount = useQ11eAmount(extraParam)
    const { enqueueSnackbar } = useSnackbar()

    const [isAwaitingLink, setAwaitingLink] = useState(false)
    const [guideModalOpen, setGuideModalOpen] = useState(false)
    const [accepted1, setAccepted1] = useState(false)
    const [accepted2, setAccepted2] = useState(false)
    const [amount, setAmount] = useState<number>()
    const [voucher, setVoucher] = useState("")
    const [isApplyingVoucher, setApplyingVoucher] = useState(false)
    const [voucherApplied, setVoucherApplied] = useState(false)
    const [appliedVoucher, setAppliedVoucher] = useState<string>()

    useEffect(() => {
        if (!q11eStore) return
        if (extraParam && !q11eAmount) return
        
        setAmount(q11eAmount || q11eStore.amount)
    }, [q11eStore, q11eAmount, extraParam])

    const handleVoucherChange = (event: ChangeEvent<HTMLInputElement>) => {
        setVoucher(event.target.value)
    }

    const handleVoucherSubmit = async () => {
        if (voucherApplied || isApplyingVoucher) return
        setApplyingVoucher(true)
        setAppliedVoucher(voucher)
        try {
            let q11eId: string

            if(extraParam){
                q11eId = extraParam
            }else{
                q11eId = documentName + "_" + documentVersion
            }

            const response = await cloudfunctions.httpsCallable("getVoucherAmount_v2")({
                voucherName: voucher,
                q11eId: q11eId
            })
            const newAmount: number = response.data
            setVoucherApplied(true)
            setAmount(newAmount)
            enqueueSnackbar(`Rabat på ${amount! - newAmount} DKK aktiveret.`)
        }  
        catch (e) {
            console.error(e)
            enqueueSnackbar((e as Error).message)
        } finally {
            setApplyingVoucher(false)
        }
    }

    const handleBuyClicked = async (event: FormEvent) => {
        event.preventDefault()
        if (isAwaitingLink) return
        setAwaitingLink(true)

        const continueUrl = `${window.location.origin}${DOCUMENT}/${documentName}/${documentVersion}`

        if(!amount){
            const orderCompleted = await handleZeroPayment(documentName, +documentVersion, appliedVoucher)
            if(!orderCompleted){
                enqueueSnackbar("Din ordre kunne ikke gennemføres. Prøv igen senere.")
                setAwaitingLink(false)
                return
            }
            window.location.href = continueUrl
        }else{
            const paymentId = await createPayment(documentName, +documentVersion, appliedVoucher)
            window.location.href = await getLink(paymentId, amount!, continueUrl)
        }
        
    }

    const buyBtnClasses = classNames("Button Button--green", {
        "Button--icon": !isAwaitingLink,
        "Button--loading-inline": isAwaitingLink,
    })

    const voucherFieldClasses = classNames("la-input la-input--small", {
        "la-input--filled": !!voucher,
    })

    const applyVoucherBtnClasses = classNames("Button", {
        "Button--loading-inline": isApplyingVoucher,
    })

    // if amount is undefined return null
    if (!q11eStore || amount === undefined) return null

    return (
        <>
            <button className="la-fab margin-bottom" onClick={history.goBack}>
                <ArrowBackRounded />
            </button>
            <div className="q11e-storefront padding-xy margin-bottom">
                <h3>{q11eStore.title}</h3>
                <p className="q11e-storefront__txt">{q11eStore.body1}</p>
                <p className="q11e-storefront__txt q11e-storefront__txt--amount">kr. {amount},00 inkl. moms</p>
                <p className="q11e-storefront__txt">{q11eStore.body2}</p>
                <form className="la-form" onSubmit={handleBuyClicked}>
                    <div className="q11e-storefront__checkbox">
                        <input
                            type="checkbox"
                            id="accept-1"
                            name="accept-1"
                            value="accept-1"
                            required
                            checked={accepted1}
                            onChange={(evt) => setAccepted1(!!evt.currentTarget.checked)}
                        />
                        <label htmlFor="accept-1">
                            Jeg accepterer&nbsp;
                            <button type="button" onClick={() => setGuideModalOpen(true)}>
                                handelsbetingelserne
                            </button>
                            .
                        </label>
                    </div>
                    <div className="q11e-storefront__checkbox">
                        <input
                            type="checkbox"
                            id="accept-2"
                            name="accept-2"
                            value="accept-2"
                            required
                            checked={accepted2}
                            onChange={(evt) => setAccepted2(!!evt.currentTarget.checked)}
                        />
                        <label htmlFor="accept-2">{q11eStore.accept2}</label>
                    </div>
                    <button className={buyBtnClasses}>
                        {(isAwaitingLink && <InlineLoader />) || (
                            <>
                                <span>{amount === 0 ? `Bekræft ordre` : `Gå til sikker betaling`}</span>
                                {amount > 0 && <LockRounded />}
                            </>
                        )}
                    </button>
                    <div className="q11e-storefront__voucher-input">
                        <Typography variant="caption" paragraph align="center">
                            Har du en rabatkode eller gavekort? Skriv den her og klik "Aktivér"
                        </Typography>
                        <input
                            className={voucherFieldClasses}
                            type="text"
                            value={voucher}
                            placeholder="Rabatkode / Gavekort"
                            name={voucher}
                            disabled={voucherApplied}
                            onChange={handleVoucherChange}
                        />
                        <button
                            type="button"
                            className={applyVoucherBtnClasses}
                            disabled={!voucher.length || voucherApplied}
                            onClick={handleVoucherSubmit}
                        >
                            {(isApplyingVoucher && <InlineLoader color="#ffffff" />) || (
                                <span>{voucherApplied ? "Aktiveret" : "Aktivér"}</span>
                            )}
                        </button>
                    </div>
                </form>
            </div>
            <Dialog className="guide-dialog" open={guideModalOpen} onClose={() => setGuideModalOpen(false)}>
                <Guide open={guideModalOpen} name="handelsbetingelser" onModalClose={() => setGuideModalOpen(false)} />
            </Dialog>
        </>
    )
}

export default PageBuy
