interface Props {
    displayName: string
    description: string
    url: string
    logoUrl: string
}

const CharityIntro = ({ displayName, description, url, logoUrl }: Props) => (
    <div className="charity-intro padding-xy margin-bottom rounded-borders">
        <div className="charity-intro__copy">
            <h1>{displayName}</h1>
            <p>
                {description}
                <br />
                Læs mere på&nbsp;
                <a href={url} target="_blank" rel="noopener noreferrer">
                    {url}
                </a>
            </p>
        </div>
        <img className="charity-intro__logo" src={logoUrl} alt={displayName} />
    </div>
)

export default CharityIntro
