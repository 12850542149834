import Module from "../Module/Module"
import { CHARITIES_OTHER } from "../../routes/paths"

const CharityPreviewAddOther = () => (
    <div className="cell brand-preview-wrapper">
        <Module color="lightblue" url={CHARITIES_OTHER}>
            <div className="brand-preview">
                <div className="brand-preview__copy">
                    <div className="brand-preview__copy__txt">Anden organisation</div>
                </div>
                <div className="brand-preview__letter brand-preview__letter--add">+</div>
            </div>
        </Module>
    </div>
)

export default CharityPreviewAddOther
