import { useState } from "react"
import { DeleteRounded, EditRounded } from "@mui/icons-material"
import PropTypes from "prop-types"

import icons from "../../assets/icons.svg"

const checkmarkIcon = icons + "#checkmark"

const FormUserInputItem = (props) => {
    const { title, onChange, onDelete, index } = props
    const [editMode, setEditMode] = useState(false)

    const toggleEditMode = (e, state) => {
        e.preventDefault()
        setEditMode(state)
    }

    return (
        <div className="Form__userinput Form__inputwrapper">
            {editMode ? (
                <div>
                    <input type="text" value={title} onChange={onChange} index={index} autoFocus />
                    <div className="Form__userinput__actions">
                        <button className="Button Button--icon save" onClick={(e) => toggleEditMode(e, false)}>
                            <span>OK</span>
                            <svg>
                                <use xlinkHref={checkmarkIcon}></use>
                            </svg>
                        </button>
                    </div>
                </div>
            ) : (
                <div>
                    <p onClick={(e) => toggleEditMode(e, true)}>{title}</p>
                    <div className="Form__userinput__actions">
                        <button className="Button Button--icon" onClick={(e) => toggleEditMode(e, true)}>
                            <EditRounded />
                        </button>
                        <button className="Button Button--icon delete" onClick={onDelete} index={index}>
                            <DeleteRounded />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FormUserInputItem

FormUserInputItem.propTypes = {
    title: PropTypes.string.isRequired,
}
