import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useCharityDonation = (charityName: string, userId?: string): ICharityDonation | undefined => {
    const [charityDonation, setCharityDonation] = useState<ICharityDonation>()

    useEffect(() => {
        if (!userId) return

        const subscribe = () => {
            firestore
                .collection("users")
                .doc(userId)
                .collection("charityDonations")
                .doc(charityName)
                .onSnapshot((doc) => {
                    const charityDonation = doc.data() as ICharityDonation
                    setCharityDonation(charityDonation)
                })
        }

        return subscribe()
    }, [charityName, userId])

    return charityDonation
}

export default useCharityDonation
