import { Component, createContext } from "react"
import { firestore } from "../firebase"

import WithUser from "../components/hoc/WithUser"

export const CategoriesContext = createContext({ categories: [] })

class CategoriesProvider extends Component {
    state = {
        categories: [],
    }

    componentDidMount() {
        if (!this.props.user) {
            return
        }
        this.getCategories()
    }

    componentDidUpdate() {
        if (!this.props.user) {
            return
        }
        if (!this.state.categories || !this.state.categories.length) {
            this.getCategories()
        }
    }

    getCategories() {
        firestore
            .collection("categories")
            .doc("v8")
            .get()
            .then((docSnapshot) => {
                this.setState({
                    categories: docSnapshot.data().categories,
                })
            })
            .catch((error) => {
                console.error("Error: ", error)
            })
    }

    render() {
        const { categories } = this.state
        const { children } = this.props
        return <CategoriesContext.Provider value={categories}>{children}</CategoriesContext.Provider>
    }
}

export default WithUser(CategoriesProvider)
