import { useEffect, useState } from "react"

import { getAnswers } from "../../../api/Q11eContentManager"

const useQ11eAnswers = (q11eName: string, q11eVersion: string, questionId: string): IQ11eAnswer[] | undefined => {
    const [answers, setAnswers] = useState<IQ11eAnswer[]>()

    useEffect(() => {
        const q11eId = q11eName + "_" + q11eVersion
        getAnswers(q11eId, questionId).then(setAnswers)
    }, [q11eName, q11eVersion, questionId])

    return answers
}

export default useQ11eAnswers
