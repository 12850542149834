import { useEffect, useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { ArrowBackRounded, ArrowForwardRounded, ReplayRounded } from "@mui/icons-material"
import classNames from "classnames"

import { auth } from "../../firebase"
import { deleteAllAnswers } from "../../api/QuestionnaireRouteManager"
import * as ROUTES from "../../routes/paths"

interface Params {
    questionnaireId: string
    questionId: string
}

interface Props {
    question: IQuestion
    answer?: IAnswer
}

const QuestionnaireNav = ({ question, answer }: Props) => {
    const { questionnaireId } = useParams<Params>()
    const history = useHistory()

    const [nextPath, setNextPath] = useState<string>()

    useEffect(() => {
        if (answer) {
            if (answer.targetQuestionId) {
                setNextPath(`${ROUTES.QUESTIONNAIRE}/${questionnaireId}/${answer.targetQuestionId}`)
            } else {
                setNextPath(`${ROUTES.QUESTIONNAIRE_RESULT}/${questionnaireId}`)
            }
        } else {
            setNextPath(undefined)
        }
    }, [questionnaireId, answer])

    const handleRestartClicked = () => {
        deleteAllAnswers(auth.currentUser!.uid, questionnaireId)
        history.replace(`${ROUTES.QUESTIONNAIRE}/${questionnaireId}/q1`)
    }

    const nextClasses = classNames("la-fab", {
        "la-fab--disabled": !answer,
    })

    return (
        <div className="questionnaire-nav">
            <div className="questionnaire-nav__restart">
                {!question.start && (
                    <button className="la-fab la-fab--white" title="Start forfra" onClick={handleRestartClicked}>
                        <ReplayRounded />
                    </button>
                )}
            </div>
            <div className="questionnaire-nav__links">
                {!question.start && (
                    <button className="la-fab la-fab--white" onClick={history.goBack}>
                        <ArrowBackRounded />
                    </button>
                )}
                <Link className={nextClasses} to={nextPath ?? ""}>
                    <ArrowForwardRounded />
                </Link>
            </div>
        </div>
    )
}

export default QuestionnaireNav
