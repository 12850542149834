import { UserContext } from "../../providers/UserProvider"

// This is to give the actual component a name when debugging
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component"
}

const WithUser = (Component) => {
    const WrappedComponent = (props) => {
        return <UserContext.Consumer>{(user) => <Component user={user} {...props} />}</UserContext.Consumer>
    }
    WrappedComponent.displayName = `WithUser(${getDisplayName(WrappedComponent)})`
    return WrappedComponent
}

export default WithUser
