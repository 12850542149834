import Logo from "../Logo/Logo"

const Loading = () => {
    return (
        <div className="Loading bg-lightblue">
            <div>
                <Logo />
                <p>Indlæser...</p>
            </div>
        </div>
    )
}

export default Loading
