import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import { Dialog } from "@mui/material"
import { cloudfunctions } from "../../firebase"
import QuestionnaireRouteDisplay from "./QuestionnaireRouteDisplay"
import InlineLoader from "../Loader/InlineLoader"
import * as ROUTES from "../../routes/paths"

interface Params {
    questionnaireId: string
}

const PageQuestionnaireResult = () => {
    const { questionnaireId } = useParams<Params>()
    const [loading, setLoading] = useState(true)
    const [result, setResult] = useState<IQuestionnaireResult>()
    const [cta, setCta] = useState<IQuestionnaireCta>()
    const [route, setRoute] = useState<IQuestionnaireResultItem[]>()
    const [routeModalOpen, setRouteModalOpen] = useState(false)

    useEffect(() => {
        cloudfunctions
            .httpsCallable("compute_questionnaire_result")(questionnaireId)
            .then(({ data }) => {
                setResult(data.result)
                setCta(data.cta)
                setRoute(data.route)
                setLoading(false)
            })
    }, [questionnaireId])

    const handleViewRouteClicked = () => {
        setRouteModalOpen(true)
    }

    const handleRouteModalClose = () => {
        setRouteModalOpen(false)
    }

    if (loading || !route) return <InlineLoader />

    return (
        <div className="questionnaire-result">
            <h3 className="questionnaire-result__title">Resultat</h3>
            {(result && cta && (
                <>
                    <p>
                        Baseret på
                        <span className="questionnaire-result__inline-btn" onClick={handleViewRouteClicked}>
                            {" "}
                            dine svar{" "}
                        </span>
                        har vi beregnet følgende resultat:
                    </p>
                    <p>{result.txt1}</p>
                    {result.primaryChart && (
                        <img
                            src={`${ROUTES.PUBLIC_BUCKET}/questionnaires/charts/${result.primaryChart}.png`}
                            alt={result.primaryChart}
                        />
                    )}
                    {result.txt2 && <p>{result.txt2}</p>}
                    {result.secondaryChart && (
                        <img
                            src={`${ROUTES.PUBLIC_BUCKET}/questionnaires/charts/${result.secondaryChart}.png`}
                            alt={result.secondaryChart}
                        />
                    )}
                    {result.txt3 && <p>{result.txt3}</p>}
                </>
            )) || (
                <p>
                    <em>Der findes endnu ikke noget resultat...</em>
                </p>
            )}
            <div className="questionnaire-result__disclaimer">
                Der er alene tale om en vejledende test, som ikke erstatter konkret advokat rådgivning. Der tages i det
                hele forbehold for fejl.
            </div>

            <Dialog open={routeModalOpen} onClose={handleRouteModalClose}>
                <QuestionnaireRouteDisplay result={result!} route={route} onModalClose={handleRouteModalClose} />
            </Dialog>
        </div>
    )
}

export default PageQuestionnaireResult
