import { NavLink } from "react-router-dom"

import icons from "../../assets/icons.svg"

const home = icons + "#home"
const flower = icons + "#flower"
const suitcase = icons + "#suitcase"
const heart = icons + "#heart"
const inheritance = icons + "#inheritance"
const user = icons + "#user"
const logout = icons + "#logout"

const renderIcon = (icon: string) => {
    switch (icon) {
        case "home":
            return home
        case "flower":
            return flower
        case "suitcase":
            return suitcase
        case "heart":
            return heart
        case "inheritance":
            return inheritance
        case "user":
            return user
        case "logout":
            return logout
        default:
            return home
    }
}

interface Props {
    url: string
    icon: string
    title: string
    isExact?: boolean
    isActive?: (match: any, location: any) => boolean
}

const TabBarItem = ({ url, icon, title, isExact, isActive }: Props) => (
    <NavLink to={url} className="TabBarItem" exact={isExact} isActive={isActive} activeClassName="current-page">
        <svg>
            <use xlinkHref={renderIcon(icon)} />
        </svg>
        <span>{title}</span>
    </NavLink>
)

export default TabBarItem
