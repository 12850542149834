import firebase from "firebase/app"

class Environment {
    ua: string // user agent
    updatedAt: Date

    constructor(ua: string, updatedAt: Date) {
        this.ua = ua
        this.updatedAt = updatedAt
    }

    hashCode(str: string): number {
        let hash: number = 0
        let i: number
        let chr: number

        for (i = 0; i < str.length; i++) {
            chr = str.charCodeAt(i)
            hash = (hash << 5) - hash + chr
            hash |= 0 // Convert to 32bit integer
        }

        return hash
    }

    converter = {
        toFirestore: function (env: Environment) {
            return { ua: env.ua, updatedAt: env.updatedAt }
        },
        fromFirestore: function (snapshot: firebase.firestore.DocumentSnapshot, options: any) {
            const data = snapshot.data(options)!
            return new Environment(data.ua, data.updatedAt)
        },
    }
}

export default Environment
