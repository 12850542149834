import { useRouteMatch } from "react-router-dom"

import Module from "../Module/Module"

interface Props {
    charity: ICharityOther
}

const CharityPreview = ({ charity }: Props) => {
    const match = useRouteMatch()

    return (
        <div className="cell brand-preview-wrapper">
            <Module color="white" url={`${match.url}/anden/${charity.name}`}>
                <div className="brand-preview">
                    <div className="brand-preview__copy">
                        <div className="brand-preview__copy__txt">{charity.displayName}</div>
                        {<div className="brand-preview__copy__chip">{charity.amount} DKK</div>}
                    </div>
                    <div className="brand-preview__letter">{charity.displayName[0]}</div>
                </div>
            </Module>
        </div>
    )
}

export default CharityPreview
