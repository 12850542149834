import { useEffect, useState } from "react"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { ArrowBackRounded, EditRounded } from "@mui/icons-material"

import WithUser from "../hoc/WithUser"
import { useHeirloom } from "../hooks/useHeirloom"
import { useLink } from "../hooks/useLink"
import icons from "../../assets/icons.svg"

const imageIcon = icons + "#image"

interface Props {
    user: { uid: string }
}

interface Params {
    id: string
}

const HeirloomDetail = ({ user }: Props) => {
    const history = useHistory()
    const match = useRouteMatch()
    const { id } = useParams<Params>()
    const heirloom = useHeirloom(user.uid, id)
    const link = useLink(heirloom?.link_id)

    const [linkImg, setLinkImg] = useState<string>()
    useEffect(() => {
        if (!link) return
        const isCreator = link.creator_id === user.uid
        setLinkImg(isCreator ? link.invitee_photo : link.creator_photo)
    }, [link, user.uid])

    if (!heirloom) return null

    return (
        <>
            <div className="heirloom">
                <div className="heirloom__top">
                    {heirloom.photo_url_str ? (
                        <img className="heirloom__img" src={heirloom.photo_url_str} alt={heirloom.description} />
                    ) : (
                        <svg className="heirloom__img">
                            <use xlinkHref={imageIcon} />
                        </svg>
                    )}
                    <button className="la-fab" onClick={history.goBack}>
                        <ArrowBackRounded />
                    </button>
                </div>
                <div className="heirloom__bottom">
                    <h1 className="heirloom__title">{heirloom.description}</h1>
                    {heirloom.value && (
                        <>
                            <p>Anslået værdi (DKK):</p>
                            <h3 className="heirloom__value">{heirloom.value}</h3>
                        </>
                    )}
                    {heirloom.receiver_name && <p>Begunstiges til:</p>}
                    {heirloom.receiver_name && (
                        <div className="heirloom__link rounded-borders">
                            <>
                                <p>{heirloom.receiver_name}</p>
                                {linkImg ? (
                                    <img className="heirloom__link__img" src={linkImg} alt={heirloom.receiver_name} />
                                ) : (
                                    <h1 className="heirloom__link__img">{heirloom.receiver_name[0]}</h1>
                                )}
                            </>
                        </div>
                    )}
                    <Link className="Button Button--flat Button--icon heirloom__edit-btn" to={`${match.url}/rediger`}>
                        <span>Redigér</span>
                        <EditRounded />
                    </Link>
                </div>
            </div>
        </>
    )
}

export default WithUser(HeirloomDetail)
