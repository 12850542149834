import { Component, createContext } from "react"
import { firestore } from "../firebase"

import WithUser from "../components/hoc/WithUser"

export const LifeContext = createContext()

class LifeProvider extends Component {
    state = {
        life: false,
    }

    unsubscribeFromLife = null

    componentDidMount() {
        if (!this.props.user) {
            return
        }
        this.listenForLife(this.props.user.uid)
    }

    componentDidUpdate() {
        if (!this.props.user) {
            this.unsubscribeFromLife()
            if (this.state.life) {
                this.setState({ life: false })
            }
            return
        }
        if (!this.state.life) {
            this.listenForLife(this.props.user.uid)
        }
    }

    componentWillUnmount() {
        if (this.unsubscribeFromLife) {
            this.unsubscribeFromLife()
        }
    }

    listenForLife(uid) {
        if (!uid) {
            return
        }
        this.unsubscribeFromLife = firestore
            .collection("users")
            .doc(uid)
            .collection("life")
            .doc("v1")
            .onSnapshot((snapshot) => {
                this.setState({ life: snapshot.data() })
            })
    }

    render() {
        const { life } = this.state
        const { children } = this.props
        return <LifeContext.Provider value={life}>{children}</LifeContext.Provider>
    }
}

export default WithUser(LifeProvider)
