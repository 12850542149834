import { MouseEvent } from "react"
import {
    ArrowBackRounded,
    CallRounded,
    CloseRounded,
    DoneRounded,
    EmailRounded,
    LanguageRounded,
    PlaceRounded,
} from "@mui/icons-material"

interface Props {
    undertaker: any
    isSelected: boolean
    onSelect?: (event: MouseEvent, undertaker: any) => void
    onBack: () => void
}

const UndertakerDetail = ({ undertaker, isSelected, onSelect, onBack }: Props) => {
    const handleSelect = (event: MouseEvent<HTMLButtonElement>) => {
        onSelect?.(event, undertaker)
    }

    return (
        <div className="undertaker">
            <button
                className="la-fab"
                style={{
                    zIndex: 1000,
                    alignSelf: isSelected ? "flex-end" : "flex-start",
                }}
                onClick={onBack}
            >
                {isSelected ? <CloseRounded /> : <ArrowBackRounded />}
            </button>
            <img className="undertaker__img" src={undertaker.img} alt={undertaker.img} />
            <div className="undertaker__name">{undertaker.name}</div>
            <p>{undertaker.desc}</p>
            <ul className="undertaker__details">
                <li>
                    <a
                        href={"https://www.google.com/maps/place/" + (undertaker.dept || undertaker.address || "—")}
                        target="_blank"
                        rel="noopener noreferrer"
                    >
                        <PlaceRounded /> <span>{undertaker.dept || undertaker.address || "—"}</span>
                    </a>
                </li>
                <li>
                    <a href={"mailto:" + undertaker.email}>
                        <EmailRounded /> <span>{undertaker.email}</span>
                    </a>
                </li>
                <li>
                    <a href={"tel:" + undertaker.phone}>
                        <CallRounded /> <span>{undertaker.phone}</span>
                    </a>
                </li>
                <li>
                    <a href={undertaker.url} target="_blank" rel="noopener noreferrer">
                        <LanguageRounded /> <span>{undertaker.url}</span>
                    </a>
                </li>
            </ul>
            <button
                className="Button Button--icon"
                onClick={handleSelect}
                style={{ visibility: Boolean(onSelect) ? "visible" : "hidden" }}
            >
                <span>{isSelected ? "Fravælg bedemand" : "Vælg bedemand"}</span>
                {isSelected ? <CloseRounded /> : <DoneRounded />}
            </button>
        </div>
    )
}

export default UndertakerDetail
