import { NavLink } from "react-router-dom"

import icons from "../../assets/icons.svg"

const renderIcon = (icon: string) => {
    switch (icon) {
        case "user":
            return icons + "#user"
        case "print":
            return icons + "#print"
        case "about":
            return icons + "#about"
        case "partners":
            return icons + "#partners"
        case "collabs":
            return icons + "#collab"
        case "policy":
            return icons + "#policy"
        case "trading-terms":
            return icons + "#trading-terms"
        case "feedback":
            return icons + "#feedback"
        default:
            return icons + "#user"
    }
}

interface Props {
    url: string
    icon: string
    title: string
}

const DrawerItem = ({ url, icon, title }: Props) => (
    <NavLink to={url} className="DrawerItem">
        <svg>
            <use xlinkHref={renderIcon(icon)} />
        </svg>
        <span>{title}</span>
    </NavLink>
)

export default DrawerItem
