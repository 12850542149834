import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Dialog } from "@mui/material"
import { useSnackbar } from "notistack"
import { ArrowBackRounded } from "@mui/icons-material"

import { cloudfunctions } from "../../firebase"
import WithUser from "../hoc/WithUser"
import Intro from "../Intro/Intro"
import PrintItem from "./PrintItem"
import LegalDocuments from "./LegalDocuments"
import downloadFile from "./downloadHelper"
import useLatestPrints from "../hooks/useLatestPrints"
import Alert from "../dialogs/Alert"
import { useLink, useLinkDetails } from "../hooks/useLink"
import UndertakerSearch from "../Undertaker/UndertakerSearch"

interface Props {
    user: { uid: string }
}

interface Params {
    linkID?: string
}

const PagePrint = ({ user }: Props) => {
    const history = useHistory()
    const { linkID } = useParams<Params>()
    const link = useLink(linkID)
    const { fullName, firstName } = useLinkDetails(user.uid, link)
    const { enqueueSnackbar } = useSnackbar()

    const [loading, setLoading] = useState(false)
    const [wishesLoading, setWishesLoading] = useState(false)
    const [lifeLoading, setLifeLoading] = useState(false)
    const [heirloomsLoading, setHeirloomsLoading] = useState(false)
    const [noHeirloomsModalOpen, setNoHeirloomsModalOpen] = useState(false)
    const [undertakerSearchOpen, setUndertakerSearchOpen] = useState(false)

    const [title, setTitle] = useState("Mine dokumenter")

    useEffect(() => {
        if (fullName) {
            setTitle(fullName + "s dokumenter")
        } else {
            setTitle("Mine dokumenter")
        }
    }, [fullName])

    const latestPrints = useLatestPrints(user.uid, linkID)

    const handleByeItemClicked = async () => {
        setWishesLoading(true)
        await download("wishes", "Mit farvel.pdf")
        setWishesLoading(false)
    }

    const handleLifeItemClicked = async () => {
        setLifeLoading(true)
        await download("life", "Mit liv.pdf")
        setLifeLoading(false)
    }

    const handleHeirloomsItemClicked = async () => {
        setHeirloomsLoading(true)
        await download("heirlooms", "Mine arvestykker.pdf")
        setHeirloomsLoading(false)
    }

    const download = async (item: string, fileName: string) => {
        if (loading) return
        setLoading(true)

        try {
            // Generate a new pdf document and store it in the user's bucket
            linkID
                ? await cloudfunctions.httpsCallable(`${item}_export_other`)({ linkID })
                : await cloudfunctions.httpsCallable(`${item}_export`)()
        } catch (err) {
            setLoading(false)
            if (item === "heirlooms") {
                // Only cloud function "heirlooms_export" throws on purpose.
                setNoHeirloomsModalOpen(true)
            }
            return
        }

        await downloadFile(fileName, user.uid)
        setLoading(false)
        enqueueSnackbar(`Dokumentet "${fileName}" er downloadet.`)
    }

    const handleUndertakerSearchClicked = () => setUndertakerSearchOpen(true)

    const resolveIntroText = () =>
        firstName ? (
            <span>
                Her kan du gennemse og printe {firstName}s dokumenter. Hvis {firstName} ikke selv har udpeget en
                bedemand kan du nemt finde én{" "}
                <span className="questionnaire-result__inline-btn" onClick={handleUndertakerSearchClicked}>
                    &nbsp;her&nbsp;
                </span>
                .
            </span>
        ) : (
            "Et printet dokument kan i visse situationer være mere praktisk. Her har du mulighed for at forevige dine data på tryk."
        )

    return (
        <>
            {linkID && (
                <button className="la-fab margin-bottom" onClick={history.goBack}>
                    <ArrowBackRounded />
                </button>
            )}
            <Intro title={title} text={resolveIntroText()} color="white" />
            <div className="grid-x grid-padding-x--small">
                <PrintItem
                    color="yellow"
                    title="Mit farvel"
                    dateLabel="Sidst set"
                    date={latestPrints?.wishes}
                    loading={wishesLoading}
                    onClick={handleByeItemClicked}
                />
                <PrintItem
                    color="pink"
                    title="Mit liv"
                    dateLabel="Sidst set"
                    date={latestPrints?.life}
                    loading={lifeLoading}
                    onClick={handleLifeItemClicked}
                />
                <PrintItem
                    color="green"
                    title="Mine arvestykker"
                    dateLabel="Sidst set"
                    date={latestPrints?.heirlooms}
                    loading={heirloomsLoading}
                    onClick={handleHeirloomsItemClicked}
                />
            </div>
            {!linkID && <LegalDocuments />}
            <Alert
                title="Ingen arvestykker"
                body={`${firstName ?? "Du"} har endnu ikke oprettet nogen arvestykker.`}
                isOpen={noHeirloomsModalOpen}
                handleClose={() => setNoHeirloomsModalOpen(false)}
            />
            <Dialog open={undertakerSearchOpen} onClose={() => setUndertakerSearchOpen(false)}>
                <UndertakerSearch onClose={() => setUndertakerSearchOpen(false)} />
            </Dialog>
        </>
    )
}

export default WithUser(PagePrint)
