import { useState } from "react"

import { forgotPassword } from "../../firebase"

const ChangePasswordModal = ({ user, onCancel }) => {
    const [isChangingPassword, setChangingPassword] = useState(false)
    const [hasChangedPassword, setHasChangedPassword] = useState(false)

    async function onClick() {
        setChangingPassword(true)
        try {
            await forgotPassword(user.email)
            setHasChangedPassword(true)
        } catch (err) {
            alert(`Der skete en uventet fejl:\n${err}`)
        } finally {
            setChangingPassword(false)
        }
    }

    return (
        <div className="la-modal">
            <h2>{!hasChangedPassword ? (isChangingPassword ? "Vent..." : "Nulstil adgangskode?") : "E-mail sendt"}</h2>
            <p>
                {!hasChangedPassword
                    ? `Vi sender en e-mail til ${user.email}.\nKlik på linket i e-mailen for at nulstille din adgangskode.`
                    : `Vi har sendt en e-mail til ${user.email}.\nKlik på linket i e-mailen for at nulstille din adgangskode.`}
            </p>
            <div className="la-modal__actions">
                <button
                    autoFocus
                    className={`Button${!hasChangedPassword ? " Button--flat" : ""}`}
                    aria-labelledby="close-modal"
                    disabled={isChangingPassword}
                    onClick={onCancel}
                >
                    {hasChangedPassword ? "OK" : "Fortryd"}
                </button>
                {!hasChangedPassword && (
                    <button className="Button" disabled={isChangingPassword} onClick={onClick}>
                        {isChangingPassword ? "Vent..." : "Nulstil"}
                    </button>
                )}
            </div>
        </div>
    )
}

export default ChangePasswordModal
