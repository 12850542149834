import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { CloseRounded } from "@mui/icons-material"
import { FormControl, FormControlLabel, Radio, RadioGroup, TextField } from "@mui/material"
import { styled } from "@mui/system"

import { cloudfunctions } from "../../firebase"
import { getLegalImageUrl } from "../../api/LegalManager"

interface Props {
    legalName: string
    legalHeading: string
    legalNeutrum: boolean
    onContactModalClose: () => void
}

enum timeSlot {
    Morning = "9:00 – 12:00",
    Afternoon = "12:00 – 16:00",
    Evening = "16:00 – 20:00",
}

const LegalContactForm = ({ legalName, legalHeading, legalNeutrum, onContactModalClose }: Props) => {
    const [name, setName] = useState("")
    const [email, setEmail] = useState("")
    const [phone, setPhone] = useState("")
    const [selectedTimeSlot, setSelectedTimeSlot] = useState<timeSlot | null>(null)
    const [loading, setLoading] = useState(false)
    const [emailSent, setEmailSent] = useState(false)

    const [imgURL, setImgURL] = useState<string>()
    useEffect(() => {
        getLegalImageUrl(legalName).then(setImgURL)
    }, [legalName])

    const handleNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        setName(value)
    }

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        setEmail(value.trim())
        setPhone("")
        setSelectedTimeSlot(null)
    }

    const handlePhoneChange = (event: ChangeEvent<HTMLInputElement>) => {
        const value = event.currentTarget.value
        setPhone(value.trim())
        setEmail("")
    }

    const handleTimeSlotChange = (event: ChangeEvent<HTMLInputElement>) =>
        setSelectedTimeSlot(event.target.value as timeSlot)

    const handleContactFormSubmit = async (event: FormEvent) => {
        event.preventDefault()
        if (loading) return

        const source = process.env.REACT_APP_IS_PHONE ? "adjo_android" : "adjo_pwa"

        const contactInfo: ILegalContactInfo = {
            legalName,
            name: name.trim(),
            source,
        }

        if (phone.length > 0) {
            /* Using phone number */
            contactInfo.phone = phone
            if (!selectedTimeSlot) {
                // TODO: Show error on form
                alert("Vælg venligst én af de tre mulige tidsrum.")
                return
            }
            contactInfo.timeSlot = selectedTimeSlot
        } else if (email.length > 0) {
            /* Using email */
            contactInfo.email = email
        } else {
            // TODO: Show error on form
            alert("Angiv venligst din e-mail eller dit telefonnummer.")
            return
        }

        setLoading(true)
        await cloudfunctions.httpsCallable("submitLegal")({ contactInfo })
        setLoading(false)
        setEmailSent(true)
    }

    return (
        <div className="questionnaire-contact">
            {imgURL && <img src={imgURL} alt="" />}
            {(emailSent && (
                <>
                    <p>
                        Tak for din interesse i at få {legalNeutrum ? "et" : "en"} {legalHeading.toLowerCase()}.
                        <br />
                        <br />
                        Du vil blive kontaktet af advokaten i henhold til dit ønske.
                    </p>
                </>
            )) || (
                <>
                    <p>
                        Skriv din e-mail eller dit telefonnummer og angiv i hvilket tidsrum du vil ringes op. Så
                        kontakter vi dig.
                    </p>
                    <Form className="la-form" onSubmit={handleContactFormSubmit}>
                        <TextField
                            variant="outlined"
                            required
                            value={name}
                            onChange={handleNameChange}
                            autoComplete="name"
                            label="Navn"
                            name="name"
                            type="text"
                            fullWidth
                        />
                        <TextField
                            variant="outlined"
                            value={email}
                            onChange={handleEmailChange}
                            autoComplete="email"
                            label="E-mail"
                            name="email"
                            type="email"
                            margin="normal"
                            fullWidth
                        />
                        <span className="or">ELLER</span>
                        <TextField
                            variant="outlined"
                            value={phone}
                            onChange={handlePhoneChange}
                            autoComplete="tel-national"
                            label="Telefonnummer"
                            name="phone"
                            type="text"
                            margin="normal"
                            fullWidth
                        />
                        <FormControl component="fieldset">
                            <RadioGroup name="timeSlots" value={selectedTimeSlot} onChange={handleTimeSlotChange}>
                                <FormControlLabel
                                    value={timeSlot.Morning}
                                    control={<Radio />}
                                    label={timeSlot.Morning}
                                />
                                <FormControlLabel
                                    value={timeSlot.Afternoon}
                                    control={<Radio />}
                                    label={timeSlot.Afternoon}
                                />
                                <FormControlLabel
                                    value={timeSlot.Evening}
                                    control={<Radio />}
                                    label={timeSlot.Evening}
                                />
                            </RadioGroup>
                        </FormControl>
                        <button className={`Button${loading ? " Button--loading" : ""}`}>
                            {loading ? "Vent..." : "Kontakt mig"}
                        </button>
                    </Form>
                    <div className="questionnaire-result__disclaimer">
                        Ved at trykke på <strong>Kontakt mig</strong> accepterer du, at dine kontaktoplysninger sendes
                        til advokaten. <em>Ingen andre informationer fra din Adjø profil deles med advokaten.</em>
                    </div>
                </>
            )}
            <button className="la-fab" onClick={onContactModalClose}>
                <CloseRounded />
            </button>
        </div>
    )
}

export default LegalContactForm

const Form = styled("form")({
    width: 300,
    alignItems: "center",
})
