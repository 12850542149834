import { ChangeEvent } from "react"

interface Props {
    answer: IQ11eDistributionAnswer
    userLabels?: IQ11eLabels
    onChange: (value: Array<number>) => void
    distribution: Array<number>
}

const Q11eAnswerDistribution = ({ answer, userLabels, onChange, distribution }: Props) => {
    if (!userLabels) return null

    const handleChange = (idx: number) => (event: ChangeEvent<HTMLInputElement>) => {
        const distributionClone = [...distribution]
        distributionClone[idx] = +event.target.value
        onChange(distributionClone)
    }

    return (
        <>
            {answer.labels.map(
                (label, idx) =>
                    userLabels[label] && (
                        <div key={label} className="la-input-field">
                            <label className="la-input-field__label" htmlFor={label}>
                                {userLabels[label]}
                            </label>
                            <input
                                className={`la-input${distribution[idx] ? " la-input--filled" : ""}`}
                                type="number"
                                required
                                value={String(distribution[idx] ?? "")}
                                placeholder={answer.placeholder}
                                id={label}
                                onChange={handleChange(idx)}
                            />
                            <span className="la-input-field__suffix">%</span>
                        </div>
                    )
            )}
        </>
    )
}

export default Q11eAnswerDistribution
