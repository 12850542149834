import PropTypes from "prop-types"

import Question from "./Question"

const QuestionList = (props) => {
    const { questions, topic, categoryId } = props
    return (
        <ul className="QuestionList list-unstyled">
            {questions.map((question, i) => (
                <Question
                    key={i + Math.floor(Math.random() * 10000)}
                    type={topic ? "wish" : "life"}
                    question={question}
                    topicId={topic?.id}
                    categoryId={categoryId}
                />
            ))}
        </ul>
    )
}

export default QuestionList

QuestionList.propTypes = {
    questions: PropTypes.array.isRequired,
    categoryId: PropTypes.string.isRequired,
    topic: PropTypes.any,
}
