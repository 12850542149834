import { firestore } from "../firebase"

export const setCharityDonation = (userId: string, charityName: string, amount: number) =>
    firestore
        .collection("users")
        .doc(userId)
        .collection("charityDonations")
        .doc(charityName)
        .set({ charityName, amount })

export const deleteCharityDonation = (userId: string, charityName: string) =>
    firestore.collection("users").doc(userId).collection("charityDonations").doc(charityName).delete()

export const setCharityOther = (userId: string, charity: ICharityOther) =>
    firestore.collection("users").doc(userId).collection("charityOtherDonations").doc(charity.name).set(charity)

export const deleteCharityOther = (userId: string, charity: ICharityOther) =>
    firestore.collection("users").doc(userId).collection("charityOtherDonations").doc(charity.name).delete()
