import { useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Dialog } from "@mui/material"
import { ArrowBackRounded } from "@mui/icons-material"

import { cloudfunctions } from "../../firebase"
import { getLegal } from "../../api/LegalManager"
import Guide from "../Guide/Guide"
import LegalContactForm from "./LegalContactForm"

interface Params {
    name: string
}

const PageLegal = () => {
    const history = useHistory()
    const { name } = useParams<Params>()
    const [legal, setLegal] = useState<ILegal>()
    const [guideModalOpen, setGuideModalOpen] = useState(false)
    const [contactModalOpen, setContactModalOpen] = useState(false)

    useEffect(() => {
        getLegal(name).then(setLegal)
    }, [name])

    const handleBackClicked = () => {
        history.goBack()
    }

    const handleReadMoreClicked = () => {
        setGuideModalOpen(true)
    }

    const handleGuideModalClose = () => {
        setGuideModalOpen(false)
    }

    const handleCTAClicked = () => {
        cloudfunctions.httpsCallable("logLegalContactFormOpened")({
            name: legal?.name,
            source: process.env.REACT_APP_IS_PHONE ? "adjo_android" : "adjo_pwa",
        })
        setContactModalOpen(true)
    }

    const handleContactModalClose = () => {
        setContactModalOpen(false)
    }

    if (!legal) return null

    return (
        <div className="legal">
            <button className="la-fab" onClick={handleBackClicked}>
                <ArrowBackRounded />
            </button>
            <h1>{legal.heading}</h1>
            <h3>{legal.subheading}</h3>
            <p>{legal.body}</p>
            <button className="guide__more-btn" onClick={handleReadMoreClicked}>
                Læs mere
            </button>
            <div className="cta">
                <p>{legal.ctaText}</p>
                <button className="Button" onClick={handleCTAClicked}>
                    {legal.ctaButtonText}
                </button>
            </div>

            <Dialog className="guide-dialog" open={guideModalOpen} onClose={handleGuideModalClose}>
                <Guide open={guideModalOpen} name={name} onModalClose={handleGuideModalClose} />
            </Dialog>

            <Dialog open={contactModalOpen} onClose={handleContactModalClose}>
                <LegalContactForm
                    legalName={name}
                    legalHeading={legal.heading}
                    legalNeutrum={legal.neutrum}
                    onContactModalClose={handleContactModalClose}
                />
            </Dialog>
        </div>
    )
}

export default PageLegal
