const UndertakerPreview = ({ undertaker, onSelect }) => (
    <button className="undertaker-preview" type="button" onClick={() => onSelect(undertaker)}>
        <div className="undertaker-preview__txt">
            <div>{undertaker.name}</div>
            <div>{undertaker.dept || undertaker.address || "—"}</div>
        </div>
        <img className="undertaker-preview__img" src={undertaker.img} alt={undertaker.img} />
    </button>
)

export default UndertakerPreview
