import { Component } from "react"
import PropTypes from "prop-types"
import { DeleteRounded, InsertPhotoRounded } from "@mui/icons-material"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { getInitialCrop, getCroppedImg } from "../../imgUtils"

import Prompt from "../dialogs/Prompt"
import icons from "../../assets/icons.svg"
const addImageIcon = icons + "#add-image"

class FormInputImage extends Component {
    state = {
        src: null,
        img: undefined,
        success: false,
        error: false,
        crop: {
            aspect: 1,
            x: 0,
            y: 0,
            width: 0,
            height: 0,
            unit: "px",
        },
        promptOpen: false,
        photoMarkedForDeletion: false,
    }

    onSelectFile = (event) => {
        const file = event.target.files?.[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener("load", () => this.setState({ src: reader.result }))
            reader.readAsDataURL(file)
        }
    }

    onImageLoaded = (img) => {
        const [x, y, len] = getInitialCrop(img)

        this.setState((prevState) => ({
            crop: {
                ...prevState.crop,
                x: x,
                y: y,
                width: len,
                height: len,
            },
            img: img,
        }))

        return false
    }

    onCropChange = (crop) => {
        this.setState({ crop })
    }

    async makeClientCrop() {
        const { img, crop } = this.state
        if (img && crop.width && crop.height) {
            return await getCroppedImg(img, crop, "newFile.jpeg")
        } else {
            return Promise.resolve(undefined)
        }
    }

    openMenu = () => {
        this.setState((prevState) => ({
            promptOpen: !prevState.promptOpen,
        }))
    }

    handleMenuClose = () => {
        this.setState({ promptOpen: false })
    }

    selectPhoto = () => {
        document.getElementById("select-heirloom-photo-input").click()
    }

    deletePhoto = () => {
        this.setState({ img: undefined, photoMarkedForDeletion: true, promptOpen: false })
    }

    render() {
        const { photo } = this.props
        const { crop, src } = this.state

        return (
            <div className="FormInputImage">
                {src ? (
                    <ReactCrop
                        src={src}
                        crop={crop}
                        onImageLoaded={this.onImageLoaded}
                        onComplete={this.onCropComplete}
                        onChange={this.onCropChange}
                    />
                ) : (
                    <div
                        className="FormInputImage__label rounded-borders"
                        onClick={photo && !this.state.photoMarkedForDeletion ? this.openMenu : this.selectPhoto}
                    >
                        <div>
                            {(photo && !this.state.photoMarkedForDeletion && <img src={photo} alt="" />) || (
                                <svg>
                                    <use xlinkHref={addImageIcon} />
                                </svg>
                            )}
                            <span>{photo && !this.state.photoMarkedForDeletion ? "Skift foto" : "Tilføj et foto"}</span>
                        </div>
                        <input
                            type="file"
                            id="select-heirloom-photo-input"
                            onChange={this.onSelectFile}
                            accept="image/x-png,image/jpeg,image/jpg"
                        />
                    </div>
                )}
                <Prompt title="Skift foto" isOpen={this.state.promptOpen} handleClose={this.handleMenuClose}>
                    <>
                        <button type="button" className="Button Button--icon" onClick={this.selectPhoto}>
                            <span>Vælg nyt</span>
                            <InsertPhotoRounded />
                        </button>
                        <button type="button" className="Button Button--icon Button--red" onClick={this.deletePhoto}>
                            <span>Slet</span>
                            <DeleteRounded />
                        </button>
                    </>
                </Prompt>
            </div>
        )
    }
}

export default FormInputImage

FormInputImage.propTypes = {
    photo: PropTypes.string,
}
