import { useState } from "react"
import { FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"

import { auth } from "../../firebase"

const DeleteUserModal = ({ user, onCancel }) => {
    const [email, setEmail] = useState(user.email)
    const [password, setPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [err, setErr] = useState(undefined)
    const [waiting, setWaiting] = useState(false)

    const togglePasswordVisible = () => setPasswordVisible(!passwordVisible)

    async function onSubmit(event) {
        event.preventDefault()

        setWaiting(true)
        try {
            await deleteUser()
        } catch (error) {
            let err
            if (error.code === "auth/user-not-found") {
                err = {
                    title: "Ugyldig e-mail",
                    txt: "Den indtastede e-mail er forkert.",
                }
            } else if (error.code === "auth/invalid-email") {
                err = {
                    title: "Ugyldig e-mail",
                    txt: "Den indtastede e-mail er ikke en gyldig e-mailadresse.",
                }
            } else if (error.code === "auth/wrong-password") {
                err = {
                    title: "Forkert adgangskode",
                    txt: "Den indtastede adgangskode er forkert.",
                }
            } else {
                err = {
                    title: "Uventet fejl",
                    txt: "Der skete en uventet fejl.\nKontakt venligst vores support afdeling.",
                }
            }
            setErr(err)
        } finally {
            setWaiting(false)
        }
    }

    async function deleteUser() {
        const credential = await auth.signInWithEmailAndPassword(email, password)
        return await credential.user.delete()
    }

    const onDismissErrorClicked = () => {
        setErr(undefined)
    }

    if (err) {
        return (
            <div className="la-modal">
                <h2>{err.title}</h2>
                <p>{err?.txt}</p>
                <div className="la-modal__actions">
                    <button className="Button" aria-labelledby="close-modal" onClick={onDismissErrorClicked}>
                        OK
                    </button>
                </div>
            </div>
        )
    }

    return (
        <div className="la-modal">
            <h2>Slet profil?</h2>
            <p>
                {
                    "Angiv din e-mail og adgangskode for at slette din profil.\nVær opmærksom på, at al din data bliver slettet og ikke kan genskabes."
                }
            </p>
            <form className="la-modal__form" onSubmit={onSubmit}>
                <TextField
                    variant="outlined"
                    required
                    value={email}
                    autoComplete="email"
                    label="E-mail"
                    onChange={(e) => setEmail(e.target.value)}
                    name="email"
                    type="email"
                />
                <FormControl variant="outlined" margin="normal" fullWidth required>
                    <InputLabel htmlFor="password">Adgangskode</InputLabel>
                    <OutlinedInput
                        type={passwordVisible ? "text" : "password"}
                        value={password}
                        autoComplete="current-password"
                        name="password"
                        id="password"
                        label="Adgangskode"
                        onChange={(e) => setPassword(e.target.value)}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton onClick={togglePasswordVisible} edge="end">
                                    {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <div className="la-modal__actions">
                    <button autoFocus className="Button Button--flat" type="button" disabled={waiting} onClick={onCancel}>
                        Fortryd
                    </button>
                    <button className="Button Button--red" disabled={waiting}>
                        {waiting ? "Vent..." : "Slet"}
                    </button>
                </div>
            </form>
        </div>
    )
}

export default DeleteUserModal
