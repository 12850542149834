import { useState, useEffect } from "react"
import PropTypes from "prop-types"
import ReactCrop from "react-image-crop"
import "react-image-crop/dist/ReactCrop.css"
import { getInitialCrop, getCroppedImg } from "../../imgUtils"
import { setPhoto } from "../../api/ProfilePhotoManager"

const EditProfileImg = ({ user, src, onSubmitted }) => {
    const [crop, setCrop] = useState({
        aspect: 1,
        x: 0,
        y: 0,
        width: 0,
        height: 0,
        unit: "px",
    })
    const [img, setImg] = useState(undefined)

    const [waiting, setWaiting] = useState(false)
    useEffect(() => {
        return setWaiting(false)
    }, [])

    const onImageLoaded = (img) => {
        setTimeout(() => {
            // We need to wait until the dialog has finished its transform,
            // otherwise the dimensions will be off
            const [x, y, len] = getInitialCrop(img)
            setCrop({ ...crop, x: x, y: y, width: len, height: len })
            setImg(img)

            return false
        }, 250)
    }

    const handleSubmit = async () => {
        setWaiting(true)

        const croppedImg = await getCroppedImg(img, crop, "newFile.jpeg")

        try {
            await setPhoto(croppedImg, user)
        } catch (err) {
            alert(err)
        }

        onSubmitted()
    }

    return (
        <>
            <ReactCrop
                style={{ marginBottom: 30 }}
                src={src}
                crop={crop}
                onImageLoaded={onImageLoaded}
                disabled={waiting}
                onChange={(newCrop) => setCrop(newCrop)}
            />
            <div className="la-modal__actions">
                <button
                    className="Button Button--flat"
                    aria-labelledby="close-modal"
                    disabled={waiting}
                    onClick={onSubmitted}
                >
                    Fortryd
                </button>
                <button className="Button" aria-labelledby="close-modal" disabled={waiting} onClick={handleSubmit}>
                    {waiting ? "Vent..." : "Gem"}
                </button>
            </div>
        </>
    )
}

export default EditProfileImg

EditProfileImg.propTypes = {
    user: PropTypes.any.isRequired,
    src: PropTypes.any,
    onSubmitted: PropTypes.func.isRequired,
}
