import PropTypes from "prop-types"

import icons from "../../assets/icons.svg"
const checkmarkIcon = icons + "#checkmark"
const circleIcon = icons + "#circle"

const FormInputOption = (props) => {
    const { title, optionId, selectedOption, onChange } = props

    const indicatorIcon = selectedOption === optionId ? checkmarkIcon : circleIcon

    return (
        <div className="Form__inputwrapper Form__radio">
            <input
                type="radio"
                name="option"
                id={optionId}
                checked={selectedOption === optionId ? true : false}
                value={optionId}
                onChange={onChange}
            />
            <label htmlFor={optionId}>
                <span>{title}</span>
                <svg>
                    <use xlinkHref={indicatorIcon}></use>
                </svg>
            </label>
        </div>
    )
}

export default FormInputOption

FormInputOption.propTypes = {
    title: PropTypes.string.isRequired,
}
