interface Props {
    email: string
    handleClick: () => void
}

const LinkSent = ({ handleClick, email }: Props) => (
    <>
        <h3>Din invitation er sendt</h3>
        <p>
            Når{" "}
            <strong>
                <em>{email}</em>
            </strong>{" "}
            har accepteret din anmodning, vil han/hun have mulighed for at se dine ønsker.
        </p>
        <div className="info">
            <p>Info:</p>
            <p>Du kan ikke se vedkommendes ønsker før han/hun selv har givet tilladelse.</p>
        </div>
        <div className="la-modal__actions">
            <button
                className="Button"
                style={{ marginLeft: "auto", marginBottom: 0 }}
                aria-labelledby="close-modal"
                onClick={handleClick}
            >
                OK
            </button>
        </div>
    </>
)

export default LinkSent
