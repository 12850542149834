import { ArrowForwardRounded } from "@mui/icons-material"
import { Typography } from "@mui/material"

interface Props {
    title: string
    body: string
}

const LegalCard = ({ title, body }: Props) => (
    <div className="legal-card">
        <div className="legal-card__title-row">
            <h3 className="legal-card__title-row__title">{title}</h3>
            <ArrowForwardRounded />
        </div>
        <Typography>{body}</Typography>
    </div>
)

export default LegalCard
