import { firestore } from "../firebase"

export const getLink = async (linkID: string): Promise<ILink> => {
    const docSnap = await firestore.collection("links").doc(linkID).get()
    return docSnap.data() as ILink
}

export const acceptLink = (linkID: string, data: any) => firestore.collection("links").doc(linkID).update(data)

export const deleteLink = (linkID: string) => firestore.collection("links").doc(linkID).delete()

export const updateLinkStatus = (linkID: string, status: number) =>
    firestore.collection("links").doc(linkID).update({ status: status })
