import { firestore, storage, cloudfunctions } from "../firebase"
import firebase from "firebase/app"

export const setPhoto = async (photo: Blob, user: firebase.User) => {
    const storageRef = storage.ref().child(`users/${user.uid}/profile_photo/profile.jpg`)

    await storageRef.put(photo)

    const downloadURL = await storageRef.getDownloadURL()

    const updateUserPhoto = firestore.doc(`users/${user.uid}`).update({
        photo: downloadURL,
    })

    const updateLinks = cloudfunctions.httpsCallable("link_update_photo_v2")(downloadURL)

    return await Promise.all([updateUserPhoto, updateLinks])
}

export const deletePhoto = async (user: firebase.User) => {
    const storageRef = storage.ref().child(`users/${user.uid}/profile_photo/profile.jpg`)

    await storageRef.delete()

    const deleteUserPhoto = firestore.doc(`users/${user.uid}`).update({
        photo: firebase.firestore.FieldValue.delete(),
    })

    const updateLinks = cloudfunctions.httpsCallable("link_update_photo_v2")()

    return await Promise.all([deleteUserPhoto, updateLinks])
}
