import { BASE_URL, REQUEST_INIT, MODEL_GUIDE } from "./datocms"
import { cloudfunctions } from "../firebase"

export const getGuide = async (name: string): Promise<IGuide | undefined> => {
    const query = `{ ${MODEL_GUIDE}(filter: {active: {eq: "true"}, name: {eq: "${name}"}}) { name content } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            return res.data[MODEL_GUIDE] as IGuide
        })
        .catch((error) => {
            console.log(error)
            return undefined
        })
}

export const logGuideClicked = (name: string) => {
    cloudfunctions.httpsCallable("logGuideClicked")({
        name,
        source: process.env.REACT_APP_IS_PHONE ? "adjo_android" : "adjo_pwa",
    })
}
