import Logo from "../Logo/Logo"
import BurgerNav from "./BurgerNav"

const Header = () => {
    return (
        <header className="Header padding-x" id="Header">
            <BurgerNav />
            <Logo />
            <div />
        </header>
    )
}

export default Header
