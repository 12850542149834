import { Link } from "react-router-dom"

import * as PATHS from "../../routes/paths"

import icons from "../../assets/icons.svg"
const plusIcon = icons + "#plus"

const NewHeirloom = () => (
    <li className="cell small-12 medium-6 large-4">
        <Link
            to={PATHS.HEIRLOOM_CREATE}
            className="heirloom-preview heirloom-new rounded-borders margin-bottom bg-lightblue"
        >
            <svg className="heirloom-preview__image">
                <use xlinkHref={plusIcon} />
            </svg>
            <h4 className="heirloom-preview__title">Tilføj arvestykke</h4>
        </Link>
    </li>
)

export default NewHeirloom
