import { CircularProgress } from "@mui/material"
import { PrintRounded } from "@mui/icons-material"

import Module from "../Module/Module"

interface Props {
    color: string
    subtitle?: string
    title: string
    date?: Date
    dateLabel?: string
    loading: boolean
    onClick: () => void
}

const PrintItem = ({ color, title, subtitle, date, dateLabel, loading, onClick }: Props) => (
    <div className={`cell print-item${loading ? " print-item--loading" : ""}`}>
        <Module color={color} handleClick={onClick}>
            <div className="legal-card">
                <div className="legal-card__title-row">
                    <h3 className="legal-card__title-row__title">{title}</h3>
                    {(loading && <CircularProgress color="inherit" size={32} />) || <PrintRounded />}
                </div>
                <p>
                    {date && (
                        <span>
                            {subtitle && `${subtitle} - `} {dateLabel} {date.toLocaleString().slice(0, -3)}
                        </span>
                    )}
                </p>

            </div>
        </Module>
    </div>
)

export default PrintItem
