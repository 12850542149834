import { CloseRounded } from "@mui/icons-material"

interface Props {
    result: IQuestionnaireResult
    route: IQuestionnaireResultItem[]
    onModalClose: () => void
}

const QuestionnaireRouteDisplay = ({ result, route, onModalClose }: Props) => (
    <div className="questionnaire-route">
        {route.map((item, idx) => (
            <div className="questionnaire-route__item" key={idx}>
                <p>{item.question}</p>
                <h5>{item.answer}</h5>
            </div>
        ))}
        <button className="la-fab" onClick={onModalClose}>
            <CloseRounded />
        </button>
        <p className="questionnaire-route__result-id">{result.id.substring(2)}</p>
    </div>
)

export default QuestionnaireRouteDisplay
