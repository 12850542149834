import { cloudfunctions } from "../firebase"

export const createPayment = async (
    documentName: string,
    documentVersion: number,
    voucher?: string
): Promise<string> => {
    const response = await cloudfunctions.httpsCallable("createPayment")({
        documentName,
        documentVersion,
        platform: process.env.REACT_APP_IS_PHONE ? "android" : "web",
        voucher,
    })

    return response.data
}

export const getLink = async (paymentId: string, amount: number, continueUrl: string): Promise<string> => {
    const response = await cloudfunctions.httpsCallable("getLink")({
        paymentId,
        amount,
        continueUrl,
    })

    return response.data
}

export const handleZeroPayment = async (
    documentName: string,
    documentVersion: number,
    voucher?: string
): Promise<boolean> => {
    try {
        const response = await cloudfunctions.httpsCallable("handleZeroPayment")({
            documentName,
            documentVersion,
            platform: process.env.REACT_APP_IS_PHONE ? "android" : "web",
            voucher,
        })

        return response.data
    }
    catch (e) {
        return false
    }
}
