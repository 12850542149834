import firebase from "firebase/app"
import { DynamicHomeItem } from "./HomeItem"

/*********************************************************************
 * ImportantQuestion
 *********************************************************************/

export class ImportantQuestion {
    constructor(
        readonly id: string,
        readonly categoryId: string,
        readonly topicId: string,
        readonly questionId: string
    ) {}
}

export const importantQuestionConverter = {
    toFirestore(iq: ImportantQuestion): firebase.firestore.DocumentData {
        return {
            id: iq.id,
            categoryId: iq.categoryId,
            topicId: iq.topicId,
            questionId: iq.questionId,
        }
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): ImportantQuestion {
        const data = snapshot.data(options)!
        return new ImportantQuestion(data.id, data.categoryId, data.topicId, data.questionId)
    },
}

/*********************************************************************
 * ImportantQuestions
 *********************************************************************/

export class ImportantQuestions {
    constructor(readonly data: [ImportantQuestion], readonly display: DynamicHomeItem, readonly intro: Intro) {}
}

export const importantQuestionsConverter = {
    toFirestore(iqs: ImportantQuestions): firebase.firestore.DocumentData {
        return {
            data: iqs.data,
            display: iqs.display,
            intro: iqs.intro,
        }
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): ImportantQuestions {
        const data = snapshot.data(options)!
        return new ImportantQuestions(data.data, data.display, data.intro)
    },
}

/*********************************************************************
 * Intro
 *********************************************************************/

export class Intro {
    constructor(readonly title: string, readonly body: string, readonly img?: string) {}
}

export const introConverter = {
    toFirestore(intro: Intro): firebase.firestore.DocumentData {
        return {
            title: intro.title,
            body: intro.body,
            img: intro.img,
        }
    },
    fromFirestore(
        snapshot: firebase.firestore.QueryDocumentSnapshot,
        options: firebase.firestore.SnapshotOptions
    ): Intro {
        const data = snapshot.data(options)!
        return new Intro(data.title, data.body, data.img)
    },
}
