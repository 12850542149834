import { styled } from "@mui/system"
import { ArcElement, Chart as ChartJS, Legend, Tooltip } from "chart.js"
import { Doughnut } from "react-chartjs-2"

ChartJS.register(ArcElement, Tooltip, Legend)
ChartJS.defaults.font.family = "'Source Sans Pro'"
ChartJS.defaults.font.size = 18
ChartJS.defaults.color = "#1c1c1c"
ChartJS.defaults.plugins.legend.position = "right"

interface Props {
    values: Array<number>
    labels: Array<string>
    caption?: string
}

const options = {
    cutout: "85%",
    aspectRatio: 2,
    events: [],
    plugins: {
        tooltip: { enabled: false },
    },
}

const colors = ["#e5edee", "#d0e3be", "#ffedca", "#f7867d"]

const Q11eOverviewChart = ({ values, labels, caption }: Props) => {
    const data_ = {
        labels: labels.map((label, idx) => `${label}: ${values[idx]}%`),
        datasets: [
            {
                data: values,
                backgroundColor: colors,
                // borderColor: "#1c1c1c",
                borderColor: "#a1a1a1",
                borderWidth: 1,
                spacing: 4,
            },
        ],
    }

    return (
        <Wrapper>
            <ChartWrapper>
                <Doughnut data={data_} options={options} />
            </ChartWrapper>
            {caption && <p style={{ whiteSpace: "pre-line" }}>{caption}</p>}
        </Wrapper>
    )
}

export default Q11eOverviewChart

const Wrapper = styled("div")({
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    width: "100%",
})

const ChartWrapper = styled("div")({
    position: "relative",
    width: "100%",
    maxWidth: 700,
    margin: "16px 0 32px",
})
