import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useDocument = (uid: string, name: string): IQ11eDoc | undefined => {
    const [document, setDocument] = useState<IQ11eDoc>()

    useEffect(() => {
        const subscribe = () =>
            firestore
                .collection("user_documents")
                .doc(uid)
                .collection(name)
                .onSnapshot((querySnap) => {
                    let documents: IQ11eDoc[] = []
                    querySnap.forEach((queryDocSnap) => {
                        const document = queryDocSnap.data() as IQ11eDoc
                        if (!document.obsolete) {
                            documents.push(document)
                        }
                    })

                    if (documents.length) {
                        setDocument(documents[0])
                    } else {
                        setDocument(undefined)
                    }
                })

        return subscribe()
    }, [uid, name])

    return document
}

export default useDocument
