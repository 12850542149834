import { useEffect, useState } from "react"
import { Link, useHistory, useParams, useRouteMatch } from "react-router-dom"
import { Fade } from "@mui/material"
import { ArrowBackRounded, ArrowForwardRounded, RestartAlt } from "@mui/icons-material"

import useQ11ePreview from "../hooks/Q11e/useQ11ePreview"
import { restart, updateStartTime, isQ11eStarted } from "../../api/Q11eStateManager"
import Intro from "../Intro/Intro"
import WithUser from "../hoc/WithUser"

interface Props {
    user: { uid: string }
}

interface Params {
    q11eName: string
    q11eVersion: string
}

const PageQ11eStart = ({ user }: Props) => {
    const [isStarted, setIsStarted] = useState<boolean>()

    const history = useHistory()
    const match = useRouteMatch()
    const { q11eName, q11eVersion } = useParams<Params>()
    const q11ePreview = useQ11ePreview(q11eName, q11eVersion)

    useEffect(() => {
        isQ11eStarted(user.uid, q11eName, q11eVersion).then(setIsStarted)
    }, [user.uid, q11eName, q11eVersion])

    if (!q11ePreview) return null

    const handleStartClicked = () => updateStartTime(user.uid, q11eName, q11eVersion)
    const handleRestartClicked = () => restart(user.uid, q11eName, q11eVersion)

    return (
        <>
            <button className="la-fab margin-bottom" onClick={history.goBack}>
                <ArrowBackRounded />
            </button>
            <Intro
                color={q11ePreview.color}
                title={q11ePreview.displayName}
                text={
                    q11ePreview.name === "fremtidsfuldmagt" ? (
                        <>
                            Her kan du købe din fremtidsfuldmagt ved at besvare nogle få spørgsmål.
                            <br />
                            Din fremtidsfuldmagt koster 500 kr. som du betaler online i slutningen af processen.
                        </>
                    ) : (
                        `${q11ePreview.intro1}${q11ePreview.intro2 ? "\n\n" + q11ePreview.intro2 : ""}`
                    )
                }
            />
            <Fade in={isStarted !== undefined}>
                <div className="q11e-nav q11e-nav--start">
                    {isStarted && (
                        <Link
                            to={`${match.url}/q1`}
                            className="Button Button--outline Button--icon"
                            onClick={handleRestartClicked}
                        >
                            <span>Start forfra</span>
                            <RestartAlt />
                        </Link>
                    )}
                    <Link to={`${match.url}/q1`} className="Button Button--icon" onClick={handleStartClicked}>
                        <span>{isStarted ? "Fortsæt" : "Start"}</span>
                        <ArrowForwardRounded />
                    </Link>
                </div>
            </Fade>
        </>
    )
}

export default WithUser(PageQ11eStart)
