import { createContext, useContext, useEffect, useState } from "react"
import { firestore } from "../firebase"
import firebase from "firebase"

interface IRouteContext {
    loading: boolean
    routes: {
        [routeId: string]: IQuestionnaireRoute
    }
}

const RouteContext = createContext<IRouteContext>({
    loading: true,
    routes: {},
})

interface Props {
    userId?: string
    children: any
}

export const ProvideQuestionnaireRoute = ({ userId, children }: Props) => {
    const routeContext = useProvideRouteContext(userId)
    return <RouteContext.Provider value={routeContext}>{children}</RouteContext.Provider>
}

export const useQuestionnaireRoute = () => {
    return useContext(RouteContext)
}

function useProvideRouteContext(userId?: string): IRouteContext {
    const [loading, setLoading] = useState(true)
    const [routes, setRoutes] = useState<{
        [routeId: string]: IQuestionnaireRoute
    }>({})

    const callback = (querySnap: firebase.firestore.QuerySnapshot) => {
        const routes: {
            [routeId: string]: IQuestionnaireRoute
        } = {}
        querySnap.forEach((doc) => {
            routes[doc.id] = doc.data() as IQuestionnaireRoute
        })
        setRoutes(routes)
        setLoading(false)
    }

    useEffect(() => {
        if (!userId) return
        return firestore.collection("users").doc(userId).collection("questionnaireRoutes").onSnapshot(callback)
    }, [userId])

    return {
        loading,
        routes,
    }
}
