export const SIGN_UP = "/signup"
export const SIGN_IN = "/login"
export const FORGOT_PASSWORD = "/glemt-adgangskode"

export const HOME = "/"
export const IMPORTANT_QUESTIONS = "/important-questions/"
export const BYE = "/mit-farvel/"
export const CHARITIES = `${BYE}velgorenhed`
export const CHARITIES_OTHER = `${CHARITIES}/anden`
export const CHARITY_OTHER = `${CHARITIES}/anden/:charityName`
export const CHARITY = `${CHARITIES}/:charityName`
export const LIFE = "/mit-liv/"
export const LINKS = "/mine-kaere"
export const LINK = `${LINKS}/:id`
export const LINK_PRINT = `${LINKS}/:linkID/print`
export const INHERITANCE = "/min-arv"
export const TESTAMENT = `${INHERITANCE}/testamente`
export const FREMTIDSFULDMAGT = `${TESTAMENT}/:q11eName/:q11eVersion`
export const HEIRLOOMS = `${INHERITANCE}/mine-ting`
export const HEIRLOOM_CREATE = `${HEIRLOOMS}/tilføj-arvestykke`
export const HEIRLOOM_EDIT = `${HEIRLOOMS}/:id/rediger`
export const HEIRLOOM = `${HEIRLOOMS}/:id`
export const DIGITAL_INHERITANCE = `${INHERITANCE}/digital-arv`
export const PRODUCTS = "/produkter"

export const QUESTIONNAIRE = "/min-arv/questionnaire"
export const QUESTIONNAIRE_RESULT = "/min-arv/questionnaire_result"

export const LEGAL = "/min-arv/legal"

export const PROFILE = "/min-profil"
export const PRINT = "/print"
export const PRINT_LINK = `${PRINT}/:linkID`
export const ABOUT = "/om-adjo"
export const PARTNERS = "/partnerskaber"
export const COLLABS = "/samarbejder"
export const POLICY = "/privatlivspolitik"
export const TRADING_TERMS = "/handelsbetingelser"
export const FEEDBACK = "/kontakt-os"

export const DOCUMENT = "/dokumenter"

export const PUBLIC_BUCKET = "https://storage.googleapis.com/adjo_public"
