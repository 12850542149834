import { useEffect, useState } from "react"

import { getQuestion } from "../../../api/Q11eContentManager"

const useQ11eQuestion = (q11eName: string, q11eVersion: string, questionId: string): IQ11eQuestion | undefined => {
    const [question, setQuestion] = useState<IQ11eQuestion>()

    useEffect(() => {
        const q11eId = q11eName + "_" + q11eVersion
        getQuestion(q11eId, questionId).then(setQuestion)
    }, [q11eName, q11eVersion, questionId])

    return question
}

export default useQ11eQuestion
