import { firestore } from "../firebase"

export const setAnswer = (userId: string, questionnaireId: string, questionId: string, answerId: string) => {
    try {
        firestore
            .collection("users")
            .doc(userId)
            .collection("questionnaireRoutes")
            .doc(questionnaireId)
            .set(
                {
                    [questionId]: answerId,
                },
                { merge: true }
            )
    } catch (e) {
        console.error(e)
    }
}

export const deleteAllAnswers = (userId: string, questionnaireId: string) => {
    try {
        firestore.collection("users").doc(userId).collection("questionnaireRoutes").doc(questionnaireId).delete()
    } catch (e) {
        console.error(e)
    }
}
