import { useParams } from "react-router-dom"
import { useEffect, useState } from "react"
import classNames from "classnames"

import icons from "../../assets/icons.svg"
import { setRadioAnswer } from "../../api/Q11eStateManager"
import { auth } from "../../firebase"
import Alert from "../dialogs/Alert"

interface Props {
    answer: IQ11eRadioAnswer
    selected: boolean
}

interface Params {
    q11eName: string
    q11eVersion: string
    questionId: string
}

const Q11eAnswerRadio = ({ answer, selected }: Props) => {
    const { q11eName, q11eVersion, questionId } = useParams<Params>()
    const [showWarning, setShowWarning] = useState(false)

    const radioOff = icons + "#radio-off"
    const radioOn = icons + "#radio-on"

    const handleClick = () => {
        setRadioAnswer(auth.currentUser!.uid, q11eName, q11eVersion, questionId, answer)
    }

    const classes = classNames("questionnaire-answer", {
        "questionnaire-answer--selected": selected,
    })

    useEffect(() => {
        if (answer.warning && selected) {
            setShowWarning(true)
        }
    }, [answer.warning, selected])


    return (
        <>
            <div className={classes} onClick={handleClick}>
                <label>{answer.txt}</label>
                <svg>
                    <use xlinkHref={selected ? radioOn : radioOff} />
                </svg>
            </div>
            {answer.warning && (
                <Alert
                    title={`${answer.warning.title}`}
                    body={answer.warning.body}
                    isOpen={showWarning}
                    handleClose={() => setShowWarning(false)}
                />
            )}
        </>
    )
}

export default Q11eAnswerRadio
