import { ChangeEvent, FormEvent, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { FormControl, InputLabel, MenuItem, Select, SelectChangeEvent, TextField } from "@mui/material"
import { ArrowBackRounded, DeleteRounded, DoneRounded } from "@mui/icons-material"
import { useSnackbar } from "notistack"

import { createHeirloomStorageRef, deleteHeirloom, getOrCreateHeirloomDocRef } from "../../api/HeirloomManager"
import FormInputImage from "../Form/FormInputImage"
import Alert from "../dialogs/Alert"
import Prompt from "../dialogs/Prompt"
import WithUser from "../hoc/WithUser"
import WithLinks from "../hoc/WithLinks"
import { useHeirloom } from "../hooks/useHeirloom"
import * as PATHS from "../../routes/paths"

interface Props {
    user: { uid: string }
    links: ILink[]
}

interface Params {
    /**
     * The ID of the heirloom being edited (part of the URL).
     * If this is defined, the heirloom is being edited.
     */
    id: string | undefined
}

const HeirloomForm = ({ user, links }: Props) => {
    const history = useHistory()
    const { id } = useParams<Params>()
    const heirloom = useHeirloom(user.uid, id)
    const inputImgRef = useRef<FormInputImage>(null)
    const { enqueueSnackbar } = useSnackbar()

    /* Heirloom model properties */
    const [description, setDescription] = useState("")
    const [value, setValue] = useState("")
    const [linkID, setLinkID] = useState("")
    const [receiverName, setReceiverName] = useState("")

    const [loading, setLoading] = useState(false)
    const [error, setError] = useState<IHeirloomError>()
    const [deletePromptOpen, setDeletePromptOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)

    const [linkOptions, setLinkOptions] = useState<ILinkOption[]>([])
    useEffect(() => {
        if (!user) return
        const linkOptions = links
            .filter((link) => link.status !== 0)
            .map((link) => ({
                value: link.id,
                label: link.creator_id === user.uid ? link.invitee_name : link.creator_name,
            }))
        setLinkOptions([{ value: "", label: "—" }].concat(linkOptions))
    }, [user, links])

    useEffect(() => {
        if (!heirloom) return
        setDescription(heirloom.description)
        setValue(String(heirloom.value ?? ""))
        if (heirloom.link_id) {
            setLinkID(heirloom.link_id)
        } else if (heirloom.receiver_name) {
            setReceiverName(heirloom.receiver_name)
        }
    }, [heirloom])

    const clearError = () => setError(undefined)

    const handleDescriptionChange = (event: ChangeEvent<HTMLInputElement>) => setDescription(event.target.value)

    const handleLinkIDChange = (event: SelectChangeEvent) => {
        setLinkID(event.target.value)
        setReceiverName("")
    }

    const handleReceiverNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        setReceiverName(event.target.value)
        setLinkID("")
    }

    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const strValue = event.target.value
        if (strValue === "") {
            setValue(strValue)
        }

        const intValue = parseInt(strValue, 10)
        if (!isNaN(intValue) && intValue > -1) {
            setValue(String(intValue))
        }
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()

        if (!user || loading || error) return
        setLoading(true)

        if (!receiverName.length && !linkID.length) {
            setLoading(false)
            setError({
                title: "Angiv en arvtager",
                txt: "Du bedes enten vælge en af dine kære eller indtaste et navn.",
            })
            return
        }
        if (!description.length) {
            setLoading(false)
            setError({
                title: "Beskriv arvestykket",
                txt: "Du bedes give en beskrivelse af arvestykket.",
            })
            return
        }

        const heirloomDocRef = getOrCreateHeirloomDocRef(user.uid, id)

        const newHeirloom: IHeirloom = {
            id: heirloomDocRef.id,
            date_created: heirloom?.date_created ?? new Date(),
            description,
        }

        if (value.length) {
            const intValue = parseInt(value, 10)
            if (!isNaN(intValue) && intValue > 0) {
                newHeirloom.value = intValue
            }
        }

        if (linkID.length) {
            /* If a link is selected get the receiver name from it */
            newHeirloom.link_id = linkID
            const link = links.find((link) => link.id === linkID)!
            const isCreator = link.creator_id === user.uid
            newHeirloom.receiver_name = isCreator ? link.invitee_name : link.creator_name
        } else {
            newHeirloom.receiver_name = receiverName
        }

        if (!inputImgRef.current?.state.photoMarkedForDeletion) {
            const img = await inputImgRef!.current!.makeClientCrop()
            if (img) {
                /* Either a new photo has been selected */
                const storageRef = createHeirloomStorageRef(user.uid, heirloomDocRef.id)
                await storageRef.put(img)
                newHeirloom.photo_url_str = await storageRef.getDownloadURL()
            } else if (heirloom?.photo_url_str) {
                /* Or we use the existing one */
                newHeirloom.photo_url_str = heirloom.photo_url_str
            }
        } else {
            /* Delete the file if it exists */
            const storageRef = createHeirloomStorageRef(user.uid, heirloomDocRef.id)
            await storageRef.delete()
        }

        try {
            await heirloomDocRef.set(newHeirloom)
            enqueueSnackbar(`Arvestykke ${heirloom ? "opdateret" : "tilføjet"}`)
            history.goBack()
        } catch (error) {
            console.error("Error creating heirloom: ", error)
        } finally {
            setLoading(false)
        }
    }

    const handleDeleteClicked = async () => {
        if (isDeleting) return
        setIsDeleting(true)
        await deleteHeirloom(user.uid, id!)
        enqueueSnackbar("Arvestykke slettet")
        history.replace(PATHS.HEIRLOOMS)
    }

    return (
        <div className="heirloom-form">
            <nav className="nav-bar">
                <button className="la-fab" onClick={history.goBack}>
                    <ArrowBackRounded />
                </button>
                <h3 className="heirloom-form__title">{heirloom ? "Redigér" : "Tilføj"} arvestykke</h3>
            </nav>
            <form className="la-modal__form" onSubmit={handleSubmit}>
                <FormInputImage photo={heirloom?.photo_url_str} ref={inputImgRef} />
                <TextField
                    variant="outlined"
                    required
                    value={description}
                    label="Beskrivelse"
                    onChange={handleDescriptionChange}
                    name="description"
                    type="text"
                    margin="normal"
                />
                <TextField
                    variant="outlined"
                    value={value}
                    label="Anslået værdi (DKK)"
                    onChange={handleValueChange}
                    name="value"
                    type="number"
                    margin="normal"
                />
                <FormControl fullWidth margin="normal">
                    <InputLabel id="link-id-label">Vælg én af dine kære</InputLabel>
                    <Select
                        labelId="link-id-label"
                        id="link-id"
                        value={linkID}
                        label="Vælg én af dine kære"
                        onChange={handleLinkIDChange}
                    >
                        {linkOptions.map((lo) => (
                            <MenuItem key={lo.value} value={lo.value}>
                                {lo.label}
                            </MenuItem>
                        ))}
                    </Select>
                </FormControl>
                <TextField
                    variant="outlined"
                    value={receiverName}
                    label="Eller indtast et navn"
                    onChange={handleReceiverNameChange}
                    name="name"
                    type="text"
                    margin="normal"
                    style={{ marginBottom: 24 }}
                />
                <div className="la-modal__actions">
                    {heirloom && (
                        <button
                            type="button"
                            className="Button Button--flat"
                            disabled={loading}
                            onClick={() => setDeletePromptOpen(true)}
                        >
                            <span>Slet arvestykke</span>
                        </button>
                    )}
                    <button className="Button Button--green Button--icon" disabled={loading}>
                        <span>{loading ? "Vent..." : heirloom ? "Gem ændringer" : "Tilføj"}</span>
                        <DoneRounded />
                    </button>
                </div>
            </form>

            <Alert title={error?.title ?? ""} body={error?.txt ?? ""} isOpen={!!error} handleClose={clearError} />
            <Prompt
                title="Vil du slette arvestykket?"
                body="Handlingen kan ikke fortrydes."
                isOpen={deletePromptOpen}
                handleClose={() => setDeletePromptOpen(false)}
            >
                <button className="Button Button--red Button--icon" disabled={isDeleting} onClick={handleDeleteClicked}>
                    <span>{isDeleting ? "Vent..." : "Slet"}</span>
                    <DeleteRounded />
                </button>
            </Prompt>
        </div>
    )
}

export default WithUser(WithLinks(HeirloomForm))
