import { firestore } from "../firebase"
import { ImportantQuestion, importantQuestionsConverter } from "../model/ImportantQuestion"
import { Answer } from "../model/Answer"

export const getImportantQuestions = async () => {
    const contentVersion = "v1"

    const docSnap = await firestore
        .collection("importantQuestions")
        .doc(contentVersion)
        .withConverter(importantQuestionsConverter)
        .get()

    return docSnap.data()
}

export const shouldDisplayCard = (iqs: [ImportantQuestion], categories: any, wishes: any): boolean => {
    let iqsAnswered = 0

    for (const iq of iqs) {
        // Find the actual Question that the ImportantQuestion is referring to
        const category = categories.find((c: any) => c.id === iq.categoryId)
        const topic = category?.topics.find((t: any) => t.id === iq.topicId)
        const question = topic?.questions.find((q: any) => q.id === iq.questionId)

        if (question) {
            // Find the Answer to the Question
            const c = wishes[category.id]
            const t = c?.[topic.id]
            const answer = t?.[question.id]
            if (answer && Answer.isResolved(question, answer)) {
                iqsAnswered += 1
            }
        }
    }

    return iqs.length > iqsAnswered
}
