const PageFeedback = () => (
    <>
        <h2>Kontakt os</h2>
        <p>
            Du er velkommen til at sende os en e-mail, hvis du finder en fejl, har forslag til forbedringer eller andre
            kommentarer.
        </p>
        <a href="mailto:support@adjo.dk" style={{ textDecoration: "underline" }}>
            support@adjo.dk
        </a>
    </>
)

export default PageFeedback
