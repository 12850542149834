import { firestore } from "../../../firebase"

import { Error } from "./model"
import { logCurrentEnv } from "../../../api/environmentManager"

export const resolveError = (errorCode: string): Error => {
    if (errorCode === "auth/email-already-in-use") {
        return {
            title: "Ugyldig e-mail",
            txt: "Den indtastede e-mail er allerede i brug.",
        }
    } else if (errorCode === "auth/weak-password") {
        return {
            title: "Ugyldig adgangskode",
            txt: "Din adgangskode skal bestå af minimum 6 tegn.",
        }
    } else if (errorCode === "auth/invalid-email") {
        return {
            title: "Ugyldig e-mail",
            txt: "Den indtastede e-mail er ikke en gyldig e-mailadresse.",
        }
    } else {
        return {
            title: "Uventet fejl",
            txt: "Der skete en uventet fejl.\nKontakt venligst vores support afdeling.",
        }
    }
}

export const createUser = async (userId: string, email: string, firstName: string, surname: string, checkedMarketing: boolean) => {
    let marktingAcceptedTimestamp: Date | null
    if (checkedMarketing) {
        marktingAcceptedTimestamp = new Date()
    } else {
        marktingAcceptedTimestamp = null
    }
    
    const createUserDocument = firestore.collection("users").doc(userId).set({
        first_name: firstName.trim(),
        surname: surname.trim(),
        email: email,
        marketingAcceptedAt: marktingAcceptedTimestamp,
    })

    /* Create sub-collections on user document */
    const createWishesDocument = firestore.collection("users").doc(userId).collection("wishes").doc("v1").set({})
    const createLifeDocument = firestore.collection("users").doc(userId).collection("life").doc("v1").set({})

    await Promise.all([createUserDocument, createWishesDocument, createLifeDocument, logCurrentEnv(userId)])
}
