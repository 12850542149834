import { useState } from "react"
import { CameraAltRounded, DeleteRounded } from "@mui/icons-material"

import { deletePhoto } from "../../api/ProfilePhotoManager"

const ProfilePhotoActions = ({ user, onCancel, onChange }) => {
    const [waiting, setWaiting] = useState(false)

    const deletePhotoClicked = async () => {
        setWaiting(true)

        try {
            await deletePhoto(user)
        } catch (err) {
            alert(err)
        }

        onCancel()
    }

    return (
        <div className="profile-photo-actions">
            <h2>Rediger profilfoto</h2>
            <button className="Button Button--icon" onClick={onChange} disabled={waiting}>
                <span>Skift foto</span>
                <CameraAltRounded />
            </button>
            {(user.photo || waiting) && (
                <button className="Button Button--icon Button--red" onClick={deletePhotoClicked} disabled={waiting}>
                    <span>{waiting ? "Vent..." : "Slet foto"}</span>
                    <DeleteRounded />
                </button>
            )}
            <button className="Button Button--flat" aria-labelledby="close-modal" onClick={onCancel} disabled={waiting}>
                Fortryd
            </button>
        </div>
    )
}

export default ProfilePhotoActions
