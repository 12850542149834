import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useOtherCharity = (name?: string, userId?: string): ICharityOther | undefined => {
    const [charity, setCharity] = useState<ICharityOther>()

    useEffect(() => {
        if (!name || !userId) return

        const subscribe = () => {
            firestore
                .collection("users")
                .doc(userId)
                .collection("charityOtherDonations")
                .doc(name)
                .onSnapshot((docSnap) => {
                    const charity = docSnap.data() as ICharityOther
                    setCharity(charity)
                })
        }

        return subscribe()
    }, [userId, name])

    return charity
}

export default useOtherCharity
