export class Answer {
    constructor(
        readonly items?: [string],
        readonly txtAnswer?: { string: any },
        readonly isIgnored?: boolean,
        readonly comment?: string,
        readonly optionID?: string
    ) {}

    static isResolved = (question: { string: any }, answer: { string: any }): boolean => {
        if (answer && Object.keys(answer).length) {
            // @ts-ignore
            if (answer.optionID) {
                return true
            } else {
                // @ts-ignore
                if (answer.txtAnswer?.isChecked) {
                    return true
                } else {
                    // @ts-ignore
                    if (answer.items?.length) {
                        return true
                    } else {
                        // @ts-ignore
                        if (answer.isIgnored) {
                            return true
                        } else {
                            // @ts-ignore
                            if (question.noAdd) {
                                // @ts-ignore
                                if (question.isAux) {
                                    return false
                                } else {
                                    // @ts-ignore
                                    if (answer.comment?.length) {
                                        return true
                                    }
                                }
                            }
                        }
                    }
                }
            }
        }

        return false
    }
}
