import classNames from "classnames"
import InlineLoader from "../Loader/InlineLoader"

interface Props {
    isAwaitingFile: boolean
    handlePrintClicked: () => void
}

const GiftcardSteps = ({ isAwaitingFile, handlePrintClicked }: Props) => {


    const printBtnClasses = classNames("Button Button--green q11e-storefront__btn", {
        "Button--loading-inline": isAwaitingFile,
    })

    return (
        <>
            <h3>Næste skridt?</h3>
            <p className="q11e-storefront__txt">Download dit gavekort her:</p>
            <button className={printBtnClasses} onClick={handlePrintClicked}>
                {(isAwaitingFile && <InlineLoader />) || (
                    <> 
                        <span>Download gavekort</span>
                    </>
                )}
            </button>
        </>
    )
}

export default GiftcardSteps
