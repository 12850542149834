import { Component, createContext } from "react"
import { firestore } from "../firebase"

import WithUser from "../components/hoc/WithUser"

export const LinksContext = createContext({ links: [] })

class LinksProvider extends Component {
    state = {
        outboundLinks: [],
        inboundLinks: [],
        linksFetched: false,
    }

    unsubscribeFromOutboundLinks = null
    unsubscribeFromInboundLinks = null

    componentDidMount() {
        if (!this.props.user) {
            return
        }
        this.listenForLinks(this.props.user)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { user } = this.props
        if (!user) {
            if (this.unsubscribeFromOutboundLinks && this.unsubscribeFromInboundLinks) {
                this.unsubscribeFromOutboundLinks()
                this.unsubscribeFromOutboundLinks = null
                this.unsubscribeFromInboundLinks()
                this.unsubscribeFromInboundLinks = null
                this.setState({
                    outboundLinks: [],
                    inboundLinks: [],
                    linksFetched: false,
                })
            }
        } else if (!this.state.linksFetched) {
            this.listenForLinks(user)
        }
    }

    listenForLinks({ uid, email }) {
        this.setState({ linksFetched: true })
        this.unsubscribeFromOutboundLinks = firestore
            .collection("links")
            .where("creator_id", "==", uid)
            .onSnapshot((snapshot) => {
                const outboundLinks = []
                snapshot.forEach((doc) => outboundLinks.push(doc.data()))
                this.setState({ outboundLinks })
            })
        this.unsubscribeFromInboundLinks = firestore
            .collection("links")
            .where("invitee_email", "==", email)
            .onSnapshot((snapshot) => {
                const inboundLinks = []
                snapshot.forEach((doc) => inboundLinks.push(doc.data()))
                this.setState({ inboundLinks })
            })
    }

    render() {
        const { outboundLinks, inboundLinks } = this.state

        // TODO: Sort these?
        const links = inboundLinks.concat(outboundLinks)

        return <LinksContext.Provider value={links}>{this.props.children}</LinksContext.Provider>
    }
}

export default WithUser(LinksProvider)
