import Intro from "../Intro/Intro"
import { useHistory } from "react-router-dom"

import useFremtidsfuldmagtPreview from "../hooks/Q11e/useFremtidsfuldmagtPreview"
import useBornetestamentePreview from "../hooks/Q11e/useBornetestamentePreview"
import useGiftCardPreview from "../hooks/Q11e/useGiftCardPreview"
// import useTestamentePreview from "../hooks/Q11e/useTestamentePreview"
import Module from "../Module/Module"
import LegalCard from "../legal/LegalCard"
import WithUser from "../hoc/WithUser"
import { DOCUMENT, TESTAMENT } from "../../routes/paths"
import { isQ11eCompleted } from "../../api/Q11eStateManager"

interface Props {
    user: { uid: string }
}

const PageProducts = ({ user }: Props) => {
    const history = useHistory()

    const fremtidsfuldmagtPreview = useFremtidsfuldmagtPreview()
    const bornetestamentePreview = useBornetestamentePreview()
    const giftCardPreview = useGiftCardPreview()
    // const testamentePreview = useTestamentePreview()

    const handleQ11eClicked = async (q11eId: string, q11eName: string, q11eVersion: number) => {
        const isCompleted = await isQ11eCompleted(user.uid, q11eId)
        if (isCompleted) {
            history.push(`${DOCUMENT}/${q11eName}/${q11eVersion}`)
        } else {
            history.push(`${TESTAMENT}/${q11eName}/${q11eVersion}`)
        }
    }

    return (
        <div>
            <Intro
                title="Produkter"
                text="Hos Adjø har du mulighed for at købe fremtidsfuldmagt, børnetestamente og snart testamente. Ved at klikke på de enkelte produkter nedenfor kan du se mere information samt priser."
                color="white"
            />
            <div>
                {/*{[fremtidsfuldmagtPreview, bornetestamentePreview, testamentePreview]*/}
                {[giftCardPreview, fremtidsfuldmagtPreview, bornetestamentePreview]
                    .filter((preview): preview is IQ11ePreview => !!preview)
                    .map((preview) => (
                        <Module
                            key={preview.id}
                            color={preview.color}
                            handleClick={() => handleQ11eClicked(preview.id, preview.name, preview.version)}
                        >
                            <LegalCard title={preview.displayName} body={preview.intro1} />
                        </Module>
                    ))}
            </div>
        </div>
    )
}

export default WithUser(PageProducts)
