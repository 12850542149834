import { ChangeEvent, useState } from "react"
import { Dialog } from "@mui/material"
import classNames from "classnames"

import Guide from "../Guide/Guide"

interface Props {
    addMoreText?: string
    onAddMore: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>, idx: number | undefined) => void
    showAlways: boolean
    showAll: boolean
    idx?: number
    isNumber?: boolean
    helpTxt?: IQ11eHelpText | null
    placeholder: string
    required: boolean
    onChange: (txt: string, idx?: number) => void
    value: string
    previousIsShown: boolean
}

const Q11eAnswerText = ({ addMoreText, onAddMore, showAlways, showAll, idx, isNumber, helpTxt, placeholder, required, onChange, value, previousIsShown }: Props) => {
    const [guideModalOpen, setGuideModalOpen] = useState(false)

    const handleChange = (event: ChangeEvent<HTMLInputElement>) => {
        if (isNumber) {
            onChange(event.target.value.trim(), idx)
        } else {
            onChange(event.target.value, idx)
        }
    }

    const handleAddMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> ) => {
        onAddMore(event, idx)
    }

    const handleGuideClicked = () => {
        setGuideModalOpen(true)
    }

    const helpTxtTitleClasses = classNames("q11e-form__help-txt__title", {
        "q11e-form__help-txt__title--optional": helpTxt?.markOptional,
    })

    const inputFieldClasses = classNames("la-input", {
        "la-input--filled": !!value,
    })

    let buttonText = addMoreText ?? "Tilføj"

    return (
        <>
            {helpTxt && (
                <div className="q11e-form__help-txt">
                    {helpTxt.title && <div className={helpTxtTitleClasses}>{helpTxt.title}</div>}
                    {helpTxt.body && (
                        <div className="q11e-form__help-txt__body">
                            {helpTxt.body}
                            {helpTxt.guide ? (
                                <>
                                    &nbsp;
                                    <button
                                        type="button"
                                        className="guide__more-btn"
                                        style={{ fontSize: "inherit" }}
                                        onClick={handleGuideClicked}
                                    >
                                        Læs mere
                                    </button>
                                </>
                            ) : null}
                        </div>
                    )}
                </div>
            )}
            <div className={`la-input-field ${(!showAlways && !value && !required && !showAll) && "invisible"}`}>
                <input
                    className={inputFieldClasses}
                    type="text"
                    pattern={isNumber ? "\\d+" : ".+"}
                    title={isNumber ? "Angiv venligst et heltal uden kommaer og punktummer" : ""}
                    required={required}
                    value={value}
                    placeholder={placeholder}
                    name={placeholder}
                    onChange={handleChange}
                />
            </div>
            { ((previousIsShown || helpTxt?.title) && !value && !showAlways && !showAll) &&
                        <div className="la-input-field">
                        <button
                            type="button"
                            onClick={handleAddMore}
                            className="la-input add-more-placeholder"
                        >{ buttonText }</button>
                    </div>
            }
            {helpTxt?.guide && (
                <Dialog className="guide-dialog" open={guideModalOpen} onClose={() => setGuideModalOpen(false)}>
                    <Guide open={guideModalOpen} name={helpTxt.guide} onModalClose={() => setGuideModalOpen(false)} />
                </Dialog>
            )}
        </>
    )
}

export default Q11eAnswerText
