import { useEffect, useState } from "react"

import { getQ11ePreview } from "../../../api/Q11eContentManager"
import { FREMTIDSFULDMAGT_NAME, FREMTIDSFULDMAGT_VERSION } from "./constants"

const useFremtidsfuldmagtPreview = (): IQ11ePreview | undefined => {
    const [preview, setPreview] = useState<IQ11ePreview>()

    useEffect(() => {
        getQ11ePreview(FREMTIDSFULDMAGT_NAME, FREMTIDSFULDMAGT_VERSION).then(setPreview)
    }, [])

    return preview
}

export default useFremtidsfuldmagtPreview
