import { useEffect, useState } from "react"

import { getStore } from "../../../api/Q11eContentManager"

const useQ11eStore = (q11eName: string, q11eVersion: string): IQ11eStore | undefined => {
    const [store, setStore] = useState<IQ11eStore>()

    useEffect(() => {
        const q11eId = q11eName + "_" + q11eVersion
        getStore(q11eId).then(setStore)
    }, [q11eName, q11eVersion])

    return store
}

export default useQ11eStore
