import { useEffect, useState } from "react"
import { getPublishedCharities } from "../../../api/CharityManager"

const usePublishedCharities = (): IPublishedCharity[] => {
    const [charities, setCharities] = useState<IPublishedCharity[]>([])

    useEffect(() => {
        getPublishedCharities().then(setCharities)
    }, [])

    return charities
}

export default usePublishedCharities
