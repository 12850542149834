import PropTypes from "prop-types"

const Form = (props) => {
    const { onSubmit, submitBtnTxt, loading, validationErrors, success, successMessage, onCancel, children } = props

    return (
        <form className="Form" onSubmit={onSubmit}>
            {children}
            <div className="Form__action">
                {onCancel && (
                    <button className="Button Button--outline Form__cancel" type="button" onClick={onCancel}>
                        Annuller
                    </button>
                )}
                {success ? (
                    <div className="Form__successMessage">
                        <p>{successMessage}</p>
                    </div>
                ) : (
                    <input
                        type="submit"
                        className="Button"
                        value={loading ? "Vent..." : submitBtnTxt}
                        disabled={loading}
                        style={{ cursor: loading ? "wait" : "pointer" }}
                    />
                )}
            </div>
            {validationErrors && validationErrors.length > 0 && (
                <div className="Form__errorMessage">
                    <ul style={{ margin: 0 }}>
                        {validationErrors.map((error, i) => (
                            <li key={i}>{error}</li>
                        ))}
                    </ul>
                </div>
            )}
        </form>
    )
}

export default Form

Form.propTypes = {
    children: PropTypes.any.isRequired,
    onSubmit: PropTypes.func.isRequired,
    submitBtnTxt: PropTypes.string.isRequired,
    loading: PropTypes.bool.isRequired,
    validationErrors: PropTypes.array,
    success: PropTypes.bool,
    successMessage: PropTypes.string,
    onCancel: PropTypes.func,
}
