import { Component } from "react"
import { Dialog } from "@mui/material"
import PropTypes from "prop-types"

import WithUser from "../hoc/WithUser"

import { saveWish, saveLife, deleteUndertaker } from "../../firebase"

import Form from "./Form"
import FormInputTextarea from "./FormInputTextarea"
import FormInputCheckbox from "./FormInputCheckbox"
import FormInputOption from "./FormInputOption"
import FormUserInputOption from "./FormUserInputOption"
import FormUserInputItem from "./FormUserInputItem"
import FormUserInputNewItem from "./FormUserInputNewItem"
import FormUserInputNewOption from "./FormUserInputNewOption"
import UndertakerSearch from "../Undertaker/UndertakerSearch"
import { SearchRounded } from "@mui/icons-material"
import UndertakerPreview from "../Undertaker/UndertakerPreview"
import UndertakerDetail from "../Undertaker/UndertakerDetail"

class FormAnswer extends Component {
    state = {
        loading: false,
        option: this.props.option,
        items: this.props.items ? this.props.items : [],
        ignore: this.props.ignored,
        comment: this.props.comment,
        ownAnswer: this.props.ownAnswer,
        undertaker: this.props.undertaker,
        newItem: "",
        newOption: "",
        undertakerModalOpen: false,
    }

    setUndertakerModalOpen = (bool) => {
        this.setState({ undertakerModalOpen: bool })
    }

    handleChange = (event) => {
        const { name, value } = event.target
        this.setState({ [name]: value })

        if (event.target.type === "radio") {
            if (this.state.ownAnswer.isChecked) {
                // Can not both have own answer and predefined option checked
                this.setState({
                    ownAnswer: { ...this.state.ownAnswer, isChecked: false },
                })
            }
            if (this.state.ignore) {
                // Can not both ignore and have predefined option checked
                this.setState({ ignore: false })
            }
        }
    }

    handleCheckboxChange = (event) => {
        const { name } = event.target
        if (name === "ignore") {
            if (this.state.option) {
                // Can not both ignore and have other option selected
                this.setState({ option: "" })
            }
            if (this.state.ownAnswer && this.state.ownAnswer.isChecked) {
                // Can not both ignore and have own option selected
                this.setState({
                    ownAnswer: { ...this.state.ownAnswer, isChecked: false },
                })
            }
        }
        this.setState((prevState) => ({
            [name]: !prevState[name],
        }))
    }

    handleChangeItem = (event) => {
        const { value } = event.target
        const index = event.target.getAttribute("index")
        let items = [...this.state.items]
        let item = { ...items[index] }
        item = value
        items[index] = item
        this.setState({ items })
    }

    handleDeleteItem = (event) => {
        event.preventDefault()
        const index = event.target.getAttribute("index")
        let items = [...this.state.items]
        items.splice(index, 1)
        this.setState({ items })
    }

    addNewItem = () => {
        let items = [...this.state.items]
        items.push(this.state.newItem)
        this.setState({
            items,
            newItem: "",
            ignore: false,
        })
    }

    handleChangeOwnAnswer = (event) => {
        const { value } = event.target
        const answer = value
        this.setState((prevState) => ({
            ownAnswer: {
                ...prevState.ownAnswer,
                txt: answer,
            },
        }))
    }

    handleDeleteOwnAnswer = (event) => {
        event.preventDefault()
        this.setState({ ownAnswer: {} })
    }

    handleSelectUndertaker = (event, undertaker) => {
        event.persist()
        event.preventDefault()
        this.setState({ undertaker }, () => {
            this.handleSubmit(event)
        })
    }

    handleDeleteUndertaker = () => {
        this.setUndertakerModalOpen(false)
        this.setState({ undertaker: undefined })
        deleteUndertaker()
    }

    toggleOwnAnswer = () => {
        if (!this.state.ownAnswer.isChecked) {
            // Can not both have predefined option AND own option checked
            if (this.state.option) {
                this.setState({ option: "" })
            }
        }
        this.setState((prevState) => ({
            ownAnswer: {
                ...prevState.ownAnswer,
                isChecked: !prevState.ownAnswer.isChecked,
            },
        }))
    }

    addNewOption = () => {
        const ownAnswer = {
            txt: this.state.newOption,
            isChecked: true,
        }
        this.setState({
            ownAnswer,
            option: "",
            ignore: false,
        })
    }

    handleSubmit = (event) => {
        event.preventDefault()

        if (!this.state.loading) {
            this.setState({ loading: true })

            if (this.props.type === "wish") {
                saveWish(
                    this.props.user.uid,
                    this.props.category,
                    this.props.topic,
                    this.props.question.id,
                    this.state.option,
                    this.state.ownAnswer,
                    this.state.ignore,
                    this.state.comment,
                    this.state.items,
                    this.state.undertaker?.id
                )
                    .then(() => {
                        this.setState({ loading: false })
                        this.props.toggleEditMode()
                    })
                    .catch((error) => {
                        this.setState({ loading: false })
                        console.error("Error saving wish: ", error)
                    })
            }

            if (this.props.type === "life") {
                saveLife(
                    this.props.user.uid,
                    this.props.category,
                    this.props.question.id,
                    this.state.option,
                    this.state.ownAnswer,
                    this.state.ignore,
                    this.state.comment,
                    this.state.items
                )
                    .then(() => {
                        this.setState({ loading: false })
                        this.props.toggleEditMode()
                    })
                    .catch((error) => {
                        this.setState({ loading: false })
                        console.error("Error saving life: ", error)
                    })
            }
        }
    }

    render() {
        const { loading, option, ownAnswer, items, newItem, newOption, ignore, comment } = this.state
        const { question, toggleEditMode, undertaker } = this.props
        const { noAdd, isSingle, isBinary, isAux, placeholder, commentStr, ignoreStr, isUndertaker } = question

        // Is this a options type of answer?
        let isOptionsAnswer = false
        if (question.options) {
            // Is options
            isOptionsAnswer = true
        }

        // Is this a items type of answer?
        let isItemsAnswer = false
        let displayNewItem = false
        if (!question.options && !noAdd && !isUndertaker) {
            // No predefined options, must be items
            isItemsAnswer = true
            displayNewItem = true
            if (isSingle && items && items.length > 0) {
                displayNewItem = false
            }
        }

        // Can the user ignore this question instead of answering?
        let userCanIgnoreQuestion = true
        if (isBinary || isAux) {
            userCanIgnoreQuestion = false
        }

        return (
            <div className="Form__answer padding-xy">
                <Form
                    onSubmit={this.handleSubmit}
                    submitBtnTxt="Gem ændringer"
                    loading={loading}
                    onCancel={toggleEditMode}
                >
                    {isOptionsAnswer && (
                        <>
                            {question.options.map((theoption) => (
                                <FormInputOption
                                    key={theoption.id}
                                    optionId={theoption.id}
                                    title={theoption.title}
                                    selectedOption={option}
                                    onChange={this.handleChange}
                                />
                            ))}
                            {!noAdd && !isBinary && (
                                <>
                                    {ownAnswer && Object.keys(ownAnswer).length ? (
                                        <FormUserInputOption
                                            onChange={this.handleChangeOwnAnswer}
                                            onToggle={this.toggleOwnAnswer}
                                            ownAnswer={ownAnswer}
                                            onDelete={this.handleDeleteOwnAnswer}
                                        />
                                    ) : (
                                        <FormUserInputNewOption
                                            onChange={this.handleChange}
                                            onSave={this.addNewOption}
                                            title={newOption}
                                        />
                                    )}
                                </>
                            )}
                        </>
                    )}

                    {isItemsAnswer && (
                        <>
                            {items.map((item, i) => (
                                <FormUserInputItem
                                    key={i}
                                    index={i}
                                    title={item}
                                    onChange={this.handleChangeItem}
                                    onDelete={this.handleDeleteItem}
                                />
                            ))}
                            {displayNewItem && (
                                <FormUserInputNewItem
                                    onChange={this.handleChange}
                                    onSave={this.addNewItem}
                                    title={newItem}
                                    addTxt={placeholder}
                                />
                            )}
                        </>
                    )}

                    {isUndertaker && (
                        <>
                            {(!undertaker && (
                                <button
                                    className="Button Button--icon"
                                    type="button"
                                    style={{ width: "100%", marginBottom: 18 }}
                                    onClick={() => this.setUndertakerModalOpen(true)}
                                >
                                    <span>Find bedemand</span>
                                    <SearchRounded />
                                </button>
                            )) || (
                                <UndertakerPreview
                                    undertaker={undertaker}
                                    onSelect={() => this.setUndertakerModalOpen(true)}
                                />
                            )}
                            <Dialog
                                open={this.state.undertakerModalOpen}
                                onClose={() => this.setUndertakerModalOpen(false)}
                            >
                                {(undertaker && (
                                    <UndertakerDetail
                                        undertaker={undertaker}
                                        isSelected={true}
                                        onSelect={this.handleDeleteUndertaker}
                                        onBack={() => this.setUndertakerModalOpen(false)}
                                    />
                                )) || (
                                    <UndertakerSearch
                                        onSelect={this.handleSelectUndertaker}
                                        onClose={() => this.setUndertakerModalOpen(false)}
                                    />
                                )}
                            </Dialog>
                        </>
                    )}

                    {userCanIgnoreQuestion && !undertaker && (
                        <FormInputCheckbox
                            name="ignore"
                            isChecked={ignore}
                            onChange={this.handleCheckboxChange}
                            label={ignoreStr || "Det er ikke vigtig for mig"}
                        />
                    )}

                    <span>Noter:</span>
                    <FormInputTextarea
                        name="comment"
                        value={comment}
                        onChange={this.handleChange}
                        placeholder={
                            noAdd && !isOptionsAnswer && !isItemsAnswer ? commentStr : "Tilføj en kommentar..."
                        }
                    />
                </Form>
            </div>
        )
    }
}

export default WithUser(FormAnswer)

FormAnswer.propTypes = {
    user: PropTypes.any.isRequired,
    question: PropTypes.any.isRequired,
    category: PropTypes.string.isRequired,
    topic: PropTypes.string.isRequired,
    toggleEditMode: PropTypes.func.isRequired,
    items: PropTypes.array,
    ignored: PropTypes.bool.isRequired,
    comment: PropTypes.string,
    option: PropTypes.string,
    ownAnswer: PropTypes.object,
    type: PropTypes.string.isRequired,
    undertaker: PropTypes.object,
}
