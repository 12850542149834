import { BASE_URL, REQUEST_INIT, MODEL_LEGAL, MODEL_ALL_LEGALS } from "./datocms"

export const getLegalEntries = async (): Promise<ILegalEntry[]> => {
    const query = `{ ${MODEL_ALL_LEGALS}(filter: {active: {eq: "true"}}) { name heading intro sortOrder colorIndex } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            const legalEntries = res.data?.[MODEL_ALL_LEGALS] as ILegalEntry[]
            return (
                legalEntries?.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0)) || []
            )
        })
        .catch((error) => {
            console.log(error)
            return []
        })
}

export const getLegal = async (name: string): Promise<ILegal | undefined> => {
    const query = `{ ${MODEL_LEGAL}(filter: {active: {eq: "true"}, name: {eq: "${name}"}}) { name heading subheading body ctaText ctaButtonText neutrum } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            return res.data[MODEL_LEGAL] as ILegal
        })
        .catch((error) => {
            console.log(error)
            return undefined
        })
}

export const getLegalImageUrl = async (name: string): Promise<string | undefined> => {
    const query = `{ ${MODEL_LEGAL}(filter: {active: {eq: "true"}, name: {eq: "${name}"}}) { image { url } } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            return res.data[MODEL_LEGAL]["image"]["url"] as string
        })
        .catch((error) => {
            console.log(error)
            return undefined
        })
}
