import { Component } from "react"

import icons from "../../assets/icons.svg"
import classNames from "classnames"
const BurgerIcon = icons + "#burger"

class Burger extends Component {
    sidebar = null
    header = null

    state = {
        sidebarOpen: false,
    }

    componentDidMount() {
        this.sidebar = document.getElementById("Sidebar")
        this.header = document.getElementById("Header")
    }

    toggleSidebar() {
        this.sidebar.classList.toggle("Sidebar--open")
        this.header.classList.toggle("Sidebar--open")
    }

    render() {
        const isPhone = process.env.REACT_APP_IS_PHONE
        const burgerClasses = classNames("Header__burger", {
            "Header__burger--phone": isPhone,
        })

        return (
            <button className={burgerClasses} onClick={() => this.toggleSidebar()}>
                <svg>
                    <use xlinkHref={BurgerIcon}></use>
                </svg>
                <div className="Header__scrim"></div>
            </button>
        )
    }
}

export default Burger
