import { useContext } from "react"
import { useRouteMatch } from "react-router-dom"

import HeirloomPreview from "./HeirloomPreview"
import NewHeirloom from "./NewHeirloom"
import Loading from "../Loader/InlineLoader"
import { HeirloomsContext } from "../../providers/HeirloomsProvider"

const HeirloomsList = () => {
    const match = useRouteMatch()
    const heirloomsData = useContext(HeirloomsContext)
    const heirlooms: IHeirloom[] = heirloomsData.heirlooms
    const loading = heirloomsData.loading

    if (loading) {
        return <Loading />
    }

    return (
        <>
            <p style={{ marginTop: 6, fontWeight: 600, textAlign: "center", lineHeight: 1.25 }}>
                <em>NB: Husk at det du noterer er ønsker og ikke juridisk bindende.</em>
            </p>
            <ul className="list-unstyled grid-x grid-padding-x--small">
                {heirlooms.map((heirloom) => (
                    <HeirloomPreview key={heirloom.id} heirloom={heirloom} url={`${match.url}/${heirloom.id}`} />
                ))}
                <NewHeirloom />
            </ul>
        </>
    )
}

export default HeirloomsList
