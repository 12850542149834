import { ChangeEvent, FormEvent, useState } from "react"
import { Link } from "react-router-dom"
import { Dialog, TextField } from "@mui/material"

import { auth } from "../../../firebase"
import Logo from "../../Logo/Logo"
import { Error } from "./model"
import { resolveError } from "./functions"

const PageForgotPassword = () => {
    const [loading, setLoading] = useState(false)
    const [success, setSuccess] = useState(false)
    const [email, setEmail] = useState("")
    const [error, setError] = useState<Error | undefined>()
    const [modalOpen, setModalOpen] = useState(false)

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => {
        setEmail(event.target.value)
        setSuccess(false)
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()
        if (loading) return

        if (success) {
            setModalOpen(true)
            return
        }

        setLoading(true)
        setError(undefined)

        try {
            await auth.sendPasswordResetEmail(email)
            setSuccess(true)
        } catch ({ code }) {
            setError(resolveError(code as string))
        } finally {
            setLoading(false)
            setModalOpen(true)
        }
    }

    return (
        <>
            <div className="Login bg-lightblue">
                <Logo />
                <div className="Login__form rounded-borders bg-white">
                    <h1 className="Login__title">Glemt adgangskode?</h1>
                    <p>
                        Indtast den e-mail du har oprettet dig med, så sender vi en mail med instrukser for nulstilling
                        af din adgangskode.
                    </p>
                    <form className="la-form" onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            required
                            value={email}
                            autoComplete="email"
                            name="email"
                            id="email"
                            onChange={handleEmailChange}
                            label="E-mail"
                            type="email"
                            margin="normal"
                            fullWidth
                        />
                        <input
                            type="submit"
                            className="Button"
                            value={loading ? "Vent..." : "Nulstil adgangskode"}
                            disabled={loading}
                            style={{ cursor: loading ? "wait" : "pointer" }}
                        />
                    </form>
                    <div>
                        <p className="SignUp__alternate">
                            <Link to="/login">Log ind her</Link>
                        </p>
                    </div>
                </div>
            </div>
            <Dialog
                className="la-dialog"
                open={modalOpen}
                onClose={() => {
                    setError(undefined)
                    setModalOpen(false)
                }}
            >
                <div className="la-modal">
                    <h2>{success ? "E-mail sendt" : error?.title}</h2>
                    <p>
                        {success
                            ? `Vi har sendt en e-mail til ${email}.\nKlik på linket i e-mailen for at nulstille din adgangskode.`
                            : error?.txt}
                    </p>
                    <div className="la-modal__actions">
                        <button
                            className="Button"
                            autoFocus={true}
                            aria-labelledby="close-modal"
                            onClick={() => setModalOpen(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PageForgotPassword
