import { CategoriesLifeContext } from "../../providers/CategoriesLifeProvider"

// This is to give the actual component a name when debugging
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component"
}

const WithCategories = (Component) => {
    const WrappedComponent = (props) => {
        return (
            <CategoriesLifeContext.Consumer>
                {(categoriesLife) => <Component categoriesLife={categoriesLife} {...props} />}
            </CategoriesLifeContext.Consumer>
        )
    }
    WrappedComponent.displayName = `WithCategoriesLife(${getDisplayName(WrappedComponent)})`
    return WrappedComponent
}

export default WithCategories
