import { useState } from "react"
import { DeleteRounded, EditRounded } from "@mui/icons-material"
import PropTypes from "prop-types"

import icons from "../../assets/icons.svg"

const checkmarkIcon = icons + "#checkmark"
const circleIcon = icons + "#circle"

const FormUserInputOption = (props) => {
    const { ownAnswer, onChange, onToggle, onDelete } = props
    const [editMode, setEditMode] = useState(false)

    const indicatorIcon = ownAnswer.isChecked ? checkmarkIcon : circleIcon

    const toggleEditMode = (e, state) => {
        e.preventDefault()
        setEditMode(state)
    }

    return (
        <div className="Form__inputwrapper">
            {editMode ? (
                <div className="Form__userinput">
                    <div>
                        <input type="text" value={ownAnswer.txt} onChange={onChange} autoFocus />
                        <div className="Form__userinput__actions">
                            <button className="Button Button--icon save" onClick={(e) => toggleEditMode(e, false)}>
                                <span>OK</span>
                                <svg>
                                    <use xlinkHref={checkmarkIcon}></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="Form__checkbox">
                    <input
                        id="OwnAnswer"
                        className="hidden"
                        type="checkbox"
                        checked={ownAnswer.isChecked === true}
                        onChange={onToggle}
                    />
                    <label htmlFor="OwnAnswer">
                        <span>{ownAnswer.txt}</span>
                        <svg>
                            <use xlinkHref={indicatorIcon}></use>
                        </svg>
                    </label>
                    <div className="Form__userinput__actions Form__userinput__actions--option">
                        <button className="Button Button--icon" onClick={(e) => toggleEditMode(e, true)}>
                            <EditRounded />
                        </button>
                        <button className="Button Button--icon delete" onClick={onDelete}>
                            <DeleteRounded />
                        </button>
                    </div>
                </div>
            )}
        </div>
    )
}

export default FormUserInputOption

FormUserInputOption.propTypes = {
    ownAnswer: PropTypes.object.isRequired,
    onChange: PropTypes.func.isRequired,
    onToggle: PropTypes.func.isRequired,
    onDelete: PropTypes.func.isRequired,
}
