import { useEffect, useState } from "react"
import { getHeirloom } from "../../api/HeirloomManager"

export const useHeirloom = (userID: string, heirloomID?: string): IHeirloom | undefined => {
    const [heirloom, setHeirloom] = useState<IHeirloom>()

    useEffect(() => {
        if (!heirloomID) return
        getHeirloom(userID, heirloomID).then(setHeirloom)
    }, [userID, heirloomID])

    return heirloom
}
