import { useRouteMatch } from "react-router-dom"

import Module from "../Module/Module"

interface Props {
    preview: ICharityPreview
    donation?: ICharityDonation
}

const CharityPreview = ({ preview, donation }: Props) => {
    const match = useRouteMatch()

    return (
        <div className="cell brand-preview-wrapper">
            <Module color="white" url={`${match.url}/${preview.name}`}>
                <div className="brand-preview">
                    <div className="brand-preview__copy">
                        <div className="brand-preview__copy__txt">{preview.displayName}</div>
                        {donation && <div className="brand-preview__copy__chip">{donation.amount} DKK</div>}
                    </div>
                    <img className="brand-preview__img" src={preview.logo.url} alt={preview.displayName} />
                </div>
            </Module>
        </div>
    )
}

export default CharityPreview
