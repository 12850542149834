import { useEffect, useState } from "react"
import { CloseRounded } from "@mui/icons-material"

import { cloudfunctions } from "../../firebase"
import { getGuide } from "../../api/GuideManager"

interface Props {
    open: boolean
    name: string
    onModalClose: () => void
}

const Guide = ({ open, name, onModalClose }: Props) => {
    const [guide, setGuide] = useState<IGuide>()

    useEffect(() => {
        if (!open) return
        if (name === guide?.name) return
        getGuide(name).then((guide) => {
            if (guide) {
                setGuide(guide)
            } else {
                cloudfunctions.httpsCallable("sendMissingGuideEmail")({ guideName: name })
                onModalClose()
            }
        })
    }, [open, guide, name, onModalClose])

    return (
        <div className="guide-wrapper">
            <div className="guide-wrapper__nav">
                <button className="la-fab" onClick={onModalClose}>
                    <CloseRounded />
                </button>
            </div>
            <div className="guide">{guide && <div dangerouslySetInnerHTML={{ __html: guide.content }} />}</div>
        </div>
    )
}

export default Guide
