import { Component } from "react"
import { getImportantQuestions } from "../../api/ImportantQuestionsManager"

const withImportantQuestions = (WrappedComponent) =>
    class extends Component {
        state = {
            importantQuestions: undefined,
        }

        componentDidMount() {
            getImportantQuestions().then((iqs) => {
                this.setState({
                    importantQuestions: iqs,
                })
            })
        }

        render() {
            return <WrappedComponent importantQuestions={this.state.importantQuestions} />
        }
    }

export default withImportantQuestions
