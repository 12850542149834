import { ChangeEvent, FormEvent, useState } from "react"
import { Link, Redirect } from "react-router-dom"
import { Dialog, FormControl, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@mui/material"
import { Visibility, VisibilityOff } from "@mui/icons-material"

import { auth } from "../../../firebase"
import { logCurrentEnv } from "../../../api/environmentManager"
import Logo from "../../Logo/Logo"

import { Error } from "./model"
import { resolveError } from "./functions"

interface Props {
    loggedIn: boolean
}

const isPhone = process.env.REACT_APP_IS_PHONE

const PageLogin = ({ loggedIn }: Props) => {
    const [loading, setLoading] = useState(false)
    const [email, setEmail] = useState("")
    const [password, setPassword] = useState("")
    const [passwordVisible, setPasswordVisible] = useState(false)
    const [error, setError] = useState<Error | undefined>()
    const [modalOpen, setModalOpen] = useState(false)

    const handleEmailChange = (event: ChangeEvent<HTMLInputElement>) => setEmail(event.target.value)

    const handlePasswordChange = (event: ChangeEvent<HTMLInputElement>) => setPassword(event.target.value)

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()

        if (loading) return

        setLoading(true)
        setError(undefined)

        try {
            const userCredential = await auth.signInWithEmailAndPassword(email, password)
            window.scrollTo(0, 0)
            return await logCurrentEnv(userCredential.user!.uid)
        } catch ({ code }) {
            setLoading(false)
            setError(resolveError(code as string))
            setModalOpen(true)
        }
    }

    if (loggedIn) {
        return <Redirect to={"/"} />
    }

    return (
        <>
            <div className="Login bg-lightblue">
                <Logo />
                <div className={`Login__form${isPhone ? " Login__form--phone" : ""} rounded-borders bg-white`}>
                    <h1 className="Login__title">Log ind</h1>
                    <form onSubmit={handleSubmit}>
                        <TextField
                            variant="outlined"
                            required
                            value={email}
                            autoComplete="email"
                            name="email"
                            id="email"
                            onChange={handleEmailChange}
                            label="E-mail"
                            type="email"
                            margin="normal"
                            fullWidth
                        />
                        <FormControl variant="outlined" margin="normal" fullWidth required>
                            <InputLabel htmlFor="current-password">Adgangskode</InputLabel>
                            <OutlinedInput
                                type={passwordVisible ? "text" : "password"}
                                value={password}
                                autoComplete="current-password"
                                name="current-password"
                                id="current-password"
                                label="Adgangskode"
                                onChange={handlePasswordChange}
                                endAdornment={
                                    <InputAdornment position="end">
                                        <IconButton onClick={() => setPasswordVisible(!passwordVisible)} edge="end">
                                            {passwordVisible ? <VisibilityOff /> : <Visibility />}
                                        </IconButton>
                                    </InputAdornment>
                                }
                            />
                        </FormControl>
                        <input
                            type="submit"
                            className="Button"
                            value={loading ? "Vent..." : "Log ind"}
                            disabled={loading}
                            style={{ cursor: loading ? "wait" : "pointer" }}
                        />
                    </form>
                    <div>
                        <p className="SignUp__alternate">
                            Har du ikke en profil? <Link to="/signup">Opret bruger</Link>
                        </p>
                        <p className="SignUp__alternate">
                            Glemt din adgangskode? <Link to="/glemt-adgangskode">Få en ny</Link>
                        </p>
                    </div>
                </div>
                {!isPhone && (
                    <section className="stores">
                        <a
                            className="store store--apple"
                            href="https://apps.apple.com/dk/app/adj%C3%B8/id1510045295?mt=8"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {}
                        </a>
                        <a
                            className="store store--google"
                            href="https://play.google.com/store/apps/details?id=app.web.adjo_563ec_phone.twa"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {}
                        </a>
                    </section>
                )}
                <div className="copyright-footer">© 2022 Life Apps ApS</div>
            </div>
            <Dialog
                className="la-dialog"
                open={modalOpen}
                onClose={() => {
                    setError(undefined)
                    setModalOpen(false)
                }}
            >
                <div className="la-modal">
                    <h2>{error?.title}</h2>
                    <p>{error?.txt}</p>
                    <div className="la-modal__actions">
                        <button
                            className="Button"
                            autoFocus={true}
                            aria-labelledby="close-modal"
                            onClick={() => setModalOpen(false)}
                        >
                            OK
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default PageLogin
