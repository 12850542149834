import { ChangeEvent, MouseEvent, useState } from "react"
import { TextField } from "@mui/material"
import { CloseRounded } from "@mui/icons-material"

import { getAllUndertakers } from "../../firebase"
import UndertakerPreview from "./UndertakerPreview"
import UndertakerDetail from "./UndertakerDetail"

interface Props {
    onSelect?: (event: MouseEvent, undertaker: any) => void
    onClose: () => void
}

const UndertakerSearch = ({ onSelect, onClose }: Props) => {
    const [str, setStr] = useState("")
    const [undertakers, setUndertakers] = useState<any[]>()
    const [undertaker, setUndertaker] = useState<any>()

    const handleStrChange = (event: ChangeEvent<HTMLInputElement>) => {
        setStr(event.target.value)
    }

    const performSearch = async (str?: string) => {
        if (str !== undefined && str.trim().length === 0) {
            return
        }

        const querySnapshot = await getAllUndertakers()

        let undertakers: any[] = []
        querySnapshot.forEach((doc) => {
            undertakers.push(doc.data())
        })

        if (str && str.trim().length > 0) {
            undertakers = performFiltering(undertakers, str.trim())
        }

        undertakers.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))

        setUndertakers(undertakers)
    }

    const performFiltering = (undertakers: any[], str: string) => {
        str = str.toLowerCase()
        const intValue = parseInt(str, 10)
        if (isNaN(intValue)) {
            return undertakers.filter(
                (undertaker) =>
                    undertaker["name"].toLowerCase().includes(str) ||
                    (undertaker["dept"]?.toLowerCase().includes(str) ?? false) ||
                    (undertaker["address"]?.toLowerCase().includes(str) ?? false)
            )
        } else {
            return undertakers.filter((undertaker) => undertaker["postcodes"].includes(str))
        }
    }

    const handleSelectUndertaker = (undertaker: any) => {
        setUndertaker(undertaker)
    }

    const handleBackClicked = () => {
        setUndertaker(undefined)
    }

    const handleSelect = (event: MouseEvent, undertaker: any) => {
        onSelect?.(event, undertaker)
        onClose()
    }

    if (undertaker) {
        return (
            <UndertakerDetail
                undertaker={undertaker}
                isSelected={false}
                onSelect={onSelect ? handleSelect : undefined}
                onBack={handleBackClicked}
            />
        )
    }

    return (
        <div className="la-modal undertaker-search">
            <div className="undertaker-search__title">
                <h4>Find en bedemand</h4>
                <button className="la-fab" onClick={onClose}>
                    <CloseRounded />
                </button>
            </div>
            <div className="la-form" style={{ height: "unset" }}>
                <div className="la-form__field">
                    <TextField
                        variant="outlined"
                        value={str}
                        onChange={handleStrChange}
                        label="Postnummer, by eller navn"
                        name="str"
                        type="text"
                    />
                </div>
                <div className="la-modal__actions" style={{ marginTop: 0, marginBottom: 30 }}>
                    <button
                        type="button"
                        className="Button Button--flat undertaker-search__show-all-btn"
                        onClick={() => performSearch()}
                    >
                        Vis alle
                    </button>
                    <button type="button" className="Button" onClick={() => performSearch(str)}>
                        Søg
                    </button>
                </div>
            </div>
            {undertakers && undertakers.length === 0 && (
                <p className="undertaker-search__empty">Ingen bedemænd fundet...</p>
            )}
            {undertakers && undertakers.length > 0 && (
                <ul className="list-unstyled">
                    {undertakers.map((undertaker) => (
                        <UndertakerPreview
                            key={undertaker.id}
                            undertaker={undertaker}
                            onSelect={handleSelectUndertaker}
                        />
                    ))}
                </ul>
            )}
        </div>
    )
}

export default UndertakerSearch
