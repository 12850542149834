import { ChangeEvent, useEffect, useState } from "react"
import { useHistory } from "react-router-dom"
import { TextField } from "@mui/material"
import { useSnackbar } from "notistack"

import useCharityDonation from "../hooks/Charity/useCharityDonation"
import { deleteCharityDonation, setCharityDonation } from "../../api/CharityDonationManager"
import { CHARITIES } from "../../routes/paths"
import WithUser from "../hoc/WithUser"

interface Props {
    user: any
    charityName: string
    charityDisplayName: string
}

const CharityDonation = ({ charityName, charityDisplayName, user }: Props) => {
    const history = useHistory()
    const charityDonation = useCharityDonation(charityName, user.uid)
    const [amount, setAmount] = useState("")
    const { enqueueSnackbar } = useSnackbar()

    useEffect(() => {
        if (!charityDonation) return
        setAmount(String(charityDonation.amount))
    }, [charityDonation])

    const handleValueChange = (event: ChangeEvent<HTMLInputElement>) => {
        const strValue = event.target.value
        if (strValue === "") {
            setAmount(strValue)
        }

        const intValue = parseInt(strValue, 10)
        if (!isNaN(intValue) && intValue > -1) {
            setAmount(String(intValue))
        }
    }

    const handleSubmit = async () => {
        const amountNumber = parseInt(amount, 10)

        if (amountNumber > 0) {
            await setCharityDonation(user.uid, charityName, amountNumber)
            enqueueSnackbar("Donationen er bekræftet")
        } else {
            await deleteCharityDonation(user.uid, charityName)
            enqueueSnackbar("Donationen er slettet")
            history.replace(CHARITIES)
        }
    }

    return (
        <>
            <div className="charity-donation padding-xy margin-bottom rounded-borders">
                <h2>Donation</h2>
                <p>Her kan du angive det beløb du ønsker at donere til {charityDisplayName}.</p>
                <div className="la-form">
                    <div className="la-form__field">
                        <TextField
                            variant="outlined"
                            value={amount}
                            label="Beløb (DKK)"
                            onChange={handleValueChange}
                            name="value"
                            type="number"
                        />
                    </div>
                    <div className="la-form__field">
                        <button className="Button" onClick={handleSubmit}>
                            Bekræft beløb
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}

export default WithUser(CharityDonation)
