const VerificationEmailSent = ({ handleClick }) => (
    <>
        <h3>Godkend din e-mail</h3>
        <p>Vi har sendt en e-mail til dig. Klik på linket i mailen for at godkende din e-mail.</p>
        <div className="info">
            <p>Info:</p>
            <p>
                Hvis du ikke har modtaget mailen inden for et par minutter, så prøv at se om den er havnet i din spam
                mappe.
            </p>
        </div>
        <div className="la-modal__actions">
            <button
                className="Button"
                style={{ marginLeft: "auto" }}
                aria-labelledby="close-modal"
                onClick={handleClick}
            >
                OK
            </button>
        </div>
    </>
)

export default VerificationEmailSent
