import { useLocation, useParams } from "react-router-dom"

import { slugify } from "../../util"
import TopicPage from "./TopicPage"
import { PUBLIC_BUCKET } from "../../routes/paths"
import WithCategories from "../hoc/WithCategories"

interface Params {
    parentTopicParam: string
}

interface Props {
    categories: any[]
}

const ParentTopic = ({ categories }: Props) => {
    const { parentTopicParam } = useParams<Params>()
    const location = useLocation()

    let color = "white"
    if (location.pathname.includes("mit-farvel")) {
        color = "yellow"
    }

    const parentTopic = categories.find((category) => slugify(category.title) === parentTopicParam)

    return (
        <>
            {parentTopic ? (
                <TopicPage
                    title={parentTopic.title}
                    text={parentTopic.desc}
                    illustration={`${PUBLIC_BUCKET}/scribbles/svg/scribble_${parentTopic.img}.svg`}
                    illustrationPad
                    color={color}
                    topics={parentTopic.topics}
                    parentTopic={parentTopic}
                />
            ) : (
                "Siden kunne ikke findes..."
            )}
        </>
    )
}

export default WithCategories(ParentTopic)
