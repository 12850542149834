import { ArrowForwardRounded } from "@mui/icons-material"

interface Props {
    title: string
    text: string
    btnText: string
    illustration: string
}

const HomeCard = ({ title, text, btnText, illustration }: Props) => (
    <div className="home-card">
        <div className="home-card__content">
            <h2>{title}</h2>
            <p>{text}</p>
            <button className="Button Button--outline Button--icon">
                <span>{btnText}</span>
                <ArrowForwardRounded style={{ fontSize: 30 }} />
            </button>
        </div>
        <img className="home-card__img" src={illustration} alt="Reading" />
    </div>
)

export default HomeCard
