import { useParams } from "react-router-dom"
import classNames from "classnames"

import icons from "../../assets/icons.svg"
import { setRadiosAnswer } from "../../api/Q11eStateManager"
import { auth } from "../../firebase"

interface Props {
    answer: IQ11eRadiosAnswer
    response?: IQ11eRouteStateRadiosAnswer
    userLabels?: { [label: string]: string }
}

interface Params {
    q11eName: string
    q11eVersion: string
    questionId: string
}

const selected = (answerLabel: string, response?: IQ11eRouteStateRadiosAnswer) => answerLabel === response?.label

const Q11eAnswerRadios = ({ answer, response, userLabels }: Props) => {
    const { q11eName, q11eVersion, questionId } = useParams<Params>()

    const radioOff = icons + "#radio-off"
    const radioOn = icons + "#radio-on"

    const handleClick = (label: string) => () =>
        setRadiosAnswer(auth.currentUser!.uid, q11eName, q11eVersion, questionId, answer, label)

    const classes = classNames("questionnaire-answer", {
        "questionnaire-answer--selected": false,
    })

    return (
        <>
            {answer.labels.map((answerLabel) =>
                userLabels?.[answerLabel] ? (
                    <div key={answerLabel} className={classes} onClick={handleClick(answerLabel)}>
                        <label>{userLabels[answerLabel]}</label>
                        <svg>
                            <use xlinkHref={selected(answerLabel, response) ? radioOn : radioOff} />
                        </svg>
                    </div>
                ) : null
            )}
        </>
    )
}

export default Q11eAnswerRadios
