import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useOtherCharities = (userId?: string): ICharityOther[] => {
    const [charities, setCharities] = useState<ICharityOther[]>([])

    useEffect(() => {
        if (!userId) return

        const subscribe = () => {
            firestore
                .collection("users")
                .doc(userId)
                .collection("charityOtherDonations")
                .onSnapshot((querySnap) => {
                    const charities: ICharityOther[] = []
                    querySnap.forEach((doc) => {
                        charities.push(doc.data() as ICharityOther)
                    })
                    setCharities(charities)
                })
        }

        return subscribe()
    }, [userId])

    return charities
}

export default useOtherCharities
