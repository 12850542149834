import { useEffect, useState } from "react"
import { Switch, Route, NavLink, useLocation } from "react-router-dom"

import * as PATHS from "../../routes/paths"
import QuestionnaireList from "../questionnaire/QuestionnaireList"
import HeirloomsList from "../Heirlooms/HeirloomsList"
import DigitalInheritance from "../DigitalInheritance/DigitalInheritance"

const intros = {
    [PATHS.TESTAMENT]:
        "Med et testamente har du mulighed for at tage stilling til hvordan arven efter dig fordeles. Du kan dermed sikre, at din formue fordeles som du ønsker.",
    [PATHS.HEIRLOOMS]:
        "Hvem skal arve dine ting - det gælder både de konkrete værdier, men også personlige genstande som tegninger, arvestykker m.m.",
    [PATHS.DIGITAL_INHERITANCE]:
        "Dit digitale liv lever videre også efter din død. Det er derfor vigtigt, at andre kan få adgang til din profil og til information på nettet.",
}

const PageInheritance = () => {
    const location = useLocation()
    const [intro, setIntro] = useState(intros[PATHS.TESTAMENT])

    useEffect(() => {
        setIntro(intros[location.pathname])
    }, [location])

    return (
        <>
            <div className="Intro rounded-borders padding-xy margin-bottom bg-green">
                <div className="Intro__content">
                    <h1>Min arv</h1>
                    <nav className="tabs">
                        <NavLink className="tab" activeClassName="tab--active" to={PATHS.TESTAMENT}>
                            Testamente
                        </NavLink>
                        <NavLink className="tab" activeClassName="tab--active" to={PATHS.HEIRLOOMS}>
                            Mine ting
                        </NavLink>
                        <NavLink className="tab" activeClassName="tab--active" to={PATHS.DIGITAL_INHERITANCE}>
                            Digital arv
                        </NavLink>
                    </nav>
                    <p>{intro}</p>
                </div>
                <img
                    className="Intro__illustration"
                    src={`${PATHS.PUBLIC_BUCKET}/illustrations/min_arv.png`}
                    alt="Min arv"
                />
            </div>

            <Switch>
                <Route path={PATHS.TESTAMENT}>
                    <QuestionnaireList />
                </Route>
                <Route path={PATHS.HEIRLOOMS}>
                    <HeirloomsList />
                </Route>
                <Route path={PATHS.DIGITAL_INHERITANCE}>
                    <DigitalInheritance />
                </Route>
            </Switch>
        </>
    )
}

export default PageInheritance
