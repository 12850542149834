import { useState, useEffect } from "react"
import { Dialog } from "@mui/material"

import WithUser from "../hoc/WithUser"
import LogOutModal from "./LogOutModal"
import ChangePasswordModal from "./ChangePasswordModal"
import UpdateEmailModal from "./UpdateEmailModal"
import DeleteUserModal from "./DeleteUserModal"

const ProfileActions = ({ user }) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [action, setAction] = useState(undefined)

    useEffect(() => {
        if (!modalOpen) {
            setAction(undefined)
        }
    }, [modalOpen])

    useEffect(() => {
        if (action !== undefined) {
            setModalOpen(true)
        } else {
            setModalOpen(false)
        }
    }, [action])

    const isPhone = process.env.REACT_APP_IS_PHONE

    const onUpdateEmailClicked = () => {
        setAction("UPDATE_EMAIL")
    }

    const onChangePasswordClicked = () => {
        setAction("CHANGE_PASSWORD")
    }

    const onLogOutClicked = () => {
        setAction("LOG_OUT")
    }

    const onDeleteUserClicked = () => {
        setAction("DELETE_USER")
    }

    const handleCancel = () => {
        setModalOpen(false)
    }

    return (
        <>
            <div className={`profile__form${isPhone ? " profile__form--phone" : ""}`}>
                <button className="Button card-spacing" onClick={onUpdateEmailClicked}>
                    Skift e-mail
                </button>
                <button className="Button card-spacing" onClick={onChangePasswordClicked}>
                    Nulstil adgangskode
                </button>
                <button className="Button card-spacing" onClick={onLogOutClicked}>
                    Log ud
                </button>
                <button className="Button Button--red card-spacing" onClick={onDeleteUserClicked}>
                    Slet profil
                </button>
            </div>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                {(action === "UPDATE_EMAIL" && <UpdateEmailModal user={user} onCancel={handleCancel} />) ||
                    (action === "CHANGE_PASSWORD" && <ChangePasswordModal user={user} onCancel={handleCancel} />) ||
                    (action === "LOG_OUT" && <LogOutModal onCancel={handleCancel} />) ||
                    (action === "DELETE_USER" && <DeleteUserModal user={user} onCancel={handleCancel} />)}
            </Dialog>
        </>
    )
}

export default WithUser(ProfileActions)
