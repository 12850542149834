import { useEffect, useState } from "react"

import { getCollabs } from "../../api/CollabsManager"

const useCollabs = (): ICollab[] => {
    const [collabs, setCollabs] = useState<ICollab[]>([])

    useEffect(() => {
        getCollabs().then(setCollabs)
    }, [])

    return collabs
}

export default useCollabs
