import { useState } from "react"
import { Link, useHistory, useParams } from "react-router-dom"
import { ArrowBackRounded, EditRounded, LibraryAdd } from "@mui/icons-material"
import { useSnackbar } from "notistack"
import classNames from "classnames"

import { cloudfunctions } from "../../firebase"
import useQ11eRouteState from "../hooks/Q11e/useQ11eRouteState"
import useQ11eQuestionDisplays from "../hooks/Q11e/useQ11eQuestionDisplays"
import * as PATHS from "../../routes/paths"
import { TESTAMENT } from "../../routes/paths"
import Intro from "../Intro/Intro"
import useQ11ePreview from "../hooks/Q11e/useQ11ePreview"
import useQ11eStorefront from "../hooks/Q11e/useQ11eStorefront"
import InlineLoader from "../Loader/InlineLoader"
import WithUser from "../hoc/WithUser"
import useDocument from "../hooks/Q11e/useDocument"
import FremtidsfuldmagtSteps from "./FremtidsfuldmagtSteps"
import BornetestamenteSteps from "./BornetestamenteSteps"
import GiftcardSteps from "./GiftcardSteps"
import downloadFile from "../print/downloadHelper"
import Prompt from "../dialogs/Prompt"
import { BORNETESTAMENTE_VERSION, FREMTIDSFULDMAGT_VERSION } from "../hooks/Q11e/constants"

interface Props {
    user: { uid: string }
}

interface Params {
    documentName: string
    documentVersion: string
}

const PageDocument = ({ user }: Props) => {
    const history = useHistory()
    const { documentName, documentVersion } = useParams<Params>()
    const q11ePreview = useQ11ePreview(documentName, documentVersion)
    const document = useDocument(user.uid, documentName)
    const q11eStorefront = useQ11eStorefront(documentName, documentVersion)
    const q11eRouteState = useQ11eRouteState(documentName, documentVersion, user.uid)
    const questionDisplays = useQ11eQuestionDisplays(documentName, documentVersion, q11eRouteState)
    const { enqueueSnackbar } = useSnackbar()

    const [isAwaitingFile, setAwaitingFile] = useState(false)
    const [isAwaitingObsoleteMarking, setAwaitingObsoleteMarking] = useState(false)
    const [editPromptOpen, setEditPromptOpen] = useState(false)

    const resolveDocumentName = (name: string): string => {
        let version = "1";
        if (name === "Fremtidsfuldmagt") {
            name = "fremtidsfuldmagt"
            version = FREMTIDSFULDMAGT_VERSION.toString()
        } else {
            name = "bornetestamente"
            version = BORNETESTAMENTE_VERSION.toString()
        }

        return name + "_" + version
    }


    const resolveAnswerDisplay = (
        answer:
            | IQ11eRouteStateRadioAnswer
            | IQ11eRouteStateRadiosAnswer
            | IQ11eRouteStateTextAnswer
            | IQ11eRouteStateMultiTextAnswer
            | IQ11eRouteStateDistributionAnswer
    ): string => {
        if (answer.hasOwnProperty("radioAnswerId")) {
            return (answer as IQ11eRouteStateRadioAnswer).radioAnswerTxt
        } else if (answer.hasOwnProperty("radiosAnswerId")) {
            return "" // TODO
        } else if (answer.hasOwnProperty("textAnswer")) {
            return (answer as IQ11eRouteStateTextAnswer).textAnswer
        } else {
            return (answer as IQ11eRouteStateMultiTextAnswer).multiTextAnswer
                .filter((item) => item !== null)
                .reduce((acc, cur) => acc + cur + "\n", "")
        }
    }

    const handlePrintClicked = async () => {
        if (isAwaitingFile) return
        setAwaitingFile(true)
        const fileName = q11ePreview!.displayName + ".pdf"
        try {
            await cloudfunctions.httpsCallable("printFremtidsfuldmagt_v4")({
                documentName,
                documentVersion,
            })
            await downloadFile(fileName, user.uid)
        } catch (e) {
            enqueueSnackbar("Der skete en fejl under download af dokumentet. Prøv igen senere.")
        }
        setAwaitingFile(false)
        enqueueSnackbar(`Dokumentet "${fileName}" er downloadet.`)
    }

    const handleEditRequested = () => {
        if (document) return setEditPromptOpen(true)
        navigateToStart()
    }

    const discardOldDocument = async () => {
        if (isAwaitingObsoleteMarking) return
        setAwaitingObsoleteMarking(true)
        await cloudfunctions.httpsCallable("markDocumentObsolete")({
            documentName,
            documentId: document!.id,
        })
        navigateToStart()
    }

    const navigateToStart = () => history.replace(`${TESTAMENT}/${documentName}/${documentVersion}`)

    const markObsoleteBtnClasses = classNames("Button", {
        "Button--red": q11ePreview?.name !== "fremtidsfuldmagt",
        "Button--green": q11ePreview?.name === "fremtidsfuldmagt",
        "Button--icon": !isAwaitingObsoleteMarking,
        "Button--loading-inline": isAwaitingObsoleteMarking,
    })

    if (!q11ePreview || !q11eStorefront || !q11eRouteState || !questionDisplays)
        return (
            <div style={{ display: "flex", alignItems: "center", height: "100%" }}>
                <InlineLoader />
            </div>
        )

    return (
        <>
            <button className="la-fab margin-bottom" onClick={history.goBack}>
                <ArrowBackRounded />
            </button>
            <Intro
                color={q11ePreview.color}
                title={q11ePreview.displayName}
                text={
                    document ? (
                        q11ePreview.name === "fremtidsfuldmagt" ? (
                            <>
                                Dokumentet er gemt og kan downloades nedenfor samt under {" "}
                                <strong>Mine dokumenter</strong>.
                                <br />
                                Nedenfor kan du se næste
                                skridt for tinglysning/notar.
                                <br />
                                <br />
                                Ønsker du at købe flere fremtidsfuldmagter skal du trykke på knappen{" "}
                                <strong>Opret ny</strong> nederst på denne side.
                            </>
                        ) : (
                            <>
                                Dokumentet er gemt og kan downloades nedenfor samt under {" "}
                                <strong>Mine dokumenter</strong>.
                                <br />
                                <br />
                                Ønsker du at købe flere, skal du trykke på knappen{" "}
                                <strong>Opret ny</strong> nederst på denne side.
                            </>
                        )
                    ) :
                        (
                            <>
                                Dokumentet vil kunne downloades,
                                så snart du har gennemført online betaling.
                                Nederst på siden kan du gennemse dine valg.
                            </>
                        )
                }
            />
            <div className="q11e-storefront padding-xy margin-bottom">
                {(document && (
                    <>
                        {documentName === "fremtidsfuldmagt" ? (
                            <FremtidsfuldmagtSteps
                                isAwaitingFile={isAwaitingFile}
                                handlePrintClicked={handlePrintClicked}
                            />
                        ) : documentName === "bornetestamente" ? (
                            <BornetestamenteSteps
                                isAwaitingFile={isAwaitingFile}
                                handlePrintClicked={handlePrintClicked}
                            />
                        ) : (
                            <GiftcardSteps
                                isAwaitingFile={isAwaitingFile}
                                handlePrintClicked={handlePrintClicked}
                            />
                        )}
                        <span className="q11e-storefront__help">
                            Har du brug for hjælp?{" "}
                            <a className="q11e-storefront__help__more-btn" href="mailto:support@adjo.dk">
                                Send os en mail
                            </a>
                            .
                        </span>
                    </>
                )) || (
                        <>
                            <h3>{q11eStorefront.title}</h3>
                            <p className="q11e-storefront__txt">{q11eStorefront.body}</p>
                            <Link
                                className="Button Button--green"
                                to={documentName === "giftcard" ? `${PATHS.DOCUMENT}/${documentName}/${documentVersion}/${resolveDocumentName((q11eRouteState['q1'] as IQ11eRouteStateRadioAnswer).radioAnswerTxt)}/køb` : `${PATHS.DOCUMENT}/${documentName}/${documentVersion}/køb`}
                            >
                                {q11eStorefront.btnTxt}
                            </Link>
                        </>
                    )}
            </div>
            <div className="q11e-result padding-xy">
                {!document && Object.keys(questionDisplays).map((questionId) => (
                    <div className="q11e-result__item" key={questionId}>
                        <h3 className="q11e-result__title">{questionDisplays[questionId].title}</h3>
                        <p className="q11e-result__question">
                            <em>{questionDisplays[questionId].body}</em>
                        </p>
                        <p className="q11e-result__answer">{resolveAnswerDisplay(q11eRouteState[questionId])}</p>
                    </div>
                ))}
                <button className="Button Button--icon q11e-result__edit-btn" onClick={handleEditRequested}>
                    <span>{document ? "Opret ny" : "Rediger"}</span>
                    {document ? <LibraryAdd /> : <EditRounded />}
                </button>
            </div>
            {document && (
                <Prompt
                    title={
                        q11ePreview.name === "fremtidsfuldmagt"
                            ? "Flere fremtidsfuldmagter?"
                            : `Flere ${q11ePreview.displayName.toLowerCase()}?`
                    }
                    body={
                        q11ePreview.name === "fremtidsfuldmagt"
                            ? "Din allerede købte fremtidsfuldmagt er gemt under 'Mine dokumenter'. Ønsker du at købe flere fremtidsfuldmagter klikker du 'Opret ny'."
                            : `Dit allerede købte ${q11ePreview.displayName.toLowerCase()} er gemt under 'Mine dokumenter'. Ønsker du at købe flere, så klikker du 'Opret ny'.`
                    }
                    isOpen={editPromptOpen}
                    handleClose={() => setEditPromptOpen(false)}
                >
                    <button className={markObsoleteBtnClasses} onClick={discardOldDocument}>
                        {(isAwaitingObsoleteMarking && <InlineLoader />) || (
                            <>
                                <span>Opret ny</span>
                                <LibraryAdd />
                            </>
                        )}
                    </button>
                </Prompt>
            )}
        </>
    )
}

export default WithUser(PageDocument)
