import { useEffect, useState } from "react"
import { useHistory, useRouteMatch } from "react-router-dom"

import { cloudfunctions } from "../../firebase"
import { getQuestionnaires } from "../../api/QuestionnaireContentManager"
import useFremtidsfuldmagtPreview from "../hooks/Q11e/useFremtidsfuldmagtPreview"
import useBornetestamentePreview from "../hooks/Q11e/useBornetestamentePreview"
// import useTestamentePreview from "../hooks/Q11e/useTestamentePreview"
import { isQ11eCompleted } from "../../api/Q11eStateManager"
import Module from "../Module/Module"
import LegalCard from "../legal/LegalCard"
import * as ROUTES from "../../routes/paths"
import WithUser from "../hoc/WithUser"
import { DOCUMENT } from "../../routes/paths"

interface Props {
    user: { uid: string }
}

const QuestionnaireList = ({ user }: Props) => {
    const history = useHistory()
    const match = useRouteMatch()
    const [questionnaires, setQuestionnaires] = useState<IQuestionnaire[]>([])

    useEffect(() => {
        getQuestionnaires().then(setQuestionnaires)
    }, [])

    const fremtidsfuldmagtPreview = useFremtidsfuldmagtPreview()
    const bornetestamentePreview = useBornetestamentePreview()
    // const testamentePreview = useTestamentePreview()

    const handleQuestionnaireClicked = (questionnaireID: string) => {
        const source = process.env.REACT_APP_IS_PHONE ? "adjo_android" : "adjo_pwa"
        cloudfunctions.httpsCallable("logQuestionnaireClicked")({ questionnaireID, source })
    }

    const handleQ11eClicked = async (q11eId: string, q11eName: string, q11eVersion: number) => {
        const isCompleted = await isQ11eCompleted(user.uid, q11eId)
        if (isCompleted) {
            history.push(`${DOCUMENT}/${q11eName}/${q11eVersion}`)
        } else {
            history.push(`${match.url}/${q11eName}/${q11eVersion}`)
        }
    }

    return (
        <div>
            {questionnaires.map((questionnaire) => (
                <Module
                    key={questionnaire.id}
                    color={questionnaire.colorIndex}
                    url={`${ROUTES.QUESTIONNAIRE}/${questionnaire.id}/q1`}
                    logEvent={() => handleQuestionnaireClicked(questionnaire.id)}
                >
                    <LegalCard title={questionnaire.title} body={questionnaire.intro} />
                </Module>
            ))}
            {/*{[fremtidsfuldmagtPreview, bornetestamentePreview, testamentePreview]*/}
            {[fremtidsfuldmagtPreview, bornetestamentePreview]
                .filter((preview): preview is IQ11ePreview => !!preview)
                .map((preview) => (
                    <Module
                        key={preview.id}
                        color={preview.color}
                        handleClick={() => handleQ11eClicked(preview.id, preview.name, preview.version)}
                    >
                        <LegalCard title={preview.displayName} body={preview.intro1} />
                    </Module>
                ))}
        </div>
    )
}

export default WithUser(QuestionnaireList)
