import { FormEvent, useEffect, useRef, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { Dialog } from "@mui/material"
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material"

import useQ11eQuestion from "../hooks/Q11e/useQ11eQuestion"
import useQ11eAnswers from "../hooks/Q11e/useQ11eAnswers"
import useQ11eRouteState from "../hooks/Q11e/useQ11eRouteState"
import useQ11eLabelState from "../hooks/Q11e/useQ11eLabelState"
import {
    deleteTextAnswer,
    setDistributionAnswer,
    setMultiTextAnswer,
    setTextAnswer,
    updateEndTime,
} from "../../api/Q11eStateManager"
import Intro from "../Intro/Intro"
import Q11eAnswerDistribution from "./Q11eAnswerDistribution"
import Q11eAnswerRadio from "./Q11eAnswerRadio"
import Q11eAnswerRadios from "./Q11eAnswerRadios"
import Q11eAnswerText from "./Q11eAnswerText"
import WithUser from "../hoc/WithUser"
import * as PATHS from "../../routes/paths"
import Guide from "../Guide/Guide"
import { distributionSumsTo100, renderOverview, resolveNextQuestionId, resolveReplacements } from "./helpers"

interface Props {
    user: { uid: string }
}

interface Params {
    q11eName: string
    q11eVersion: string
    questionId: string
}

const PageQ11eQuestion = ({ user }: Props) => {
    const history = useHistory()
    const { q11eName, q11eVersion, questionId } = useParams<Params>()
    const question = useQ11eQuestion(q11eName, q11eVersion, questionId)
    const answers = useQ11eAnswers(q11eName, q11eVersion, questionId)
    const q11eRouteState = useQ11eRouteState(q11eName, q11eVersion, user.uid)
    const q11eLabelState = useQ11eLabelState(q11eName, q11eVersion, user.uid)
    const [guideModalOpen, setGuideModalOpen] = useState(false)

    const [txt, setTxt] = useState("")
    const [multiTxt, setMultiTxt] = useState<Array<string | null>>(Array(4).fill(null))
    const [showAlways, setShowAlways] = useState<Array<boolean>>([true, false, false, false])
    const [distribution, setDistribution] = useState<Array<number>>([])

    const wrapper = useRef<HTMLDivElement>(null)

    useEffect(() => {
        /* Whenever a question is presented, check to see if it has previously been answered. */
        const routeStateQuestion = q11eRouteState?.[questionId]
        if (!routeStateQuestion) {
            /* Question has NOT been answered previously; clear fields. */
            setTxt("")
            setMultiTxt(Array(4).fill(null))
            setDistribution([])
            setShowAlways([true, false, false, false])
        } else {
            /* Question has previously been answered; set text for fields. */
            if (routeStateQuestion.hasOwnProperty("textAnswer")) {
                setTxt((routeStateQuestion as IQ11eRouteStateTextAnswer).textAnswer)
            } else if (routeStateQuestion.hasOwnProperty("multiTextAnswer")) {
                setMultiTxt((routeStateQuestion as IQ11eRouteStateMultiTextAnswer).multiTextAnswer);

                setShowAlways((routeStateQuestion as IQ11eRouteStateMultiTextAnswer).multiTextAnswer.map((value: any, id: number) => {
                    if(value){
                        return true;
                    }
                    return false;
                }))
                
            } else if (routeStateQuestion.hasOwnProperty("distribution")) {
                setDistribution((routeStateQuestion as IQ11eRouteStateDistributionAnswer).distribution)
            }
        }
    }, [q11eRouteState, questionId])

    useEffect(() => {
        const routeStateQuestion = q11eRouteState?.[questionId]
        if (!routeStateQuestion) {
            setTimeout(() => {
                wrapper.current?.classList.remove("q11e-wrapper--fade-out")
            }, 500)
            return
        }

        setTimeout(() => {
            wrapper.current?.classList.remove("q11e-wrapper--fade-out")
        }, 500)
    }, [q11eRouteState, questionId])

    if (!question || !answers) return null

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()

        let nextQuestionId: string | undefined

        if (answers[0].type === "empty") {
            nextQuestionId = answers[0].targetQuestionId
        } else if (answers[0].type === "txt") {
            if (txt.length) {
                await setTextAnswer(user.uid, q11eName, q11eVersion, question, answers[0] as IQ11eTextAnswer, txt)
            } else {
                await deleteTextAnswer(user.uid, q11eName, q11eVersion, questionId, answers[0] as IQ11eTextAnswer)
            }
            nextQuestionId = answers[0].targetQuestionId
        } else if (answers[0].type === "multiTxt") {
            let labelState: IQ11eLabelState | undefined
            if (multiTxt.length) {
                labelState = await setMultiTextAnswer(
                    user.uid,
                    q11eName,
                    q11eVersion,
                    question,
                    answers[0] as IQ11eMultiTextAnswer,
                    multiTxt
                )
            }
            if (answers[0].targetQuestionIds && answers[0].targetQuestionIds.length > 1 && labelState) {
                /* Branching */
                nextQuestionId = resolveNextQuestionId(answers[0].targetQuestionIds, labelState)
            } else {
                nextQuestionId = answers[0].targetQuestionId
            }
        } else if (answers[0].type === "radios") {
            nextQuestionId = answers[0].targetQuestionId
        } else if (answers[0].type === "distribution") {
            if (!distributionSumsTo100(distribution)) {
                alert("Den procentvise fordeling skal sammenlagt svare til 100 %")
                return
            }
            setDistributionAnswer(
                user.uid,
                q11eName,
                q11eVersion,
                questionId,
                answers[0] as IQ11eDistributionAnswer,
                distribution
            )
            nextQuestionId = answers[0].targetQuestionId
        } else {
            /* answers[0].type === "radio" */
            const answerId = (q11eRouteState?.[questionId] as IQ11eRouteStateRadioAnswer)?.radioAnswerId
            if (!answerId) {
                /* A radio answer cannot be optional */
                return
            }
            const answer = answers.find((answer) => answer.id === answerId)
            if (answer?.targetQuestionIds && answer.targetQuestionIds.length > 1 && q11eLabelState) {
                /* Branching */
                nextQuestionId = resolveNextQuestionId(answer.targetQuestionIds, q11eLabelState)
            } else {
                nextQuestionId = answer?.targetQuestionId
            }
        }

        wrapper.current?.classList.add("q11e-wrapper--fade-out")

        setTimeout(() => {
            setTxt("")
            setMultiTxt([])
        }, 300)

        setTimeout(async () => {
            if (!nextQuestionId) {
                await updateEndTime(user.uid, q11eName, q11eVersion)
                history.push(`${PATHS.DOCUMENT}/${q11eName}/${q11eVersion}`)
            } else {
                history.push(`${PATHS.TESTAMENT}/${q11eName}/${q11eVersion}/${nextQuestionId}`)
            }
        }, 350)
    }

    const handleTextChange = (txt: string, idx?: number) => {
        if (idx === undefined) {
            setTxt(txt)
            return
        }

        const newMultiTxt = [...multiTxt]
        if (txt.trim().length) {
            newMultiTxt[idx] = txt
        } else {
            newMultiTxt[idx] = null
        }
        setMultiTxt(newMultiTxt)
    }

    const onAddMore = (event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null, idx?: number) => {
        if(idx === undefined){
            return
        }

        const newShowAlwaysState = showAlways.map((value, id ) => {
            // 👇️ if id equals 2, update country property
            if (id === idx) {
              return true
            }
      
            // 👇️ otherwise return object as is
            return value;
          });
          setShowAlways(newShowAlwaysState);
      };

    const handleGuideClicked = () => {
        setGuideModalOpen(true)
    }

    const handleNavigateBack = () => {
        if (questionId === "q1") {
            history.goBack()
        } else {
            wrapper.current?.classList.add("q11e-wrapper--fade-out")
            setTimeout(history.goBack, 300)
        }
    }

    return (
        <>
            <button className="la-fab margin-bottom" onClick={handleNavigateBack}>
                <ArrowBackRounded />
            </button>
            <div className="q11e-wrapper" ref={wrapper}>
                <Intro
                    color="white"
                    title={resolveReplacements(question.title, q11eLabelState?.labels)}
                    text={
                        <span>
                            {resolveReplacements(question.body, q11eLabelState?.labels)}{" "}
                            {question.guide ? (
                                <>
                                    <button
                                        className="guide__more-btn"
                                        style={{ fontSize: "inherit" }}
                                        onClick={handleGuideClicked}
                                    >
                                        Læs mere
                                    </button>
                                    &nbsp;{question.guideMoreTxt}
                                </>
                            ) : null}
                        </span>
                    }
                />

                {question.overview && renderOverview(question.overview, q11eRouteState, q11eLabelState?.labels)}

                <form className="la-form q11e-form" onSubmit={handleSubmit}>
                    {answers.map((answer) =>
                        answer.type === "empty" ? null : answer.type === "radio" ? (
                            <Q11eAnswerRadio
                                key={answer.id}
                                answer={answer as IQ11eRadioAnswer}
                                selected={
                                    (q11eRouteState?.[questionId] as IQ11eRouteStateRadioAnswer)?.radioAnswerId ===
                                    answer.id
                                }
                            />
                        ) : answer.type === "radios" ? (
                            <Q11eAnswerRadios
                                key={answer.id}
                                answer={answer as IQ11eRadiosAnswer}
                                response={q11eRouteState?.[questionId] as IQ11eRouteStateRadiosAnswer | undefined}
                                userLabels={q11eLabelState?.labels}
                            />
                        ) : answer.type === "txt" ? (
                            <Q11eAnswerText
                                onAddMore={onAddMore}
                                showAlways={true}
                                key={answer.id}
                                showAll={true}
                                placeholder={(answer as IQ11eTextAnswer).placeholder}
                                required={(answer as IQ11eTextAnswer).required}
                                isNumber={(answer as IQ11eTextAnswer).isNumber}
                                onChange={handleTextChange}
                                value={txt}
                                previousIsShown={false}
                            />
                        ) : answer.type === "distribution" ? (
                            <Q11eAnswerDistribution
                                key={answer.id}
                                answer={answer as IQ11eDistributionAnswer}
                                userLabels={q11eLabelState?.labels}
                                onChange={setDistribution}
                                distribution={distribution}
                            />
                        ) : (
                            (answer as IQ11eMultiTextAnswer).placeholders.map((placeholder, idx) => (
                                <Q11eAnswerText
                                    onAddMore={onAddMore}
                                    addMoreText={(answer as IQ11eMultiTextAnswer).addMoreText}
                                    showAlways={showAlways[idx]}
                                    idx={idx}
                                    showAll={(answer as IQ11eMultiTextAnswer).showAll ?? true}
                                    key={`${answer.id}_${idx}`}
                                    helpTxt={(answer as IQ11eMultiTextAnswer).helpTexts?.[idx]}
                                    placeholder={placeholder}
                                    required={(answer as IQ11eMultiTextAnswer).required[idx]}
                                    onChange={handleTextChange}
                                    value={multiTxt[idx] ?? ""}
                                    previousIsShown={showAlways[idx-1] ?? false}
                                />
                            ))
                        )
                    )}
                    <div className="q11e-nav">
                        <button className="q11e-nav__nxt-btn Button Button--icon">
                            <span>Gem og fortsæt</span>
                            <ArrowForwardRounded />
                        </button>
                    </div>
                </form>
            </div>
            {question.guide && (
                <Dialog className="guide-dialog" open={guideModalOpen} onClose={() => setGuideModalOpen(false)}>
                    <Guide open={guideModalOpen} name={question.guide} onModalClose={() => setGuideModalOpen(false)} />
                </Dialog>
            )}
        </>
    )
}

export default WithUser(PageQ11eQuestion)
