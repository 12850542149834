import { storage } from "../../firebase"

const downloadFile = async (fileName: string, userID: string): Promise<void> => {
    const url = await storage.ref(`users/${userID}/${fileName}`).getDownloadURL()
    const xhr = new XMLHttpRequest()
    xhr.responseType = "blob"
    return new Promise(function (resolve, reject) {
        xhr.onload = () => {
            const blob = xhr.response
            const a = document.createElement("a")
            a.href = window.URL.createObjectURL(blob)
            a.download = fileName
            a.dispatchEvent(new MouseEvent("click"))
            resolve()
        }
        xhr.open("GET", url)
        xhr.send()
    })
}

export default downloadFile
