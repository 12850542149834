import { firestore } from "../firebase"
import Environment from "../model/Environment"

export const logCurrentEnv = async (userId: string) => {
    const env = new Environment(navigator.userAgent, new Date())
    const hash = env.hashCode(env.ua)
    const preFix = process.env.REACT_APP_IS_PHONE ? "andorid" : "web"
    const id = preFix + "_" + hash

    return await firestore
        .collection("users")
        .doc(userId)
        .collection("env")
        .doc(id)
        .withConverter(env.converter)
        .set(env)
}
