import PropTypes from "prop-types"

import WithUser from "../hoc/WithUser"
import WithCategories from "../hoc/WithCategories"
import WithWishes from "../hoc/WithWishes"
import WithCategoriesLife from "../hoc/WithCategoriesLife"
import WithLife from "../hoc/WithLife"

import Loading from "../Loader/Loading"

const Loader = (props) => {
    // If we have a authenticated user, await this data before displaying app
    const { user, categories, categoriesLife, wishes, life, children } = props
    return (
        <>
            {user ? (
                <>{!categories.length || !categoriesLife.length || !life || !wishes ? <Loading /> : <>{children}</>}</>
            ) : (
                <>{children}</>
            )}
        </>
    )
}

export default WithLife(WithCategoriesLife(WithWishes(WithCategories(WithUser(Loader)))))

Loader.propTypes = {
    user: PropTypes.any,
    categories: PropTypes.array,
    categoriesLife: PropTypes.array,
    wishes: PropTypes.any,
    children: PropTypes.any.isRequired,
}
