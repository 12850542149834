import { useEffect, useState } from "react"
import { getCharityPreviews } from "../../../api/CharityManager"

const useCharityPreviews = (): ICharityPreview[] => {
    const [charities, setCharities] = useState<ICharityPreview[]>([])

    useEffect(() => {
        getCharityPreviews().then(setCharities)
    }, [])

    return charities
}

export default useCharityPreviews
