import { Component } from "react"
import { CloseRounded, DoneRounded } from "@mui/icons-material"
import PropTypes from "prop-types"

import { acceptLink, deleteLink } from "../../api/LinkManager"

import WithUser from "../hoc/WithUser"

import icons from "../../assets/icons.svg"
const personIcon = icons + "#person"
const pendingIcon = icons + "#pending"
const cancelIcon = icons + "#cancel-invitation"

class PendingLink extends Component {
    state = {
        loading: false,
        loadingAccept: false,
        loadingDecline: false,
        loadingCancel: false,
    }

    acceptInvitation = () => {
        if (this.state.loading) {
            return
        }
        this.setState({ loading: true, loadingAccept: true })

        const user = this.props.user
        const data = {
            status: 1,
            invitee_id: user.uid,
            invitee_name: `${user["first_name"]} ${user["surname"]}`,
            invitee_first_name: user.first_name,
            invitee_photo: user.photo ? user.photo : null,
        }

        setTimeout(() => {
            // Indicate that the request is loading for at least 1s
            acceptLink(this.props.link["id"], data)
                .then(() => {
                    this.props.onDismiss()
                })
                .catch((error) => {
                    alert("Noget gik galt... Prøv igen.")
                    console.error("Error accepting link invitation: ", error)
                    this.setState({ loading: false, loadingAccept: false })
                })
        }, 1000)
    }

    declineInvitation = () => {
        if (this.state.loading) {
            return
        }
        this.setState({ loading: true, loadingDecline: true })

        setTimeout(() => {
            // Indicate that the request is loading for at least 1s
            deleteLink(this.props.link["id"])
                .then(() => {
                    this.props.onDismiss()
                })
                .catch((error) => {
                    alert("Noget gik galt... Prøv igen.")
                    console.error("Error deleting link: ", error)
                    this.setState({ loading: false, loadingDecline: false })
                })
        }, 1000)
    }

    cancelInvitation = () => {
        if (this.state.loading) {
            return
        }
        this.setState({ loading: true, loadingCancel: true })

        setTimeout(() => {
            // Indicate that the request is loading for at least 1s
            deleteLink(this.props.link["id"])
                .then(() => {
                    this.props.onDismiss()
                })
                .catch((error) => {
                    alert("Noget gik galt... Prøv igen.")
                    console.error("Error deleting link: ", error)
                    this.setState({ loading: false, loadingCancel: false })
                })
        }, 1000)
    }

    render() {
        const { link, user } = this.props
        const { loading, loadingAccept, loadingDecline, loadingCancel } = this.state

        const isCreator = user.uid === link["creator_id"]

        return (
            <div className="Link__pending">
                <button className="la-fab" onClick={this.props.onDismiss}>
                    <CloseRounded />
                </button>
                {isCreator ? (
                    <>
                        <figure className="Link__pending__img">
                            <svg>
                                <use xlinkHref={pendingIcon} />
                            </svg>
                        </figure>
                        <h3>Din invitation er sendt</h3>
                        <div className="Link__pending__text">
                            <p>
                                Når{" "}
                                <i>
                                    <b>{link["invitee_email"]}</b>
                                </i>{" "}
                                har accepteret din anmodning, vil han/hun have mulighed for at se dine ønsker.
                            </p>
                        </div>
                        <div className="info">
                            <p>Info:</p>
                            <p>Du kan ikke se vedkommendes ønsker før han/hun selv har givet tilladelse.</p>
                        </div>
                        <div className="Link__pending__actions">
                            <button
                                className="Button Button--icon"
                                onClick={this.cancelInvitation}
                                style={{ cursor: loading ? "wait" : "pointer" }}
                            >
                                <span>{loadingCancel ? "Annullerer invitation..." : "Annulér invitation"}</span>
                                <svg>
                                    <use xlinkHref={cancelIcon} />
                                </svg>
                            </button>
                            {/* <button className="Button Button--outline">
                <span>Send igen</span>
              </button> */}
                        </div>
                    </>
                ) : (
                    <>
                        <figure className="Link__pending__img">
                            {link["creator_photo"] ? (
                                <img src={link["creator_photo"]} alt={link["creator_name"]} />
                            ) : (
                                <svg>
                                    <use xlinkHref={personIcon} />
                                </svg>
                            )}
                        </figure>
                        <h2>{link["creator_name"]}</h2>
                        <div className="Link__pending__text">
                            <p>
                                <b>{link["creator_first_name"] ?? link["creator_name"]} vil gerne dele sine ønsker</b>
                            </p>
                            <p>
                                Ved at acceptere får du mulighed for at se alle de ønsker{" "}
                                {link["creator_first_name"] ?? link["creator_name"]} har angivet i Adjø.
                            </p>
                        </div>
                        <div className="info">
                            <p>Info:</p>
                            <p>
                                Selvom du accepterer, kan {link["creator_first_name"] ?? link["creator_name"]} ikke se
                                dine ønsker før du selv har givet lov.
                            </p>
                        </div>
                        <div className="Link__pending__actions">
                            <button
                                className="Button Button--icon"
                                onClick={this.acceptInvitation}
                                style={{ cursor: loading ? "wait" : "pointer" }}
                            >
                                <span>{loadingAccept ? "Accepterer invitation..." : "Acceptér invitation"}</span>
                                <DoneRounded />
                            </button>
                            <button
                                className="Button Button--outline"
                                onClick={this.declineInvitation}
                                style={{ cursor: loading ? "wait" : "pointer" }}
                            >
                                <span>{loadingDecline ? "Afviser invitation..." : "Afvis invitation"}</span>
                            </button>
                        </div>
                    </>
                )}
            </div>
        )
    }
}

export default WithUser(PendingLink)

PendingLink.propTypes = {
    link: PropTypes.any.isRequired,
    user: PropTypes.any.isRequired,
    onDismiss: PropTypes.func.isRequired,
}
