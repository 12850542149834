import { CategoriesContext } from "../../providers/CategoriesProvider"

// This is to give the actual component a name when debugging
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component"
}

const WithCategories = (Component) => {
    const WrappedComponent = (props) => {
        return (
            <CategoriesContext.Consumer>
                {(categories) => <Component categories={categories} {...props} />}
            </CategoriesContext.Consumer>
        )
    }
    WrappedComponent.displayName = `WithCategories(${getDisplayName(WrappedComponent)})`
    return WrappedComponent
}

export default WithCategories
