import { useEffect, useState } from "react"

import { firestore } from "../../firebase"
import { getLink } from "../../api/LinkManager"

export const useLink = (linkID?: string): ILink | undefined => {
    const [link, setLink] = useState<ILink>()

    useEffect(() => {
        if (!linkID) {
            setLink(undefined)
            return
        }
        getLink(linkID).then(setLink)
    }, [linkID])

    return link
}

export const useLinkListener = (linkID?: string): ILink | undefined => {
    const [link, setLink] = useState<ILink>()

    useEffect(() => {
        if (!linkID) return

        const subscribe = () => {
            firestore
                .collection("links")
                .doc(linkID)
                .onSnapshot((doc) => {
                    const link = doc.data() as ILink
                    setLink(link)
                })
        }

        return subscribe()
    }, [linkID])

    return link
}

export const useLinkDetails = (userID: string, link?: ILink) => {
    const [isCreator, setIsCreator] = useState<boolean>()
    const [isPending, setIsPending] = useState<boolean>()
    const [fullName, setFullName] = useState<string>()
    const [firstName, setFirstName] = useState<string>()
    const [email, setEmail] = useState<string>()
    const [image, setImage] = useState<string>()

    useEffect(() => {
        if (!link) {
            setIsCreator(undefined)
            setIsPending(undefined)
            setFullName(undefined)
            setFirstName(undefined)
            setEmail(undefined)
            setImage(undefined)
            return
        }
        const isCreator = userID === link.creator_id
        setIsCreator(isCreator)
        setIsPending(link.status === 0)
        setFullName(isCreator ? link.invitee_name : link.creator_name)
        setFirstName(isCreator ? link.invitee_first_name : link.creator_first_name)
        setEmail(isCreator ? link.invitee_email : link.creator_email)
        setImage(isCreator ? link.invitee_photo : link.creator_photo)
    }, [link, userID])

    return { isCreator, isPending, fullName, firstName, email, image }
}
