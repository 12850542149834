import { useState, useEffect, useRef } from "react"
import { Dialog } from "@mui/material"
import { EditRounded } from "@mui/icons-material"
import PropTypes from "prop-types"
import classNames from "classnames"

import FormAnswer from "../Form/FormAnswer"
import UndertakerPreview from "../Undertaker/UndertakerPreview"
import UndertakerDetail from "../Undertaker/UndertakerDetail"
import Guide from "../Guide/Guide"
import WithWishes from "../hoc/WithWishes"
import WithLife from "../hoc/WithLife"
import { getUndertaker, deleteUndertaker } from "../../firebase"
import { logGuideClicked } from "../../api/GuideManager"

import icons from "../../assets/icons.svg"

const checkmarkIcon = icons + "#checkmark"
const circleIcon = icons + "#circle"

const Question = (props) => {
    const { type, question, wishes, life, topicId, categoryId } = props
    const [editMode, setEditMode] = useState(false)
    const [undertaker, setUndertaker] = useState(undefined)
    const [undertakerModalOpen, setUndertakerModalOpen] = useState(false)
    const [guideModalOpen, setGuideModalOpen] = useState(false)

    useEffect(() => {
        if (question["isUndertaker"] && wishes.undertaker) {
            if (wishes.undertaker !== undertaker?.id) {
                getUndertaker(wishes.undertaker)
                    .then((querySnapshot) => {
                        querySnapshot.forEach((doc) => {
                            setUndertaker(doc.data())
                        })
                    })
                    .catch((error) => {
                        console.error("Error getting undertaker: ", error)
                    })
            }
        } else {
            setUndertaker(undefined)
        }
    }, [question, wishes, undertaker])

    const isPhone = process.env.REACT_APP_IS_PHONE

    let mounted = useRef(false)

    useEffect(() => {
        // Need mounted state to avoid setting state if unmounted in promise
        mounted.current = true
        return () => {
            mounted.current = false
        }
    }, [])

    // Variable to indicate if this question been answered in any type of way
    let isAnswered = false

    // Variables for different types of possible answers
    let isIgnored = false
    let predefinedOption = ""
    let optionID = ""
    let items = []
    let textAnswer = ""
    let ownAnswer = {}
    let comment = ""

    let answer = undefined

    if (type === "wish") {
        answer = wishes[categoryId]?.[topicId]?.[question.id]
    } else if (type === "life") {
        answer = life[categoryId]?.[question.id]
    }

    /** Is `answer` a non-empty object? */
    if (answer && Object.keys(answer).length) {
        if (answer.isIgnored) {
            isIgnored = true
            isAnswered = true
        }
        if (answer.items?.length) {
            items = answer.items
            isAnswered = true
        }
        if (answer.comment?.length) {
            comment = answer.comment
            isAnswered = true
        }
        if (answer.optionID) {
            optionID = answer.optionID
            predefinedOption = question.options.find((option) => option.id === optionID).title
            isAnswered = true
        }
        if (answer.txtAnswer) {
            ownAnswer = answer.txtAnswer
            if (answer.txtAnswer.isChecked) {
                textAnswer = answer.txtAnswer.txt
                isAnswered = true
            }
        }
    }

    const QuestionClasses = classNames("Question box-shadow bg-white rounded-borders", {
        "Question--completed": isAnswered || undertaker,
    })
    const icon = isAnswered || undertaker ? checkmarkIcon : circleIcon
    const buttonText = isAnswered || undertaker ? "Rediger dit ønske" : "Tilføj dit ønske"

    const handleClickUndertaker = () => {
        setUndertakerModalOpen(true)
    }

    const handleDeleteUndertaker = () => {
        setUndertakerModalOpen(false)
        deleteUndertaker()
    }

    const handleGuideClicked = () => {
        setGuideModalOpen(true)
        logGuideClicked(question.more)
    }

    return (
        <>
            <li className={QuestionClasses}>
                <div className="Question__top padding-xy">
                    <h3 className="Question__title">{question.title}</h3>
                    <svg>
                        <use xlinkHref={icon} />
                    </svg>
                </div>
                <div className="Question__desc bg-lightergray padding-xy">
                    <p className={isPhone && "has-phone-font-size"}>
                        {question.desc}{" "}
                        {question.more && !question.inspiration && (
                            <button className="guide__more-btn" onClick={handleGuideClicked}>
                                Læs mere
                            </button>
                        )}
                    </p>
                    {question.inspiration && (
                        <button className="Button Button--purple Button--floating" onClick={handleGuideClicked}>
                            Få inspiration
                        </button>
                    )}
                </div>
                {editMode ? (
                    <FormAnswer
                        question={question}
                        category={categoryId}
                        topic={topicId || ""}
                        toggleEditMode={() => setEditMode(!editMode)}
                        ignored={isIgnored}
                        items={items}
                        option={optionID}
                        ownAnswer={ownAnswer}
                        comment={comment}
                        type={type}
                        undertaker={undertaker}
                    />
                ) : (
                    <>
                        <div className="Question__answer padding-xy">
                            <h4 className="Question__answer__title">Dit ønske:</h4>
                            {isAnswered || undertaker ? (
                                <>
                                    {isIgnored ? (
                                        <p>Det er ikke vigtigt for mig</p>
                                    ) : (
                                        <>
                                            {items && (
                                                <ul className="list-unstyled">
                                                    {items.map((item, i) => (
                                                        <li key={i}>{item}</li>
                                                    ))}
                                                </ul>
                                            )}
                                            {predefinedOption && (
                                                <div>
                                                    <p>{predefinedOption}</p>
                                                </div>
                                            )}
                                            {textAnswer && (
                                                <div>
                                                    <p>{textAnswer}</p>
                                                </div>
                                            )}
                                            {undertaker && (
                                                <>
                                                    <UndertakerPreview
                                                        undertaker={undertaker}
                                                        onSelect={handleClickUndertaker}
                                                    />
                                                    <Dialog
                                                        open={undertakerModalOpen}
                                                        onClose={() => setUndertakerModalOpen(false)}
                                                    >
                                                        <UndertakerDetail
                                                            undertaker={undertaker}
                                                            isSelected={true}
                                                            onSelect={handleDeleteUndertaker}
                                                            onBack={() => setUndertakerModalOpen(false)}
                                                        />
                                                    </Dialog>
                                                </>
                                            )}
                                        </>
                                    )}
                                    {comment && (
                                        <div className="Question__answer__comment">
                                            <span>Noter:</span>
                                            <p>{comment}</p>
                                        </div>
                                    )}
                                </>
                            ) : (
                                <p style={{ fontWeight: 600 }}>Du har ikke taget stilling til dette spørgsmål endnu</p>
                            )}
                        </div>
                        <button className="Question__editbtn" onClick={() => setEditMode(!editMode)}>
                            <span>{buttonText}</span>
                            <EditRounded />
                        </button>
                    </>
                )}
            </li>
            <div className="Question__dots">
                <span>&#183;</span>
                <span>&#183;</span>
                <span>&#183;</span>
            </div>

            <Dialog className="guide-dialog" open={guideModalOpen} onClose={() => setGuideModalOpen(false)}>
                <Guide open={guideModalOpen} name={question.more} onModalClose={() => setGuideModalOpen(false)} />
            </Dialog>
        </>
    )
}

export default WithLife(WithWishes(Question))

Question.propTypes = {
    categoryId: PropTypes.string.isRequired,
    topicId: PropTypes.string,
    type: PropTypes.string.isRequired,
    question: PropTypes.any.isRequired,
    wishes: PropTypes.any.isRequired,
    life: PropTypes.any.isRequired,
}
