import { useHistory, useParams } from "react-router-dom"
import { ArrowBackRounded } from "@mui/icons-material"

import useCharity from "../hooks/Charity/useCharity"
import CharityIntro from "./CharityIntro"
import CharityDonation from "./CharityDonation"

interface Params {
    charityName: string
}

const CharityDetail = () => {
    const history = useHistory()
    const { charityName } = useParams<Params>()
    const charity = useCharity(charityName)

    if (!charity) return null

    return (
        <>
            <button className="la-fab margin-bottom" onClick={history.goBack}>
                <ArrowBackRounded />
            </button>
            <CharityIntro
                displayName={charity.displayName}
                description={charity.description}
                url={charity.url}
                logoUrl={charity.logo.url}
            />
            <CharityDonation charityName={charity.name} charityDisplayName={charity.displayName} />
        </>
    )
}

export default CharityDetail
