import { Error } from "./model"

export const resolveError = (errorCode: string): Error => {
    if (errorCode === "auth/user-not-found") {
        return {
            title: "Ugyldig e-mail",
            txt: "Der er ikke oprettet nogen bruger med den indtastede e-mail.",
        }
    } else if (errorCode === "auth/invalid-email") {
        return {
            title: "Ugyldig e-mail",
            txt: "Den indtastede e-mail er ikke en gyldig e-mailadresse.",
        }
    } else {
        return {
            title: "Uventet fejl",
            txt: "Der skete en uventet fejl.\nKontakt venligst vores support afdeling.",
        }
    }
}
