import { Dialog } from "@mui/material"

interface Props {
    title: string
    body: string | string[]
    isOpen: boolean
    handleClose: () => void
}

const Alert = ({ title, body, isOpen, handleClose }: Props) => (
    <Dialog className="alert" open={isOpen} onClose={handleClose}>
        <h2>{title}</h2>
        <p className="alert__body">
            {typeof body === "string" ? body : body.map((line, idx) => <><span key={idx}>{line}</span><br /><br /></>)}
        </p>
        <div className="alert__action">
            <button type="button" className="Button" onClick={handleClose}>
                OK
            </button>
        </div>
    </Dialog>
)

export default Alert
