import { Component, createContext } from "react"
import { firestore } from "../firebase"

import WithUser from "../components/hoc/WithUser"

export const CategoriesLifeContext = createContext({ categoriesLife: [] })

class CategoriesLifeProvider extends Component {
    state = {
        categoriesLife: [],
    }

    componentDidMount() {
        if (!this.props.user) {
            return
        }
        this.getCategories()
    }

    componentDidUpdate() {
        if (!this.props.user) {
            return
        }
        if (!this.state.categoriesLife || !this.state.categoriesLife.length) {
            this.getCategories()
        }
    }

    getCategories() {
        firestore
            .collection("categories_life")
            .doc("v6")
            .get()
            .then((docSnapshot) => {
                this.setState({
                    categoriesLife: docSnapshot.data().categories,
                })
            })
            .catch((error) => {
                console.error("Error: ", error)
            })
    }

    render() {
        const { categoriesLife } = this.state
        const { children } = this.props
        return <CategoriesLifeContext.Provider value={categoriesLife}>{children}</CategoriesLifeContext.Provider>
    }
}

export default WithUser(CategoriesLifeProvider)
