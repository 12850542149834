import { ImportantQuestions } from "../../model/ImportantQuestion"
import Loading from "../Loader/InlineLoader"
import Intro from "../Intro/Intro"
import { PUBLIC_BUCKET } from "../../routes/paths"
import ImportantQuestionList from "../Questions/ImportantQuestionList"
import withImportantQuestions from "../hoc/withImportantQuestions"
import WithCategories from "../hoc/WithCategories"

interface Props {
    categories: any
    importantQuestions?: ImportantQuestions
}

const PageImportant = ({ categories, importantQuestions }: Props) => {
    if (!importantQuestions) {
        return <Loading />
    }

    const questions = importantQuestions.data.map((iq) => {
        const category = categories.find((c: any) => c.id === iq.categoryId)
        const topic = category.topics.find((t: any) => t.id === iq.topicId)
        return topic.questions.find((q: any) => q.id === iq.questionId)
    })

    return (
        <>
            <Intro
                title={importantQuestions.intro.title}
                text={importantQuestions.intro.body}
                illustration={`${PUBLIC_BUCKET}/illustrations/ill/${importantQuestions.intro.img}.png`}
                color="lightblue"
            />
            <ImportantQuestionList importantQuestions={importantQuestions.data} questions={questions} />
        </>
    )
}

export default withImportantQuestions(WithCategories(PageImportant))
