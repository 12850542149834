import { LifeContext } from "../../providers/LifeProvider"

// This is to give the actual component a name when debugging
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component"
}

const WithLife = (Component) => {
    const WrappedComponent = (props) => {
        return <LifeContext.Consumer>{(life) => <Component life={life} {...props} />}</LifeContext.Consumer>
    }
    WrappedComponent.displayName = `WithLife(${getDisplayName(WrappedComponent)})`
    return WrappedComponent
}

export default WithLife
