import { useEffect, useState } from "react"
import { getGuide } from "../../api/GuideManager"

const PageTradingTerms = () => {
    const [guide, setGuide] = useState<IGuide>()

    useEffect(() => {
        getGuide("handelsbetingelser").then(setGuide)
    }, [])

    if (!guide) {
        return <h5 style={{ textAlign: "center", marginTop: 120 }}>Vent venligst...</h5>
    }

    return <div dangerouslySetInnerHTML={{ __html: guide.content }} />
}

export default PageTradingTerms
