import { ChangeEvent, FormEvent, useEffect, useState } from "react"
import { useHistory, useParams } from "react-router-dom"
import { TextField } from "@mui/material"
import { ArrowBackRounded, DeleteRounded } from "@mui/icons-material"
import { useSnackbar } from "notistack"

import useOtherCharity from "../hooks/Charity/useOtherCharity"
import CharityOtherIntro from "./CharityOtherIntro"
import { slugify } from "../../util"
import { deleteCharityOther, setCharityOther } from "../../api/CharityDonationManager"
import Prompt from "../dialogs/Prompt"
import { CHARITIES } from "../../routes/paths"
import WithUser from "../hoc/WithUser"

interface Props {
    user: { uid: string }
}

interface Params {
    charityName?: string
}

const CharityOtherAdd = ({ user }: Props) => {
    const history = useHistory()

    const { charityName } = useParams<Params>()
    const charity = useOtherCharity(charityName, user.uid)
    const { enqueueSnackbar } = useSnackbar()

    const [deletePromptOpen, setDeletePromptOpen] = useState(false)
    const [isDeleting, setIsDeleting] = useState(false)
    const [displayName, setDisplayName] = useState("")
    const [amount, setAmount] = useState("")

    useEffect(() => {
        if (!charity) return
        setAmount(String(charity.amount))
    }, [charity])

    const handleDisplayNameChange = (event: ChangeEvent<HTMLInputElement>) => {
        const strValue = event.target.value
        setDisplayName(strValue)
    }

    const handleAmountChange = (event: ChangeEvent<HTMLInputElement>) => {
        const strValue = event.target.value
        if (strValue === "") {
            setAmount(strValue)
        }

        const intValue = parseInt(strValue, 10)
        if (!isNaN(intValue) && intValue > -1) {
            setAmount(String(intValue))
        }
    }

    const handleSubmit = async (event: FormEvent) => {
        event.preventDefault()

        const charityOther: ICharityOther = charity
            ? {
                  ...charity,
                  amount: +amount,
              }
            : {
                  name: slugify(displayName),
                  displayName,
                  amount: +amount,
              }

        await setCharityOther(user.uid, charityOther)

        const msg = charity
            ? `Donationsbeløbet til organisationen ${charity.displayName} blev ændret.`
            : `Organisationen ${charityOther.displayName} blev tilføjet.`

        enqueueSnackbar(msg)
        history.replace(CHARITIES)
    }

    const handleDeleteClicked = async () => {
        if (isDeleting) return
        setIsDeleting(true)
        await deleteCharityOther(user.uid, charity!)
        enqueueSnackbar(`Organisationen ${charity!.displayName} blev slettet`)
        history.replace(CHARITIES)
    }

    return (
        <>
            <button className="la-fab margin-bottom" onClick={history.goBack}>
                <ArrowBackRounded />
            </button>
            <CharityOtherIntro
                displayName={charityName ? charity?.displayName : "Anden organisation"}
                body={
                    charityName
                        ? "Denne organisation har du selv tilføjet. Her kan du ændre donationsbeløbet."
                        : "Kan du ikke finde en bestemt organisation på listen, kan du selv tilføje den her, samt angive et donationsbeløb."
                }
            />
            <div className="charity-donation padding-xy margin-bottom rounded-borders">
                <form className="la-form" onSubmit={handleSubmit}>
                    {!charityName && (
                        <>
                            <h2>Navn</h2>
                            <p>Her skal du skrive navnet på organisationen.</p>
                            <div className="la-form__field">
                                <TextField
                                    required
                                    variant="outlined"
                                    value={displayName}
                                    label="Navn"
                                    onChange={handleDisplayNameChange}
                                    name="display-name"
                                    type="text"
                                />
                            </div>
                        </>
                    )}
                    <h2>Donation</h2>
                    <p>
                        Her skal du skrive det beløb du ønsker at donere til{" "}
                        {charityName ? charity?.displayName : "organisationen"}.
                    </p>
                    <div className="la-form__field">
                        <TextField
                            required
                            variant="outlined"
                            value={amount}
                            label="Beløb (DKK)"
                            onChange={handleAmountChange}
                            name="value"
                            type="number"
                        />
                    </div>
                    <div className="la-form__field">
                        <button className="Button Button--green">{charityName ? "Gem" : "Bekræft"}</button>
                    </div>
                    {charityName && (
                        <div className="la-form__field">
                            <button
                                type="button"
                                className="Button Button--flat btn--delete"
                                onClick={() => setDeletePromptOpen(true)}
                            >
                                Slet
                            </button>
                        </div>
                    )}
                </form>
            </div>
            {charityName && (
                <Prompt
                    title="Slet organisation?"
                    body={`Organisationen ${charity?.displayName} samt din donation på ${charity?.amount} DKK vil blive slettet.`}
                    isOpen={deletePromptOpen}
                    handleClose={() => setDeletePromptOpen(false)}
                >
                    <button
                        className="Button Button--red Button--icon"
                        disabled={isDeleting}
                        onClick={handleDeleteClicked}
                    >
                        <span>{isDeleting ? "Vent..." : "Slet"}</span>
                        <DeleteRounded />
                    </button>
                </Prompt>
            )}
        </>
    )
}

export default WithUser(CharityOtherAdd)
