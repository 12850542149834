import { useState } from "react"
import PropTypes from "prop-types"

import icons from "../../assets/icons.svg"
const checkmarkIcon = icons + "#checkmark"
const plusIcon = icons + "#plus"

const FormUserInputNewItem = (props) => {
    const { title, onSave, onChange, addTxt } = props
    const [editMode, setEditMode] = useState(false)

    const toggleEditMode = (e, state) => {
        e.preventDefault()
        setEditMode(state)
    }

    const save = (e) => {
        e.preventDefault()
        onSave()
        setEditMode(false)
    }

    const addItemLabel = addTxt ? addTxt : "Tilføj dig eget svar"

    return (
        <div className="Form__inputwrapper">
            {editMode ? (
                <div className="Form__userinput">
                    <div>
                        <input type="text" name="newItem" value={title} onChange={onChange} autoFocus />
                        <div className="Form__userinput__actions">
                            <button className="Button Button--icon save" onClick={save}>
                                <span>OK</span>
                                <svg>
                                    <use xlinkHref={checkmarkIcon}></use>
                                </svg>
                            </button>
                        </div>
                    </div>
                </div>
            ) : (
                <div className="Form__addbtn">
                    <button className="Button Button--outline Button--icon" onClick={(e) => toggleEditMode(e, true)}>
                        <span>{addItemLabel}</span>
                        <svg>
                            <use xlinkHref={plusIcon}></use>
                        </svg>
                    </button>
                </div>
            )}
        </div>
    )
}

export default FormUserInputNewItem

FormUserInputNewItem.propTypes = {
    title: PropTypes.string.isRequired,
    onSave: PropTypes.func.isRequired,
    onChange: PropTypes.func.isRequired,
    addTxt: PropTypes.string,
}
