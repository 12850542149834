import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useQ11eRouteState = (q11eName: string, q11eVersion: string, userId?: string): IQ11eRouteState | undefined => {
    const [q11eRouteState, setQ11eRouteState] = useState<IQ11eRouteState>()

    useEffect(() => {
        if (!userId) return

        const q11eId = q11eName + "_" + q11eVersion

        const subscribe = () =>
            firestore
                .collection("users")
                .doc(userId)
                .collection("q11eState")
                .doc(q11eId)
                .collection("route")
                .onSnapshot((querySnap) => {
                    const routeState: IQ11eRouteState = {}

                    querySnap.forEach((queryDocSnap) => {
                        const questionId = queryDocSnap.id
                        routeState[questionId] = queryDocSnap.data() as
                            | IQ11eRouteStateRadioAnswer
                            | IQ11eRouteStateTextAnswer
                            | IQ11eRouteStateMultiTextAnswer
                    })

                    setQ11eRouteState(routeState)
                })

        return subscribe()
    }, [q11eName, q11eVersion, userId])

    return q11eRouteState
}

export default useQ11eRouteState
