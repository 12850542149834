import { useEffect, useState } from "react"

import { getStorefront } from "../../../api/Q11eContentManager"

const useQ11eStorefront = (q11eName: string, q11eVersion: string): IQ11eStorefront | undefined => {
    const [storefront, setStorefront] = useState<IQ11eStorefront>()

    useEffect(() => {
        const q11eId = q11eName + "_" + q11eVersion
        getStorefront(q11eId).then(setStorefront)
    }, [q11eName, q11eVersion])

    return storefront
}

export default useQ11eStorefront
