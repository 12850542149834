import { BASE_URL, REQUEST_INIT, MODEL_ALL_DIGITAL_INHERITANCES } from "./datocms"

export const getEntries = async (): Promise<IDigitalInheritance[]> => {
    const query = `{ ${MODEL_ALL_DIGITAL_INHERITANCES} { name displayName guideName sortOrder logo { url } } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            const entries = res.data?.[MODEL_ALL_DIGITAL_INHERITANCES] as IDigitalInheritance[]
            return entries?.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0)) || []
        })
        .catch((error) => {
            console.log(error)
            return []
        })
}
