import { useHistory, useParams } from "react-router-dom"
import { ArrowBackRounded } from "@mui/icons-material"

import { useLinkDetails, useLinkListener } from "../hooks/useLink"
import WithUser from "../hoc/WithUser"

import LinkSharing from "./LinkSharing"
import LinkIntro from "./LinkIntro"
import LinkHistory from "./LinkHistory"

interface Props {
    user: { uid: string }
}

interface Params {
    id: string
}

const LinkDetail = ({ user }: Props) => {
    const history = useHistory()
    const { id } = useParams<Params>()
    const link = useLinkListener(id)
    const { isCreator, fullName, firstName, email, image } = useLinkDetails(user.uid, link)

    if (!link) return null

    return (
        <>
            <button className="la-fab margin-bottom" onClick={history.goBack}>
                <ArrowBackRounded />
            </button>
            <LinkIntro fullName={fullName!} email={email!} photo={image} />
            <div className="grid-x grid-padding-x--small">
                <LinkSharing link={link} name={firstName!} isCreator={isCreator!} />
                <LinkHistory link={link} />
            </div>
        </>
    )
}

export default WithUser(LinkDetail)
