import { useState } from "react"
import { useHistory } from "react-router-dom"

import { useLinkDetails } from "../hooks/useLink"
import { deleteLink } from "../../api/LinkManager"
import WithUser from "../hoc/WithUser"
import { DeleteRounded } from "@mui/icons-material"
import Prompt from "../dialogs/Prompt"

interface Props {
    user: any
    link: ILink
}

const LinkHistory = ({ user, link }: Props) => {
    const history = useHistory()
    const { isCreator, firstName } = useLinkDetails(user.uid, link)
    const [loading, setLoading] = useState(false)
    const [deletePromptOpen, setDeletePromptOpen] = useState(false)

    const removeLink = async () => {
        if (loading) return
        setLoading(true)

        try {
            await deleteLink(link.id)
            history.goBack()
        } catch (error) {
            alert("Noget gik galt... Prøv igen.")
            console.error("Error deleting link: ", error)
            setLoading(false)
        }
    }

    return (
        <>
            <div className="cell link-sharing-card-wrapper">
                <div className="link-sharing-card padding-xy margin-bottom bg-white">
                    {(isCreator && (
                        <div className="link-sharing-card__title">
                            Du inviterede {firstName} til deling {link.date_created.toDate().toLocaleDateString()}
                        </div>
                    )) || (
                        <div className="link-sharing-card__title">
                            {firstName} inviterede dig til deling {link.date_created.toDate().toLocaleDateString()}
                        </div>
                    )}
                    <button
                        className="Button Button--outline Button--icon btn--delete"
                        onClick={() => setDeletePromptOpen(true)}
                    >
                        <span>Slet forbindelse</span>
                        <DeleteRounded />
                    </button>
                </div>
            </div>
            <Prompt
                title="Slet forbindelse?"
                body={`David og dig vil ikke længere kunne dele hinandens ønsker.`}
                isOpen={deletePromptOpen}
                handleClose={() => setDeletePromptOpen(false)}
            >
                <button
                    className="Button Button--red"
                    onClick={removeLink}
                    style={{ cursor: loading ? "wait" : "pointer" }}
                >
                    <span>{loading ? "Vent..." : "Slet"}</span>
                </button>
            </Prompt>
        </>
    )
}

export default WithUser(LinkHistory)
