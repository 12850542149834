import icons from "../../assets/icons.svg"
const placeholder = icons + "#user"

interface Props {
    fullName: string
    email: string
    photo?: string
}

const LinkIntro = ({ fullName, email, photo }: Props) => (
    <div className="intro padding-xy margin-bottom rounded-borders">
        <div className="intro__left">
            <h1>{fullName}</h1>
            <p className="has-big-font-size">
                <a href={"mailto:" + email}>{email}</a>
            </p>
        </div>
        <div className="intro__img-wrapper">
            {(photo && <img className="intro__img" src={photo} alt={photo} />) || (
                <svg className="intro__img intro__img--placeholder">
                    <use xlinkHref={placeholder} />
                </svg>
            )}
        </div>
    </div>
)

export default LinkIntro
