import { ImportantQuestion } from "../../model/ImportantQuestion"
import Question from "./Question"
import NewNewLinkForm from "../Links/NewNewLinkForm"

interface Props {
    importantQuestions: ImportantQuestion[]
    questions: any[]
}

const ImportantQuestionList = ({ importantQuestions, questions }: Props) => (
    <ul className="QuestionList list-unstyled">
        {questions.map((question, i) => (
            <Question
                key={i}
                type="wish"
                question={question}
                categoryId={importantQuestions[i].categoryId}
                topicId={importantQuestions[i].topicId}
            />
        ))}
        <li className="box-shadow bg-white rounded-borders padding-xy" style={{ marginBottom: 72 }}>
            <NewNewLinkForm />
        </li>
    </ul>
)

export default ImportantQuestionList
