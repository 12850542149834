import usePrivacyPolicy from "../hooks/usePrivacyPolicy"

const PagePolicy = () => {
    const privacyPolicy = usePrivacyPolicy()

    if (!privacyPolicy) {
        return <h5 style={{ textAlign: "center", marginTop: 120 }}>Vent venligst...</h5>
    }

    return <div dangerouslySetInnerHTML={{ __html: privacyPolicy.html }} />
}

export default PagePolicy
