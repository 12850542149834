interface Props {
    displayName?: string
    body: string
}

const CharityOtherIntro = ({ displayName, body }: Props) => (
    <div className="charity-other-intro padding-xy margin-bottom rounded-borders">
        <div className="charity-other-intro__copy">
            <h1>{displayName ?? ""}</h1>
            <p>{body}</p>
        </div>
    </div>
)

export default CharityOtherIntro
