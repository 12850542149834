import { useEffect, useState } from "react"
import { Dialog } from "@mui/material"
import { ArrowForwardRounded } from "@mui/icons-material"

import useDigitalInheritances from "../hooks/useDigitalInheritances"
import Module from "../Module/Module"
import Guide from "../Guide/Guide"

const DigitalInheritance = () => {
    const [guideName, setGuideName] = useState<string>()
    const [guideModalOpen, setGuideModalOpen] = useState(false)

    useEffect(() => {
        if (!guideName) return
        setGuideModalOpen(true)
    }, [guideName])

    const entries = useDigitalInheritances()

    const handleModalClose = () => {
        setGuideModalOpen(false)
        setTimeout(() => {
            setGuideName(undefined)
        }, 400)
    }

    return (
        <>
            <div className="so-me-card-wrapper">
                {entries.map((entry) => (
                    <Module color="white" key={entry.name} handleClick={() => setGuideName(entry.guideName)}>
                        <div className="so-me-card">
                            <img src={entry.logo.url} alt={entry.displayName} />
                            <p>{entry.displayName}</p>
                            <ArrowForwardRounded />
                        </div>
                    </Module>
                ))}
            </div>

            {guideName && (
                <Dialog className="guide-dialog" open={guideModalOpen} onClose={handleModalClose}>
                    <Guide open={guideModalOpen} name={guideName} onModalClose={handleModalClose} />
                </Dialog>
            )}
        </>
    )
}

export default DigitalInheritance
