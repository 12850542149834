import { determineColorFromStatus, determineIconFromStatus, determineTxtFromStatus } from "../../util"

import WithUser from "../hoc/WithUser"
import Module from "../Module/Module"

import icons from "../../assets/icons.svg"
import { useLinkDetails } from "../hooks/useLink"
const pendingIcon = icons + "#pending"
const acceptIcon = icons + "#plusperson"

interface Props {
    link: ILink
    user: any
    url?: string
    onClick?: (link: ILink) => void
}

const LinkPreview = ({ link, user, url, onClick }: Props) => {
    const { isCreator, isPending, fullName, email, image } = useLinkDetails(user.uid, link)

    // Determine text, color and icon from status
    let statusTxt = determineTxtFromStatus(link.status, isCreator)
    let color = determineColorFromStatus(link.status)
    let icon = determineIconFromStatus(link.status, isCreator)

    if (isPending && !isCreator) {
        // If the link is pending and current user is not the creator, the status can only be "pending your accept"
        statusTxt = "Godkend anmodning"
        color = "green"
        icon = acceptIcon
    }

    return (
        <div className="cell link-preview-wrapper" onClick={onClick ? () => onClick(link) : undefined}>
            <Module color={color} url={url}>
                <div className="link-preview">
                    <div>
                        <p>{isPending && isCreator ? email : fullName}</p>
                        <div className="link-preview__status">
                            {link.status !== 4 && (
                                <svg>
                                    <use xlinkHref={icon} />
                                </svg>
                            )}
                            <span>{statusTxt}</span>
                        </div>
                    </div>
                    {isPending ? (
                        <>
                            {isCreator ? (
                                <svg className="link__img">
                                    <use xlinkHref={pendingIcon} />
                                </svg>
                            ) : (
                                <>
                                    {image ? (
                                        <img className="link__img" src={image} alt={fullName} />
                                    ) : (
                                        <h1 className="link__img">{fullName?.[0]}</h1>
                                    )}
                                </>
                            )}
                        </>
                    ) : (
                        <>
                            {image ? (
                                <img className="link__img" src={image} alt={fullName} />
                            ) : (
                                <h1 className="link__img">{fullName?.[0]}</h1>
                            )}
                        </>
                    )}
                </div>
            </Module>
        </div>
    )
}

export default WithUser(LinkPreview)
