import classNames from "classnames"

import WithUser from "../hoc/WithUser"

interface Props {
    user: any
    text: string | HTMLElement
    type?: string
    title?: string
    illustration?: string
    illustrationPad?: boolean
    emph?: string
    color?: string | number
}

const Intro = ({ user, type, title, illustration, text, emph, color }: Props) => {
    let greeting = "Hej"
    if (type === "greeting") {
        const d = new Date()
        const n = d.getHours()
        if (n < 4) {
            greeting = "Godnat"
        } else if (n < 10 && n > 3) {
            greeting = "Godmorgen"
        } else if (n > 9 && n < 12) {
            greeting = "God formiddag"
        } else if (n > 11 && n < 18) {
            greeting = "God eftermiddag"
        } else if (n > 17 && n < 24) {
            greeting = "Godaften"
        }
        greeting = greeting + " " + user.first_name
    }

    const greetingClasses = classNames("Intro rounded-borders padding-xy margin-bottom", {
        "bg-red": color === "red" || color === 0,
        "bg-pink": color === "pink" || color === 1,
        "bg-purple": color === "purple" || color === 2,
        "bg-yellow": color === "yellow" || color === 3,
        "bg-green": color === "green" || color === 4,
        "bg-lightblue": color === "lightblue" || color === 5,
        "bg-lightgray": color === "lightgray" || color === 6,
        "bg-white": color === "white" || color === 8,
        "bg-black": color === "black" || color === 9,
        "bg-blue": color === "blue" || color === 10,
    })

    return (
        <div className={greetingClasses}>
            <div className="Intro__content">
                <h1>
                    {type === "greeting" ? greeting : title}
                    {title === "Testamente" && (
                        <span style={{ fontFamily: "Source Sans Pro", fontSize: 14, marginLeft: 10, opacity: 0.75 }}>
                            beta: 04-01-2022
                        </span>
                    )}
                </h1>
                <p>{text}</p>
                {emph && (
                    <p style={{ marginTop: 6, fontWeight: 600 }}>
                        <em>{emph}</em>
                    </p>
                )}
            </div>
            {illustration && <img className="Intro__illustration" src={illustration} alt={title} />}
        </div>
    )
}

export default WithUser(Intro)
