import { useState } from "react"
import { PrintRounded, HourglassTopRounded } from "@mui/icons-material"

import { cloudfunctions } from "../../firebase"
import downloadFile from "../print/downloadHelper"
import Intro from "../Intro/Intro"
import CharityTopicCard from "./CharityTopicCard"
import TopicCard from "../Topic/TopicCard"
import WithUser from "../hoc/WithUser"

interface Props {
    topics: any[]
    parentTopic?: any
    title?: string
    illustration: string
    illustrationPad?: boolean
    text: string
    color: string
    user: any
}

const TopicPage = ({ title, text, illustration, illustrationPad, color, topics, parentTopic, user }: Props) => {
    const [loading, setLoading] = useState(false)

    const exportWishes = async () => {
        if (loading) return
        setLoading(true)
        // Generate a new pdf and store it in the user's bucket
        await cloudfunctions.httpsCallable("wishes_export")()
        await downloadFile("Mit farvel.pdf", user.uid)
        setLoading(false)
    }

    return (
        <>
            <Intro
                title={title}
                text={text}
                illustration={illustration}
                color={color}
                illustrationPad={illustrationPad}
            />
            <div className="grid-x grid-padding-x--small">
                {topics.map((topic) => (
                    <div key={topic.id} className="cell topic-card-wrapper">
                        <TopicCard topic={topic} parentTopic={parentTopic} />
                    </div>
                ))}
                {title === "Mit farvel" && (
                    <div className="cell topic-card-wrapper">
                        <CharityTopicCard />
                    </div>
                )}
                {title === "Mit farvel" && (
                    <div className="cell topic-card-wrapper" style={{ display: "flex", padding: "15px 30px 30px" }}>
                        <button
                            className="Button Button--icon"
                            style={{ minWidth: 246, margin: "auto" }}
                            disabled={loading}
                            onClick={exportWishes}
                        >
                            {!loading ? (
                                <>
                                    <span>Print dine ønsker</span>
                                    <PrintRounded />
                                </>
                            ) : (
                                <>
                                    <span>Vent venligst</span>
                                    <HourglassTopRounded />
                                </>
                            )}
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default WithUser(TopicPage)
