import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useCharityDonations = (userId?: string): ICharityDonation[] => {
    const [charityDonations, setCharityDonations] = useState<ICharityDonation[]>([])

    useEffect(() => {
        if (!userId) return

        const subscribe = () => {
            firestore
                .collection("users")
                .doc(userId)
                .collection("charityDonations")
                .where("amount", ">", 0)
                .onSnapshot((querySnapshot) => {
                    const charityDonations: ICharityDonation[] = []
                    querySnapshot.forEach((doc) => {
                        charityDonations.push(doc.data() as ICharityDonation)
                    })
                    setCharityDonations(charityDonations)
                })
        }

        return subscribe()
    }, [userId])

    return charityDonations
}

export default useCharityDonations
