import { useEffect, useState } from "react"
import { Link, useRouteMatch } from "react-router-dom"
import { ArrowForwardRounded, BlockRounded } from "@mui/icons-material"

import { updateLinkStatus } from "../../api/LinkManager"

interface Props {
    link: ILink
    name: string
    isCreator: boolean
}

const LinkSharing = ({ link, name, isCreator }: Props) => {
    const match = useRouteMatch()
    const [loading, setLoading] = useState(false)
    const [loadingShareStatus, setLoadingShareStatus] = useState(false)
    const [loggedInUserIsSharing, setLoggedInUserIsSharing] = useState<boolean>()
    const [otherUserIsSharing, setOtherUserIsSharing] = useState<boolean>()

    useEffect(() => {
        if (link.status === 2) {
            setLoggedInUserIsSharing(true)
            setOtherUserIsSharing(true)
        } else {
            if (isCreator) {
                setLoggedInUserIsSharing(link.status === 1)
                setOtherUserIsSharing(link.status === 3)
            } else {
                setLoggedInUserIsSharing(link.status === 3)
                setOtherUserIsSharing(link.status === 1)
            }
        }
    }, [link, isCreator])

    const startSharing = () => {
        if (loading) return
        setLoading(true)
        setLoadingShareStatus(true)

        let newStatus: number
        if (isCreator) {
            if (link.status === 3) {
                newStatus = 2
            } else {
                newStatus = 1
            }
        } else {
            if (link.status === 1) {
                newStatus = 2
            } else {
                newStatus = 3
            }
        }

        updateLinkStatus(link.id, newStatus)
            .then(() => {
                setLoading(false)
                setLoadingShareStatus(false)
            })
            .catch((error) => {
                alert("Noget gik galt... Prøv igen.")
                console.error("Error updating link: ", error)
            })
    }

    const stopSharing = () => {
        if (loading) return
        setLoading(true)
        setLoadingShareStatus(true)

        let newStatus: number
        if (isCreator) {
            if (link.status === 1) {
                newStatus = 4
            } else {
                newStatus = 3
            }
        } else {
            if (link.status === 2) {
                newStatus = 1
            } else {
                newStatus = 4
            }
        }

        updateLinkStatus(link.id, newStatus)
            .then(() => {
                setLoading(false)
                setLoadingShareStatus(false)
            })
            .catch((error) => {
                alert("Noget gik galt... Prøv igen.")
                console.error("Error updating link: ", error)
            })
    }

    return (
        <>
            <div className="cell link-sharing-card-wrapper">
                {otherUserIsSharing ? (
                    <div className="link-sharing-card padding-xy margin-bottom bg-lightblue">
                        <div className="link-sharing-card__title">{name} deler sine ønsker med dig</div>
                        <Link className="Button Button--icon" to={`${match.url}/print`}>
                            <span>Se ønsker</span>
                            <ArrowForwardRounded />
                        </Link>
                    </div>
                ) : (
                    <div className="link-sharing-card link-sharing-card--not-sharing padding-xy margin-bottom bg-white">
                        <div className="link-sharing-card__title">{name} deler ikke sine ønsker med dig</div>
                        <p>Du har ikke mulighed for at se de ønsker {name} har angivet i Adjø.</p>
                    </div>
                )}
            </div>

            <div className="cell link-sharing-card-wrapper">
                {loggedInUserIsSharing ? (
                    <div className="link-sharing-card padding-xy margin-bottom bg-lightblue">
                        <div className="link-sharing-card__title">Du deler dine ønsker med {name}</div>
                        <button
                            className="Button Button--outline Button--icon btn--delete"
                            onClick={stopSharing}
                            style={{ cursor: loading ? "wait" : "pointer" }}
                        >
                            <span>{loadingShareStatus ? "Vent..." : "Stop deling"}</span>
                            <BlockRounded />
                        </button>
                    </div>
                ) : (
                    <div className="link-sharing-card link-sharing-card--not-sharing padding-xy margin-bottom bg-white">
                        <div className="link-sharing-card__title">Du deler ikke dine ønsker med {name}</div>
                        <button
                            className="Button"
                            onClick={startSharing}
                            style={{ cursor: loading ? "wait" : "pointer" }}
                        >
                            <span>{loadingShareStatus ? "Vent..." : "Del ønsker"}</span>
                        </button>
                    </div>
                )}
            </div>
        </>
    )
}

export default LinkSharing
