import { useEffect, useState } from "react"
import { firestore } from "../../firebase"

const getPrivacyPolicy = async (): Promise<IPrivacyPolicy> => {
    const doc = await firestore.collection("privacy_policies").doc("v1").get()
    return doc.data() as IPrivacyPolicy
}

const usePrivacyPolicy = (): IPrivacyPolicy | undefined => {
    const [privacyPolicy, setPrivacyPolicy] = useState<IPrivacyPolicy>()

    useEffect(() => {
        getPrivacyPolicy().then(setPrivacyPolicy)
    }, [])

    return privacyPolicy
}

export default usePrivacyPolicy
