import classNames from "classnames"
import PropTypes from "prop-types"
import { Link } from "react-router-dom"

const Module = (props) => {
    const { color, handleClick, logEvent, url, href, children } = props

    const isPhone = process.env.REACT_APP_IS_PHONE

    const moduleClasses = classNames("Module padding-xy rounded-borders margin-bottom", {
        "bg-red": color === "red" || color === 0,
        "bg-pink": color === "pink" || color === 1,
        "bg-purple": color === "purple" || color === 2,
        "bg-yellow": color === "yellow" || color === 3,
        "bg-green": color === "green" || color === 4,
        "bg-lightblue": color === "lightblue" || color === 5,
        "bg-lightgray": color === "lightgray" || color === 6,
        "bg-white": color === "white" || color === 8,
        "bg-black": color === "black" || color === 9,
        "bg-blue": color === "blue" || color === 10,
        "margin-bottom": !isPhone,
        "card-spacing": isPhone,
    })

    if (href) {
        return (
            <a className={moduleClasses} href={href} target="_blank" rel="noopener noreferrer">
                <div className="Module__inner">{children}</div>
            </a>
        )
    }

    return (
        <>
            {url ? (
                <Link className={moduleClasses} to={url} onClick={logEvent}>
                    <div className="Module__inner">{children}</div>
                </Link>
            ) : (
                <button className={moduleClasses} onClick={handleClick}>
                    <div className="Module__inner">{children}</div>
                </button>
            )}
        </>
    )
}

export default Module

Module.propTypes = {
    url: PropTypes.string,
    handleClick: PropTypes.func,
    logEvent: PropTypes.func,
    color: PropTypes.oneOfType([PropTypes.string, PropTypes.number]).isRequired,
    children: PropTypes.any.isRequired,
}
