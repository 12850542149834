import TopicPage from "../Topic/TopicPage"
import { PUBLIC_BUCKET } from "../../routes/paths"
import WithCategories from "../hoc/WithCategories"

interface Props {
    categories: any[]
}

const PageFarewell = ({ categories }: Props) => (
    <TopicPage
        title="Mit farvel"
        text="Skal dine kære gætte, om du vil begraves eller brændes? Om du vil forlade denne verden i stilhed eller det modsatte? Der er mange spørgsmål, men en ting er sikkert. Du bliver glad for at have taget stilling. Og det gør dine kære også."
        illustration={`${PUBLIC_BUCKET}/illustrations/mit_farvel.png`}
        color="yellow"
        topics={categories}
    />
)

export default WithCategories(PageFarewell)
