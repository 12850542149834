import firebase from "firebase/app"
import "firebase/firestore"
import "firebase/auth"
import "firebase/storage"
import "firebase/functions"
import "firebase/analytics"

const firebaseConfig = {
    apiKey: "AIzaSyDENh9Ox5_hcWBTSbFHCz-4yX-mGkbXwjY",
    authDomain: "adjo-563ec.firebaseapp.com",
    databaseURL: "https://adjo-563ec.firebaseio.com",
    projectId: "adjo-563ec",
    storageBucket: "adjo-563ec.appspot.com",
    messagingSenderId: "405814444524",
    appId: "1:405814444524:web:a28deab9c8b9c5f23380c9",
    measurementId: "G-P5H96JRW1K",
}

firebase.initializeApp(firebaseConfig)
firebase.analytics()

export const firebaseCore = firebase
export const firestore = firebase.firestore()
export const auth = firebase.auth()
export const storage = firebase.storage()
export const cloudfunctions = firebase.app().functions("europe-west3")

export const getUserReference = async (uid) => {
    if (!uid) return null
    try {
        return firestore.collection("users").doc(uid)
    } catch (error) {
        console.error("Error fetching user", error.message)
        return false
    }
}

export const signInWithEmailAndPassword = (email, password) =>
    auth.signInWithEmailAndPassword(email, password).catch(function (error) {
        // Handle Errors here.
        var errorCode = error.code
        return { error: errorCode }
    })

export const signOut = () => {
    auth.signOut()
}

export const forgotPassword = (emailAddress) => auth.sendPasswordResetEmail(emailAddress)

export const sendEmailVerification = () => auth.currentUser.sendEmailVerification()

export const saveWish = (
    userID,
    categoryID,
    topicID,
    questionID,
    optionID,
    txtAnswer,
    isIgnored,
    comment,
    items,
    undertakerID
) => {
    let newAnswer = {}
    let undertakerValue
    if (!undertakerID) {
        newAnswer = {
            optionID: optionID ? optionID : firebase.firestore.FieldValue.delete(),
            txtAnswer: txtAnswer && Object.keys(txtAnswer).length ? txtAnswer : firebase.firestore.FieldValue.delete(),
            isIgnored: isIgnored ? true : firebase.firestore.FieldValue.delete(),
            comment: comment ? comment : firebase.firestore.FieldValue.delete(),
            items: items && items.length > 0 ? items : firebase.firestore.FieldValue.delete(),
        }
    } else {
        newAnswer.isIgnored = firebase.firestore.FieldValue.delete()
        if (comment) {
            newAnswer.comment = comment ? comment : firebase.firestore.FieldValue.delete()
        }
        undertakerValue = undertakerID
    }

    return firestore
        .collection("users")
        .doc(userID)
        .collection("wishes")
        .doc("v1")
        .set(
            {
                [categoryID]: {
                    [topicID]: {
                        [questionID]: newAnswer,
                    },
                },
                ...(undertakerValue && { undertaker: undertakerValue }),
            },
            { merge: true }
        )
}

export const saveLife = (userID, categoryID, questionID, optionID, txtAnswer, isIgnored, comment, items) =>
    firestore
        .collection("users")
        .doc(userID)
        .collection("life")
        .doc("v1")
        .set(
            {
                [categoryID]: {
                    [questionID]: {
                        optionID: optionID ? optionID : firebase.firestore.FieldValue.delete(),
                        txtAnswer:
                            txtAnswer && Object.keys(txtAnswer).length
                                ? txtAnswer
                                : firebase.firestore.FieldValue.delete(),
                        isIgnored: isIgnored ? true : firebase.firestore.FieldValue.delete(),
                        comment: comment ? comment : firebase.firestore.FieldValue.delete(),
                        items: items && items.length > 0 ? items : firebase.firestore.FieldValue.delete(),
                    },
                },
            },
            { merge: true }
        )

export const getAllUndertakers = () => firestore.collection("undertakers").where("isActive", "==", true).get()

export const getUndertaker = (id) => firestore.collection("undertakers").where("id", "==", id).get()

export const deleteUndertaker = () => {
    const user = auth.currentUser
    return firestore
        .doc(`users/${user.uid}/wishes/v1`)
        .set({ undertaker: firebase.firestore.FieldValue.delete() }, { merge: true })
}
