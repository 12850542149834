import { Dialog } from "@mui/material"

interface Props {
    title: string
    body?: string
    hideCancel?: boolean
    isOpen: boolean
    handleClose: () => void
    children: any
}

const Prompt = ({ title, body, hideCancel, isOpen, handleClose, children }: Props) => (
    <Dialog className="prompt" open={isOpen} onClose={handleClose}>
        <h2>{title}</h2>
        {body && <p className="prompt__body">{body}</p>}
        <div className="prompt__action">
            {children}
            {!hideCancel && (
                <button autoFocus type="button" className="Button Button--flat" onClick={handleClose}>
                    Fortryd
                </button>
            )}
        </div>
    </Dialog>
)

export default Prompt
