import { DescriptionRounded } from "@mui/icons-material"

import NavItem from "./NavItem"
import * as PATHS from "../../routes/paths"

const NavItemsMain = () => (
    <ul className="list-unstyled">
        <li>
            <NavItem url={PATHS.HOME} icon="home" title="Hjem" isExact color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.BYE} icon="suitcase" title="Mit farvel" color="yellow" />
        </li>
        <li>
            <NavItem url={PATHS.LIFE} icon="flower" title="Mit liv" color="pink" />
        </li>
        <li>
            <NavItem url={PATHS.LINKS} icon="heart" title="Mine kære" color="purple" />
        </li>
        <li>
            <NavItem
                url={PATHS.TESTAMENT}
                icon="inheritance"
                title="Min arv"
                color="green"
                isActive={(match, location) => location.pathname.includes(PATHS.INHERITANCE)}
            />
        </li>
        <li>
            <NavItem url={PATHS.PRINT} icon="print" title="Mine dokumenter" color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.PRODUCTS} title="Produkter" color="lightblue">
                <DescriptionRounded />
            </NavItem>
        </li>
    </ul>
)

export default NavItemsMain
