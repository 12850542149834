import { useEffect, useState } from "react"

import { firestore } from "../../firebase"

const useLatestPrints = (userID: string, linkID?: string): ILatestPDFExports | undefined => {
    const [latestPrints, setLatestPrints] = useState<ILatestPDFExports>()

    useEffect(() => {
        if (!userID) return

        const subscribe = () =>
            firestore
                .collection("users")
                .doc(userID)
                .collection("pdfExports")
                .doc("latest")
                .onSnapshot((doc) => {
                    const data = doc.data()
                    if (!data) return
                    const latestPDFExports: ILatestPDFExports = {
                        ...(data.wishes && { wishes: data.wishes.toDate() }),
                        ...(data.life && { life: data.life.toDate() }),
                        ...(data.heirlooms && { heirlooms: data.heirlooms.toDate() }),
                    }
                    setLatestPrints(latestPDFExports)
                })

        const subscribeOther = () =>
            firestore
                .collection("users")
                .doc(userID)
                .collection("pdfExports_requestOther")
                .where("linkID", "==", linkID)
                .onSnapshot((querySnapshot) => {
                    const pdfExports: IPDFExport[] = []
                    querySnapshot.forEach((doc) => {
                        pdfExports.push(doc.data() as IPDFExport)
                    })

                    const wishes = pdfExports
                        .filter((pe) => pe.type === "wishes")
                        .sort((a, b) => +b.requestDate - +a.requestDate)[0]?.requestDate

                    const life = pdfExports
                        .filter((pe) => pe.type === "life")
                        .sort((a, b) => +b.requestDate - +a.requestDate)[0]?.requestDate

                    const heirlooms = pdfExports
                        .filter((pe) => pe.type === "heirlooms")
                        .sort((a, b) => +b.requestDate - +a.requestDate)[0]?.requestDate

                    const latestPDFExports: ILatestPDFExports = {
                        ...(wishes && { wishes: wishes.toDate() }),
                        ...(life && { life: life.toDate() }),
                        ...(heirlooms && { heirlooms: heirlooms.toDate() }),
                    }
                    setLatestPrints(latestPDFExports)
                })

        return linkID ? subscribeOther() : subscribe()
    }, [userID, linkID])

    return latestPrints
}

export default useLatestPrints
