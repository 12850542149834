import { Route, Redirect } from "react-router-dom"
import * as ROUTES from "./paths"
import Dashboard from "../components/Dashboard/Dashboard"

interface Props {
    authenticated: boolean
    exact?: boolean
    path: string
    children: any
}

const ProtectedRoute = ({ authenticated, children, ...rest }: Props) => (
    <Route
        {...rest}
        render={({ location }) =>
            authenticated ? (
                <Dashboard>{children}</Dashboard>
            ) : (
                <Redirect
                    to={{
                        pathname: ROUTES.SIGN_IN,
                        state: { from: location },
                    }}
                />
            )
        }
    />
)

export default ProtectedRoute
