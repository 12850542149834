import { Component } from "react"
import { FormControl, InputLabel, MenuItem, Select, TextField } from "@mui/material"
import firebase from "firebase/app"

import { firestore } from "../../firebase"
import WithUser from "../hoc/WithUser"

class ProfileForm extends Component {
    constructor(props) {
        super(props)
        const { email, photo, uid, ...initialState } = props.user
        this.isPhone = process.env.REACT_APP_IS_PHONE

        let sex = ""
        if (initialState.sex !== undefined) {
            sex = initialState.sex
        }

        this.initialState = {
            ...initialState,
            sex,
        }

        this.state = this.initialState
    }

    handleNameChange = (event) => this.setState({ [event.target.name]: event.target.value })

    handleSexChange = (event) => this.setState({ sex: event.target.value })

    handleBirthYearChange = (event) => {
        const value = event.target.value

        if (value.length > 4) {
            return
        }

        const intValue = parseInt(value, 10)

        if (isNaN(intValue)) {
            this.setState({ birth_year: "" })
            return
        }

        this.setState({ birth_year: intValue })
    }

    handlePostcodeChange = (event) => {
        const value = event.target.value

        if (value.length > 4) {
            return
        }

        const intValue = parseInt(value, 10)

        if (isNaN(intValue)) {
            this.setState({ postcode: "" })
            return
        }

        if (intValue < 0 || intValue > 9999) {
            return
        }

        this.setState({ postcode: value })
    }

    handleSubmit = async (event) => {
        event.preventDefault()
        if (!this.hasChanged()) {
            return
        }

        const birthYearValue = Number.isInteger(this.state.birth_year)
            ? this.state.birth_year
            : firebase.firestore.FieldValue.delete()

        let sexValue = this.state.sex
        if (this.state.sex === "") {
            sexValue = firebase.firestore.FieldValue.delete()
        }

        const postcodeValue = this.state.postcode || firebase.firestore.FieldValue.delete()

        let data = {
            ...this.state,
            birth_year: birthYearValue,
            postcode: postcodeValue,
            sex: sexValue,
        }

        this.initialState = this.state
        await firestore.collection("users").doc(this.props.user.uid).update(data)
    }

    hasChanged = () => JSON.stringify(this.initialState) !== JSON.stringify(this.state)

    render() {
        return (
            <form
                className={`profile__form${this.isPhone ? " profile__form--phone" : ""}`}
                onSubmit={this.handleSubmit}
                autoComplete="off"
            >
                <TextField
                    variant="outlined"
                    required
                    value={this.state.first_name}
                    autoCapitalize="words"
                    autoComplete="given-name"
                    label="Fornavn"
                    onChange={this.handleNameChange}
                    name="first_name"
                    type="text"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    required
                    value={this.state.surname}
                    autoCapitalize="words"
                    autoComplete="family-name"
                    label="Efternavn"
                    onChange={this.handleNameChange}
                    name="surname"
                    type="text"
                    margin="normal"
                    fullWidth
                />
                <TextField
                    variant="outlined"
                    value={this.state.birth_year || ""}
                    label="Fødselsår"
                    onChange={this.handleBirthYearChange}
                    name="birth_year"
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[1|2][0|9][0-9][0-9]",
                    }}
                    margin="normal"
                    fullWidth
                />
                <FormControl variant="outlined" margin="normal" fullWidth>
                    <InputLabel id="sex-select-label">Køn</InputLabel>
                    <Select
                        labelId="sex-select-label"
                        id="sex-select"
                        value={this.state.sex}
                        label="Sex"
                        onChange={this.handleSexChange}
                    >
                        <MenuItem value={""}>—</MenuItem>
                        <MenuItem value={0}>Kvinde</MenuItem>
                        <MenuItem value={1}>Mand</MenuItem>
                    </Select>
                </FormControl>{" "}
                <TextField
                    variant="outlined"
                    value={this.state.postcode || ""}
                    label="Postnummer"
                    onChange={this.handlePostcodeChange}
                    name="postcode"
                    inputProps={{
                        inputMode: "numeric",
                        pattern: "[1-9][0-9][0-9][0-9]",
                    }}
                    margin="normal"
                    fullWidth
                    helperText="1000 – 9999"
                />
                <input disabled={!this.hasChanged()} className="Button" type="submit" value="Gem ændringer" />
            </form>
        )
    }
}

export default WithUser(ProfileForm)
