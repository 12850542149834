import DrawerItem from "./DrawerItem"
import * as PATHS from "../../routes/paths"

const Drawer = () => {
    const closeSidebar = () => {
        const sidebar = document.getElementById("Sidebar")
        if (sidebar!.classList.contains("Sidebar--open")) {
            sidebar!.classList.remove("Sidebar--open")
        }

        const header = document.getElementById("Header")
        if (header!.classList.contains("Sidebar--open")) {
            header!.classList.remove("Sidebar--open")
        }
    }

    return (
        <nav className="Nav" onClick={closeSidebar}>
            <ul className="list-unstyled">
                <li>
                    <DrawerItem url={PATHS.PROFILE} icon="user" title="Min profil" />
                </li>
                <li>
                    <DrawerItem url={PATHS.PRINT} icon="print" title="Mine dokumenter" />
                </li>
                <li>
                    <DrawerItem url={PATHS.ABOUT} icon="about" title="Om Adjø" />
                </li>
                <li>
                    <DrawerItem url={PATHS.PARTNERS} icon="partners" title="Partnerskaber" />
                </li>
                <li>
                    <DrawerItem url={PATHS.COLLABS} icon="collabs" title="Samarbejder" />
                </li>
                <li>
                    <DrawerItem url={PATHS.POLICY} icon="policy" title="Privatlivspolitik" />
                </li>
                <li>
                    <DrawerItem url={PATHS.TRADING_TERMS} icon="trading-terms" title="Handelsbetingelser" />
                </li>
                <li>
                    <DrawerItem url={PATHS.FEEDBACK} icon="feedback" title="Kontakt os" />
                </li>
            </ul>
        </nav>
    )
}

export default Drawer
