import icons from "./assets/icons.svg"
const dotsIcon = icons + "#dots"
const shareBothIcon = icons + "#share_both"
const shareTheyIcon = icons + "#share_they"
const shareYouIcon = icons + "#share_you"
const deleteIcon = icons + "#delete"

export const slugify = (string) => {
    const a = "àáâäæãåāăąçćčđďèéêëēėęěğǵḧîïíīįìłḿñńǹňôöòóœøōõőṕŕřßśšşșťțûüùúūǘůűųẃẍÿýžźż·/_,:;"
    const b = "aaaaaaaaaacccddeeeeeeeegghiiiiiilmnnnnoooooooooprrsssssttuuuuuuuuuwxyyzzz------"
    const p = new RegExp(a.split("").join("|"), "g")

    return string
        .toString()
        .toLowerCase()
        .replace(/\s+/g, "-") // Replace spaces with -
        .replace(p, (c) => b.charAt(a.indexOf(c))) // Replace special characters
        .replace(/&/g, "-and-") // Replace & with 'and'
        .replace(/[^\w-]+/g, "") // Remove all non-word characters
        .replace(/--+/g, "-") // Replace multiple - with single -
        .replace(/^-+/, "") // Trim - from start of text
        .replace(/-+$/, "") // Trim - from end of text
}

export const determineColorFromStatus = (status) => {
    switch (status) {
        case 0:
            return "yellow"
        case 1:
            return "lightgray"
        case 2:
            return "lightblue"
        case 3:
            return "lightgray"
        case 4:
            return "lightgray"
        default:
            return "yellow"
    }
}

export const determineIconFromStatus = (status, isCreator) => {
    switch (status) {
        case 0:
            return dotsIcon
        case 1:
            return isCreator ? shareYouIcon : shareTheyIcon
        case 2:
            return shareBothIcon
        case 3:
            return isCreator ? shareTheyIcon : shareYouIcon
        case 4:
            return deleteIcon
        default:
            return shareBothIcon
    }
}

export const determineTxtFromStatus = (status, isCreator) => {
    switch (status) {
        case 0:
            return "Afventer svar"
        case 1:
            if (isCreator) {
                return "Kun dig som deler"
            } else {
                return "Kun ham/hende som deler"
            }
        case 2:
            return "Deler med hinanden"
        case 3:
            if (isCreator) {
                return "Kun ham/hende som deler"
            } else {
                return "Kun dig som deler"
            }
        case 4:
            return "Ingen af jer deler"
        default:
            return "Afventer svar"
    }
}
