import { BrowserRouter as Router, Switch, Route, Redirect } from "react-router-dom"

import * as PATHS from "./paths"
import ProtectedRoute from "./ProtectedRoute"
import WithUser from "../components/hoc/WithUser"

import ScrollToTop from "../components/ScrollToTop/ScrollToTop"
import PageHome from "../components/Home/PageHome"
import PageFarewell from "../components/pages/PageFarewell"
import PageLife from "../components/pages/PageLife"
import PageLinks from "../components/pages/PageLinks"
import LinkDetail from "../components/Links/LinkDetail"
import PageInheritance from "../components/pages/PageInheritance"
import ParentTopic from "../components/Topic/ParentTopic"
import Topic from "../components/Topic/Topic"
import TopicLife from "../components/Topic/TopicLife"
import PageProfile from "../components/pages/PageProfile"
import PagePrint from "../components/print/PagePrint"
import PageAbout from "../components/pages/PageAbout"
import PagePartners from "../components/pages/PagePartners"
import PageCollabs from "../components/pages/PageCollabs"
import PagePolicy from "../components/pages/PagePolicy"
import PageTradingTerms from "../components/pages/PageTradingTerms"
import PageFeedback from "../components/pages/PageFeedback"
import PageImportant from "../components/pages/PageImportant"
import PageQuestionnaireQuestion from "../components/questionnaire/PageQuestionnaireQuestion"
import PageQuestionnaireResult from "../components/questionnaire/PageQuestionnaireResult"
import { ProvideQuestionnaireRoute } from "../providers/useQuestionnaireRoute"
import PageLegal from "../components/legal/PageLegal"
import HeirloomDetail from "../components/Heirlooms/HeirloomDetail"
import HeirloomForm from "../components/Heirlooms/HeirloomForm"
import PageCharities from "../components/pages/PageCharities"
import CharityDetail from "../components/Charity/CharityDetail"
import CharityOtherAdd from "../components/Charity/CharityOtherAdd"
import PageQ11eStart from "../components/Q11e/PageQ11eStart"
import PageQ11eQuestion from "../components/Q11e/PageQ11eQuestion"
import PageDocument from "../components/Documents/PageDocument"
import PageBuy from "../components/Documents/PageBuy"
import PageLogin from "../components/pages/PageLogin/PageLogin"
import PageSignUp from "../components/pages/PageSignUp/PageSignUp"
import PageForgotPassword from "../components/pages/PageForgotPassword/PageForgotPassword"
import PageProducts from "../components/pages/PageProducts"

interface Props {
    user?: any
}

const AllRoutes = ({ user }: Props) => {
    const authenticated = !!user

    return (
        <Router>
            <ScrollToTop />
            <Switch>
                {/* Public routes */}
                <Route
                    exact
                    path={PATHS.SIGN_IN}
                    render={(props) => <PageLogin {...props} loggedIn={authenticated} />}
                />
                <Route
                    exact
                    path={PATHS.SIGN_UP}
                    render={(props) => <PageSignUp {...props} loggedIn={authenticated} />}
                />
                <Route exact path={PATHS.FORGOT_PASSWORD} component={PageForgotPassword} />

                {/* Private routes */}
                <ProtectedRoute exact path={PATHS.HOME} authenticated={authenticated}>
                    <PageHome />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.BYE} authenticated={authenticated}>
                    <PageFarewell />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.LIFE} authenticated={authenticated}>
                    <PageLife />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.LINK_PRINT}>
                    <PagePrint />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.LINK}>
                    <LinkDetail />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.LINKS}>
                    <PageLinks />
                </ProtectedRoute>
                <ProtectedRoute
                    authenticated={authenticated}
                    path={`${PATHS.DOCUMENT}/:documentName/:documentVersion/køb`}
                >
                    <PageBuy />
                </ProtectedRoute>
                <ProtectedRoute
                authenticated={authenticated}
                path={`${PATHS.DOCUMENT}/:documentName/:documentVersion/:extraParam/køb`}
            >
                <PageBuy />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={`${PATHS.DOCUMENT}/:documentName/:documentVersion`}>
                    <PageDocument />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={`${PATHS.FREMTIDSFULDMAGT}/:questionId`}>
                    <PageQ11eQuestion />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.FREMTIDSFULDMAGT}>
                    <PageQ11eStart />
                </ProtectedRoute>
                <ProtectedRoute
                    authenticated={authenticated}
                    path={`${PATHS.QUESTIONNAIRE}/:questionnaireId/:questionId`}
                >
                    <ProvideQuestionnaireRoute userId={user?.uid}>
                        <PageQuestionnaireQuestion />
                    </ProvideQuestionnaireRoute>
                </ProtectedRoute>
                <ProtectedRoute
                    authenticated={authenticated}
                    exact
                    path={`${PATHS.QUESTIONNAIRE_RESULT}/:questionnaireId`}
                >
                    <ProvideQuestionnaireRoute userId={user?.uid}>
                        <PageQuestionnaireResult />
                    </ProvideQuestionnaireRoute>
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={`${PATHS.LEGAL}/:name`}>
                    <PageLegal />
                </ProtectedRoute>

                <ProtectedRoute exact path={PATHS.PROFILE} authenticated={authenticated}>
                    <PageProfile />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.PRINT} authenticated={authenticated}>
                    <PagePrint />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.ABOUT} authenticated={authenticated}>
                    <PageAbout />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.PARTNERS} authenticated={authenticated}>
                    <PagePartners />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.COLLABS} authenticated={authenticated}>
                    <PageCollabs />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.POLICY} authenticated={authenticated}>
                    <PagePolicy />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.TRADING_TERMS} authenticated={authenticated}>
                    <PageTradingTerms />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.FEEDBACK} authenticated={authenticated}>
                    <PageFeedback />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.IMPORTANT_QUESTIONS} authenticated={authenticated}>
                    <PageImportant />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.HEIRLOOM_CREATE}>
                    <HeirloomForm />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.HEIRLOOM_EDIT}>
                    <HeirloomForm />
                </ProtectedRoute>
                <ProtectedRoute authenticated={authenticated} path={PATHS.HEIRLOOM}>
                    <HeirloomDetail />
                </ProtectedRoute>
                <ProtectedRoute path={PATHS.INHERITANCE} authenticated={authenticated}>
                    <PageInheritance />
                </ProtectedRoute>
                <ProtectedRoute path={PATHS.PRODUCTS} authenticated={authenticated}>
                    <PageProducts />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.CHARITIES} authenticated={authenticated}>
                    <PageCharities />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.CHARITIES_OTHER} authenticated={authenticated}>
                    <CharityOtherAdd />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.CHARITY_OTHER} authenticated={authenticated}>
                    <CharityOtherAdd />
                </ProtectedRoute>
                <ProtectedRoute exact path={PATHS.CHARITY} authenticated={authenticated}>
                    <CharityDetail />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${PATHS.BYE}:parentTopicParam/`} authenticated={authenticated}>
                    <ParentTopic />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${PATHS.BYE}:parentTopicParam/:topicParam`} authenticated={authenticated}>
                    <Topic />
                </ProtectedRoute>
                <ProtectedRoute exact path={`${PATHS.LIFE}:topicParam`} authenticated={authenticated}>
                    <TopicLife />
                </ProtectedRoute>
                {/* 404 */}
                <Route render={() => <Redirect to={"/"} />} />
            </Switch>
        </Router>
    )
}

export default WithUser(AllRoutes)
