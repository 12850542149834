import { useState } from "react"
import { Dialog } from "@mui/material"
import { ExploreRounded } from "@mui/icons-material"

import Module from "../Module/Module"
import Guide from "../Guide/Guide"

const GuideItem = () => {
    const [guideModalOpen, setGuideModalOpen] = useState(false)

    const handelOpen = () => setGuideModalOpen(true)
    const handleClose = () => setGuideModalOpen(false)

    return (
        <>
            <div className="cell print-item">
                <Module color="purple" handleClick={handelOpen}>
                    <div className="legal-card">
                        <div className="legal-card__title-row">
                            <h4 className="legal-card__title-row__title">Guide til tinglysning og notar</h4>
                            <ExploreRounded />
                        </div>
                    </div>
                </Module>
            </div>
            <Dialog className="guide-dialog" open={guideModalOpen} onClose={handleClose}>
                <Guide open={guideModalOpen} name="fremtidsfuldmagt_post_purchase" onModalClose={handleClose} />
            </Dialog>
        </>
    )
}

export default GuideItem
