export const BASE_URL = "https://graphql.datocms.com/"

const READ_ONLY_API_TOKEN = "77527c9be057b36dd7366b25a5a0ad"

export const REQUEST_INIT = {
    method: "POST",
    headers: {
        "Content-Type": "application/json",
        Accept: "application/json",
        Authorization: `Bearer ${READ_ONLY_API_TOKEN}`,
    },
}

export const MODEL_GUIDE = "guide"
export const MODEL_ALL_GUIDES = "allGuides"

export const MODEL_LEGAL = "legal"
export const MODEL_ALL_LEGALS = "allLegals"

export const MODEL_DIGITAL_INHERITANCE = "digital_inheritance"
export const MODEL_ALL_DIGITAL_INHERITANCES = "allDigitalInheritances"

export const MODEL_CHARITY = "charity"
export const MODEL_ALL_CHARITIES = "allCharities"
