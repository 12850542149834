import { Link, useParams } from "react-router-dom"
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material"

import { slugify } from "../../util"

import WithCategoriesLife from "../hoc/WithCategoriesLife"
import Intro from "../Intro/Intro"
import QuestionList from "../Questions/QuestionList"
import { LIFE, PUBLIC_BUCKET } from "../../routes/paths"

interface Params {
    topicParam: string
}

interface Props {
    categoriesLife: any[]
}

const TopicLife = ({ categoriesLife }: Props) => {
    const { topicParam } = useParams<Params>()
    const topic = categoriesLife.find((category) => slugify(category.title) === topicParam)

    // Prev / next topic
    const indexOfTopic = categoriesLife.indexOf(topic)
    const previousTopic = categoriesLife[indexOfTopic - 1]
    const nextTopic = categoriesLife[indexOfTopic + 1]

    const slug = LIFE
    const prevTopicLink = previousTopic ? slug + slugify(previousTopic.title) : slug
    const prevTopicTxt = previousTopic ? "Tilbage" : "Til oversigten"

    const nextTopicLink = nextTopic ? slug + slugify(nextTopic.title) : slug
    const nextTopicTxt = nextTopic ? "Næste emne" : "Til oversigten"

    return (
        <>
            {topic ? (
                <>
                    <Intro
                        title={topic.title}
                        text={topic.desc}
                        illustration={`${PUBLIC_BUCKET}/scribbles/svg/scribble_${topic.img}.svg`}
                        illustrationPad
                        color={"pink"}
                    />
                    <QuestionList questions={topic.questions} categoryId={topic.id} />
                    <div className="Topic__nav">
                        <Link className="Topic__nav--prev Button Button--outline Button--icon" to={prevTopicLink}>
                            <ArrowBackRounded style={{ fontSize: 30 }} />
                            <span>{prevTopicTxt}</span>
                        </Link>
                        <Link className="Topic__nav--next Button Button--icon" to={nextTopicLink}>
                            <span>{nextTopicTxt}</span>
                            <ArrowForwardRounded style={{ fontSize: 30 }} />
                        </Link>
                    </div>
                </>
            ) : (
                "Siden kunne ikke findes..."
            )}
        </>
    )
}

export default WithCategoriesLife(TopicLife)
