import { Link } from "react-router-dom"

import icons from "../../assets/icons.svg"

const imageIcon = icons + "#image"

interface Props {
    heirloom: IHeirloom
    url: string
}

const HeirloomPreview = ({ heirloom, url }: Props) => (
    <li className="cell small-12 medium-6 large-4">
        <Link to={url} className="heirloom-preview margin-bottom bg-white">
            {heirloom.photo_url_str ? (
                <img className="heirloom-preview__image" src={heirloom.photo_url_str} alt={heirloom.description} />
            ) : (
                <svg className="heirloom-preview__image">
                    <use xlinkHref={imageIcon} />
                </svg>
            )}
            <h4 className="heirloom-preview__title">{heirloom.description}</h4>
        </Link>
    </li>
)

export default HeirloomPreview
