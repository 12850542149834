import { useEffect, useState } from "react"

const useTotalCharityDonationAmount = (
    publishedCharities: IPublishedCharity[],
    charityDonations: ICharityDonation[],
    otherCharities: ICharityOther[]
): number => {
    const [amount, setAmount] = useState<number>(0)

    useEffect(() => {
        let amount = charityDonations
            .filter((charityDonation) =>
                publishedCharities.find((publishedCharity) => publishedCharity.name === charityDonation.charityName)
            )
            .reduce((acc, cur) => acc + cur.amount, 0)

        amount += otherCharities.reduce((acc, cur) => acc + cur.amount, 0)

        setAmount(amount)
    }, [publishedCharities, charityDonations, otherCharities])

    return amount
}

export default useTotalCharityDonationAmount
