import { firestore } from "../firebase"

export const getQuestionnaires = async (): Promise<IQuestionnaire[]> => {
    const querySnaps = await firestore.collection("questionnaires").where("active", "==", true).get()

    const questionnaires: IQuestionnaire[] = []
    querySnaps.forEach((querySnap) => {
        const questionnaire = querySnap.data() as IQuestionnaire
        questionnaires.push(questionnaire)
    })

    return questionnaires
}

export const getQuestion = async (questionnaireId: string, questionId: string): Promise<IQuestion | undefined> => {
    try {
        const docSnap = await firestore
            .collection("questionnaires")
            .doc(questionnaireId)
            .collection("questions")
            .doc(questionId)
            .get()

        if (docSnap.exists) {
            return docSnap.data() as IQuestion
        }
    } catch (e) {
        console.error(e)
    }
}

export const getAnswers = async (questionnaireId: string, questionId: string): Promise<IAnswer[]> => {
    try {
        const docSnaps = await firestore
            .collection("questionnaires")
            .doc(questionnaireId)
            .collection("answers")
            .where("sourceQuestionId", "==", questionId)
            .get()

        let answers: IAnswer[] = []
        docSnaps.forEach((docSnap) => {
            if (docSnap.exists) {
                answers.push(docSnap.data() as IAnswer)
            }
        })
        return answers
    } catch (e) {
        console.error(e)
    }

    return []
}
