import NavItem from "./NavItem"
import * as PATHS from "../../routes/paths"

const NavItemsMore = () => (
    <ul className="list-unstyled">
        <li>
            <NavItem url={PATHS.PROFILE} icon="user" title="Min profil" isExact color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.ABOUT} icon="about" title="Om Adjø" color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.PARTNERS} icon="partners" title="Partnerskaber" color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.COLLABS} icon="collabs" title="Samarbejder" color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.POLICY} icon="policy" title="Privatlivspolitik" color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.TRADING_TERMS} icon="trading-terms" title="Handelsbetingelser" color="lightblue" />
        </li>
        <li>
            <NavItem url={PATHS.FEEDBACK} icon="feedback" title="Kontakt os" color="lightblue" />
        </li>
    </ul>
)

export default NavItemsMore
