import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"
import { BORNETESTAMENTE_NAME, FREMTIDSFULDMAGT_NAME, GIFTCARD_NAME } from "./constants"

interface IQ11eLegalDocs {
    [FREMTIDSFULDMAGT_NAME]: Array<IQ11eDoc>
    [BORNETESTAMENTE_NAME]: Array<IQ11eDoc>
    [GIFTCARD_NAME]: Array<IQ11eDoc>
}

const useDocumentsForPrint = (uid: string) => {
    const [documents, setDocuments] = useState<IQ11eLegalDocs>()
    const [documentsLoading, setDocumentsLoading] = useState(true)

    useEffect(() => {
        const userDocs = firestore.collection("user_documents").doc(uid)

        const result = {
            [FREMTIDSFULDMAGT_NAME]: [] as Array<IQ11eDoc>,
            [BORNETESTAMENTE_NAME]: [] as Array<IQ11eDoc>,
            [GIFTCARD_NAME]: [] as Array<IQ11eDoc>,
        }

        userDocs
            .collection(FREMTIDSFULDMAGT_NAME)
            .orderBy("payment.date", "desc")
            .get()
            .then((querySnap) => {
                querySnap.forEach((queryDocSnap) => {
                    const document = queryDocSnap.data() as IQ11eDoc
                    result[FREMTIDSFULDMAGT_NAME].push(document)
                })
            })
            .then(() => {
                userDocs
                    .collection(BORNETESTAMENTE_NAME)
                    .orderBy("payment.date", "desc")
                    .get()
                    .then((querySnap) => {
                        querySnap.forEach((queryDocSnap) => {
                            const document = queryDocSnap.data() as IQ11eDoc
                            result[BORNETESTAMENTE_NAME].push(document)
                        })
                    })
            })
            .then(() => {
                userDocs
                    .collection(GIFTCARD_NAME)
                    .orderBy("payment.date", "desc")
                    .get()
                    .then((querySnap) => {
                        querySnap.forEach((queryDocSnap) => {
                            const document = queryDocSnap.data() as IQ11eDoc
                            result[GIFTCARD_NAME].push(document)
                        })
                    })
                    .then(() => {
                        setDocuments(result)
                        setDocumentsLoading(false)
                    })
            })

    }, [uid])

    return { documents, documentsLoading }
}

export default useDocumentsForPrint
