import { useHistory } from "react-router-dom"
import { ArrowBackRounded } from "@mui/icons-material"

import useCharityPreviews from "../hooks/Charity/useCharityPreviews"
import useCharityDonations from "../hooks/Charity/useCharityDonations"
import useOtherCharities from "../hooks/Charity/useOtherCharities"
import { BYE } from "../../routes/paths"
import Intro from "../Intro/Intro"
import CharityPreview from "../Charity/CharityPreview"
import CharityOtherPreview from "../Charity/CharityOtherPreview"
import CharityPreviewAddOther from "../Charity/CharityPreviewAddOther"
import WithUser from "../hoc/WithUser"

interface Props {
    user: any
}

export const PageCharities = ({ user }: Props) => {
    const history = useHistory()
    const charityPreviews = useCharityPreviews()
    const charityDonations = useCharityDonations(user.uid)
    const otherCharities = useOtherCharities(user.uid)

    const resolveDonation = (charityName: string) => charityDonations.find((cd) => cd.charityName === charityName)

    return (
        <>
            <button className="la-fab margin-bottom" onClick={() => history.replace(BYE)}>
                <ArrowBackRounded />
            </button>
            <Intro
                title="Velgørenhed"
                text="Har du gjort dig overvejelser om at betænke velgørenhed med en donation? Er der en sag der betyder særligt meget for dig? Her kan du betænke dele af din formue til velgørende organisationer."
                color="white"
            />
            <div className="grid-x grid-padding-x--small">
                {charityPreviews.map((preview) => (
                    <CharityPreview key={preview.name} preview={preview} donation={resolveDonation(preview.name)} />
                ))}
                {otherCharities.map((charity) => (
                    <CharityOtherPreview key={charity.name} charity={charity} />
                ))}
                <CharityPreviewAddOther />
            </div>
        </>
    )
}

export default WithUser(PageCharities)
