import PropTypes from "prop-types"

import icons from "../../assets/icons.svg"
const checkmarkIcon = icons + "#checkmark"
const circleIcon = icons + "#circle"

const FormInputCheckbox = (props) => {
    const { name, isChecked, onChange, label, headline, link, required } = props

    const indicatorIcon = isChecked ? checkmarkIcon : circleIcon

    return (
        <div className="Form__inputwrapper Form__checkbox">
            {headline && link && (
                <p>
                    <a href={link} target="_blank" rel="noopener noreferrer">
                        {headline}
                    </a>
                </p>
            )}
            <input
                type="checkbox"
                name={name}
                id={name}
                checked={isChecked ? true : false}
                onChange={onChange}
                required={required}
            />
            <label htmlFor={name}>
                <span>{label}</span>
                <svg>
                    <use xlinkHref={indicatorIcon} />
                </svg>
            </label>
        </div>
    )
}

export default FormInputCheckbox

FormInputCheckbox.propTypes = {
    name: PropTypes.string.isRequired,
    isChecked: PropTypes.bool.isRequired,
    onChange: PropTypes.func.isRequired,
    label: PropTypes.string.isRequired,
    headline: PropTypes.string,
    link: PropTypes.string,
    required: PropTypes.bool,
}
