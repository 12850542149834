export const getInitialCrop = (img) => {
    // Center the crop
    const shortSide = Math.min(img.width, img.height)

    let x, y
    if (shortSide === img.width) {
        // vertical image
        x = 0
        y = (img.height - shortSide) / 2
    } else {
        // horizontal image
        x = (img.width - shortSide) / 2
        y = 0
    }

    return [x, y, shortSide]
}

export const getCroppedImg = (img, crop, fileName) => {
    const canvas = document.createElement("canvas")
    const scaleX = img.naturalWidth / img.width
    const scaleY = img.naturalHeight / img.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext("2d")

    ctx.drawImage(
        img,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height
    )

    return new Promise((resolve) => {
        canvas.toBlob((blob) => {
            blob.name = fileName
            resolve(blob)
        }, "image/jpeg")
    })
}
