import { BASE_URL, REQUEST_INIT, MODEL_ALL_CHARITIES, MODEL_CHARITY } from "./datocms"

export const getPublishedCharities = async (): Promise<IPublishedCharity[]> => {
    const query = `{ ${MODEL_ALL_CHARITIES} { name } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            return res.data?.[MODEL_ALL_CHARITIES] as IPublishedCharity[]
        })
        .catch((error) => {
            console.log(error)
            return []
        })
}

export const getCharityPreviews = async (): Promise<ICharityPreview[]> => {
    const query = `{ ${MODEL_ALL_CHARITIES} { name displayName logo { url } sortOrder } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            const charities = res.data?.[MODEL_ALL_CHARITIES] as ICharityPreview[]
            return charities?.sort((a, b) => (a.sortOrder < b.sortOrder ? -1 : a.sortOrder > b.sortOrder ? 1 : 0)) || []
        })
        .catch((error) => {
            console.log(error)
            return []
        })
}

export const getCharity = async (name: string): Promise<ICharity | undefined> => {
    const query = `{ ${MODEL_CHARITY}(filter: {name: {eq: "${name}"}}) { name displayName description url logo { url } } }`

    return fetch(BASE_URL, {
        ...REQUEST_INIT,
        body: JSON.stringify({ query }),
    })
        .then((res) => res.json())
        .then((res) => {
            return res.data?.[MODEL_CHARITY] as ICharity
        })
        .catch((error) => {
            console.log(error)
            return undefined
        })
}
