import { useState, useEffect, useRef } from "react"
import { Dialog } from "@mui/material"
import { EditRounded } from "@mui/icons-material"

import WithUser from "../hoc/WithUser"

import icons from "../../assets/icons.svg"
import EditProfileImg from "../Profile/EditProfileImg"
import ProfilePhotoActions from "../Profile/ProfilePhotoActions"

const placeholder = icons + "#user"

const ProfileIntro = (props) => {
    const [isShowingPhotoActions, setShowingPhotoActions] = useState(false)
    const [src, setSrc] = useState(undefined)
    useEffect(() => {
        if (src || isShowingPhotoActions) {
            setModalOpen(true)
        }
    }, [src, isShowingPhotoActions])

    const [isModalOpen, setModalOpen] = useState(false)
    useEffect(() => {
        if (!isModalOpen) {
            setTimeout(() => {
                setSrc(undefined)
                setShowingPhotoActions(false)
            }, 250)
        }
    }, [isModalOpen])

    const inputEl = useRef(null)

    const { user } = props
    const { photo, email } = user

    const closeModal = () => {
        setModalOpen(false)
    }

    const editImgClicked = () => {
        setShowingPhotoActions(true)
    }

    const newPhotoClicked = () => {
        closeModal()
        inputEl.current.value = ""
        inputEl.current.click()
    }

    const onSelectFile = (event) => {
        const file = event.target.files?.[0]

        if (file) {
            const reader = new FileReader()
            reader.addEventListener("load", () => setSrc(reader.result))
            reader.readAsDataURL(file)
        }
    }

    let img
    if (photo) {
        img = <img className="intro__img" src={photo} alt={photo} />
    } else {
        img = (
            <svg className="intro__img intro__img--placeholder">
                <use xlinkHref={placeholder} />
            </svg>
        )
    }

    return (
        <>
            <div className="intro padding-xy margin-bottom rounded-borders">
                <div className="intro__left">
                    <h1>Min profil</h1>
                    <p className="has-big-font-size">{email}</p>
                </div>
                <div className="intro__img-wrapper">
                    {img}
                    <button title="Redigér dit profil-foto" onClick={photo ? editImgClicked : newPhotoClicked}>
                        <EditRounded />
                    </button>
                    <input
                        hidden
                        type="file"
                        accept="image/x-png,image/jpeg,image/jpg"
                        ref={inputEl}
                        onChange={onSelectFile}
                    />
                </div>
            </div>
            {(src || isShowingPhotoActions) && (
                <Dialog open={isModalOpen} onClose={closeModal}>
                    <div className="la-modal">
                        {(isShowingPhotoActions && (
                            <ProfilePhotoActions user={props.user} onCancel={closeModal} onChange={newPhotoClicked} />
                        )) ||
                            (src && <EditProfileImg user={props.user} src={src} onSubmitted={closeModal} />)}
                    </div>
                </Dialog>
            )}
        </>
    )
}

export default WithUser(ProfileIntro)
