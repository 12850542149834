import { createTheme } from "@mui/material/styles"

export const theme = createTheme({
    palette: {
        primary: {
            main: "#1C1C1C",
        },
        secondary: {
            main: "#C9BFE2",
        },
    },
    typography: {
        fontFamily: ["Source Sans Pro", "sans-serif"].join(","),
        fontSize: 16,
        body1: {
            color: "#1C1C1C",
        },
    },
    components: {
        MuiDialog: {
            styleOverrides: {
                paper: {
                    borderRadius: 18,
                    margin: 16,
                },
            },
        },
    },
})
