import Logo from "../Logo/Logo"
import Nav from "../Nav/Nav"
import Drawer from "../Drawer/Drawer"

const Sidebar = () => {
    const isPhone = process.env.REACT_APP_IS_PHONE

    return (
        <aside className="Sidebar" id="Sidebar">
            <Logo />
            {(isPhone && <Drawer />) || <Nav />}
        </aside>
    )
}

export default Sidebar
