import { signOut } from "../../firebase"

const LogOutModal = ({ onCancel }) => (
    <div className="la-modal">
        <h2>Log ud?</h2>
        <p>{"Dine data slettes ikke.\nDu kan altid logge ind fra en anden enhed og fortsætte med at bruge Adjø."}</p>
        <div className="la-modal__actions">
            <button autoFocus className="Button Button--flat" aria-labelledby="close-modal" onClick={onCancel}>
                Fortryd
            </button>
            <button className="Button" onClick={signOut}>
                Log ud
            </button>
        </div>
    </div>
)

export default LogOutModal
