import { Component, createContext } from "react"
import { firestore } from "../firebase"

import WithUser from "../components/hoc/WithUser"

export const HeirloomsContext = createContext({ heirlooms: [], loading: true })

class HeirloomsProvider extends Component {
    state = {
        heirlooms: [],
        heirloomsFetched: false,
        loading: false,
    }

    unsubscribeFromHeirlooms = null

    componentDidMount() {
        if (!this.props.user) {
            return
        }
        this.listenForHeirlooms(this.props.user)
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { user } = this.props
        if (!user) {
            if (this.unsubscribeFromHeirlooms) {
                this.unsubscribeFromHeirlooms()
                this.unsubscribeFromHeirlooms = null
                this.setState({
                    heirlooms: [],
                    heirloomsFetched: false,
                })
            }
        } else if (!this.state.heirloomsFetched) {
            this.listenForHeirlooms(user)
        }
    }

    listenForHeirlooms({ uid }) {
        this.setState({ heirloomsFetched: true })
        this.unsubscribeFromHeirlooms = firestore.collection(`users/${uid}/heirlooms`).onSnapshot((snapshot) => {
            const heirlooms = []
            snapshot.forEach((doc) => {
                const heirloom = doc.data()
                heirloom.id = doc.id
                heirlooms.push(heirloom)
            })
            this.setState({ heirlooms, loading: false })
        })
    }

    render() {
        const { heirlooms, loading } = this.state
        const { children } = this.props

        return (
            <HeirloomsContext.Provider
                value={{
                    heirlooms: heirlooms,
                    loading: loading,
                }}
            >
                {children}
            </HeirloomsContext.Provider>
        )
    }
}

export default WithUser(HeirloomsProvider)
