import { firestore } from "../firebase"

export const getCollabs = async (): Promise<ICollab[]> => {
    const querySnaps = await firestore.collection("undertakers_compact").get()

    const collabs: ICollab[] = []
    querySnaps.forEach((querySnap) => {
        const collab = querySnap.data() as ICollab
        collabs.push(collab)
    })

    return collabs.sort((a, b) => (a.name < b.name ? -1 : a.name > b.name ? 1 : 0))
}
