import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"

import { getAnswers, getQuestion } from "../../api/QuestionnaireContentManager"
import QuestionnaireAnswerRadio from "./QuestionnaireAnswerRadio"
import QuestionnaireNav from "./QuestionnaireNav"
import { useQuestionnaireRoute } from "../../providers/useQuestionnaireRoute"
import InlineLoader from "../Loader/InlineLoader"

interface Params {
    questionnaireId: string
    questionId: string
}

const PageQuestionnaireQuestion = () => {
    const { questionnaireId, questionId } = useParams<Params>()
    const { routes } = useQuestionnaireRoute()
    const [question, setQuestion] = useState<IQuestion>()
    const [answers, setAnswers] = useState<IAnswer[]>()
    const [selectedAnswer, setSelectedAnswer] = useState<IAnswer>()

    useEffect(() => {
        getQuestion(questionnaireId, questionId).then((question) => {
            setQuestion(question)
            getAnswers(questionnaireId, questionId).then(setAnswers)
        })
    }, [questionnaireId, questionId])

    useEffect(() => {
        if (!answers) return
        const selectedAnswerId = routes?.[questionnaireId]?.[questionId]
        if (selectedAnswerId) {
            const answer = answers.find((a) => a.id === selectedAnswerId)
            setSelectedAnswer(answer)
        } else {
            setSelectedAnswer(undefined)
        }
    }, [questionnaireId, questionId, routes, answers])

    if (!question || !answers) return <InlineLoader />

    return (
        <div className="questionnaire-question">
            <h3 className="questionnaire-question__title">{question.txt}</h3>
            {answers.map((answer) => (
                <QuestionnaireAnswerRadio key={answer.id} answer={answer} selected={selectedAnswer?.id === answer.id} />
            ))}
            <QuestionnaireNav question={question} answer={selectedAnswer} />
        </div>
    )
}

export default PageQuestionnaireQuestion
