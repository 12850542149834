import PropTypes from "prop-types"

const FormInputTextarea = (props) => {
    const { name, onChange, value, placeholder } = props

    return <textarea name={name} defaultValue={value} onChange={onChange} placeholder={placeholder}></textarea>
}

export default FormInputTextarea

FormInputTextarea.propTypes = {
    name: PropTypes.string.isRequired,
    onChange: PropTypes.func.isRequired,
    value: PropTypes.string.isRequired,
    placeholder: PropTypes.string.isRequired,
}
