import { useEffect, useState } from "react"

import { getEntries } from "../../api/DigitalInheritanceManager"

const useDigitalInheritances = (): IDigitalInheritance[] => {
    const [digitalInheritances, setDigitalInheritances] = useState<IDigitalInheritance[]>([])

    useEffect(() => {
        getEntries().then(setDigitalInheritances)
    }, [])

    return digitalInheritances
}

export default useDigitalInheritances
