import TabBarItem from "../TabBar/TabBarItem"
import * as PATHS from "../../routes/paths"

export const TabBar = () => (
    <nav className="TabBar" id="TabBar">
        <TabBarItem url={PATHS.HOME} icon="home" title="Hjem" isExact />
        <TabBarItem url={PATHS.BYE} icon="suitcase" title="Mit farvel" />
        <TabBarItem url={PATHS.LIFE} icon="flower" title="Mit liv" />
        <TabBarItem url={PATHS.LINKS} icon="heart" title="Mine kære" />
        <TabBarItem
            url={PATHS.TESTAMENT}
            icon="inheritance"
            title="Min arv"
            isActive={(match, location) => location.pathname.includes(PATHS.INHERITANCE)}
        />
    </nav>
)

export default TabBar
