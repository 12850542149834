import useCollabs from "../hooks/useCollabs"

const PageCollabs = () => {
    const collabs = useCollabs()

    return (
        <>
            <h2>Samarbejder</h2>
            {collabs.map((collab) => (
                <div key={collab.id} className="collab">
                    <div className="collab__txt">{collab.name}</div>
                    <img className="collab__img" src={collab.img} alt={collab.img} />
                </div>
            ))}
        </>
    )
}

export default PageCollabs
