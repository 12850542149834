import { useEffect, useState } from "react"

import { getQ11ePreview } from "../../../api/Q11eContentManager"
import { GIFTCARD_NAME, GIFTCARD_VERSION } from "./constants"

const useGiftCardPreview = (): IQ11ePreview | undefined => {
    const [preview, setPreview] = useState<IQ11ePreview>()

    useEffect(() => {
        getQ11ePreview(GIFTCARD_NAME, GIFTCARD_VERSION).then(setPreview)
    }, [])

    return preview
}

export default useGiftCardPreview
