import PropTypes from "prop-types"
import classNames from "classnames"

import { slugify } from "../../util"
import Module from "../Module/Module"

import WithWishes from "../hoc/WithWishes"
import WithLife from "../hoc/WithLife"
import { PUBLIC_BUCKET } from "../../routes/paths"

const TopicCard = (props) => {
    const { parentTopic, topic, wishes, life } = props

    const isPhone = process.env.REACT_APP_IS_PHONE

    let answerCount = 0
    let questionCount = 0

    const category = parentTopic // rename
    if (category) {
        /** Topics */
        questionCount += topic.questionsCount
        for (const question of topic.questions) {
            const answer = wishes[category.id]?.[topic.id]?.[question.id]
            answerCount += isQuestionAnswered(question, answer) ? 1 : 0
        }
    } else {
        if (topic.topics) {
            /** Categories */
            const category = topic // rename
            for (const topic of category.topics) {
                questionCount += topic.questionsCount
                for (const question of topic.questions) {
                    const answer = wishes[category.id]?.[topic.id]?.[question.id]
                    answerCount += isQuestionAnswered(question, answer) ? 1 : 0
                }
            }
        } else {
            /** Life */
            questionCount += topic.questionsCount
            for (const question of topic.questions) {
                const answer = life[topic.id]?.[question.id]
                answerCount += isQuestionAnswered(question, answer) ? 1 : 0
            }
        }
    }

    function isQuestionAnswered(question, answer) {
        if (question.isUndertaker && wishes.undertaker) {
            return true
        }

        /** Is `answer` a non-empty object? */
        if (answer && Object.keys(answer).length) {
            if (answer.optionID) {
                return true
            } else if (answer.txtAnswer?.isChecked) {
                return true
            } else if (answer.items?.length) {
                return true
            } else if (answer.isIgnored) {
                return true
            } else if (question.noAdd) {
                if (question.isAux) {
                    return false
                } else if (answer.comment?.length) {
                    return true
                }
            }
        }

        return false
    }

    const indicatorClasses = classNames({
        "TopicCard__indicator--phone": isPhone,
        TopicCard__indicator: !isPhone,
        "rounded-borders": !isPhone,
        "bg-purple": answerCount === 0,
        "bg-yellow": answerCount > 0,
        "bg-green": answerCount === questionCount,
    })

    return (
        <Module url={slugify(topic.title) + "/"} color="white">
            <div className="TopicCard">
                <div className="TopicCard__content">
                    <h3 className={isPhone && "h3--phone"}>{topic.title}</h3>
                    <div className={indicatorClasses}>
                        <span>
                            {answerCount} / {questionCount} udfyldt
                        </span>
                    </div>
                </div>
                <figure className="TopicCard__illustration">
                    <img src={`${PUBLIC_BUCKET}/scribbles/svg/scribble_${topic.img}.svg`} alt="" />
                </figure>
            </div>
        </Module>
    )
}

export default WithLife(WithWishes(TopicCard))

TopicCard.propTypes = {
    parentTopic: PropTypes.any,
    topic: PropTypes.any.isRequired,
    wishes: PropTypes.any.isRequired,
    life: PropTypes.any.isRequired,
}
