import { firestore } from "../firebase"

export const getPartners = async (): Promise<IPartner[]> => {
    const querySnaps = await firestore.collection("partners_v1").where("active", "==", true).get()

    const partners: IPartner[] = []
    querySnaps.forEach((querySnap) => {
        const partner = querySnap.data() as IPartner
        partners.push(partner)
    })

    return partners
}
