import { useEffect, useState } from "react"

import { cloudfunctions } from "../../../firebase"

const useQ11eQuestionDisplays = (q11eName: string, q11eVersion: string, q11eRouteState?: IQ11eRouteState) => {
    const [questionDisplays, setQuestionDisplays] = useState<IQ11eQuestionDisplays>()

    useEffect(() => {
        if (!q11eRouteState) return
        const questionIds = Object.keys(q11eRouteState)
        const q11eId = q11eName + "_" + q11eVersion
        cloudfunctions
            .httpsCallable("getQuestionDisplaysForResult ")({ q11eId, questionIds })
            .then((result) => setQuestionDisplays(result.data))
    }, [q11eName, q11eVersion, q11eRouteState])

    return questionDisplays
}

export default useQ11eQuestionDisplays
