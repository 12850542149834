import { useEffect, useState } from "react"
import { Typography } from "@mui/material"
import { ArrowForwardRounded } from "@mui/icons-material"

import { PUBLIC_BUCKET } from "../../routes/paths"
import Module from "../Module/Module"
import { getPartners } from "../../api/PartnersManager"

const PagePartners = () => {
    const [partners, setPartners] = useState<IPartner[]>([])

    useEffect(() => {
        getPartners().then(setPartners)
    }, [])

    return (
        <>
            <h2>Partnerskaber</h2>
            {partners.map((partner) => (
                <Module color="white" key={partner.id}>
                    <div className="partner">
                        <img
                            className="partner__img"
                            src={`${PUBLIC_BUCKET}/partners/${partner.img}`}
                            alt={partner.name}
                        />
                        <Typography className="partner__desc" paragraph>
                            {partner.desc}
                        </Typography>
                        <a
                            className="Button Button--outline Button--icon"
                            href={partner.url}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            <span>Læs mere</span>
                            <ArrowForwardRounded style={{ fontSize: 30 }} />
                        </a>
                    </div>
                </Module>
            ))}
        </>
    )
}

export default PagePartners
