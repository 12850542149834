import Intro from "../Intro/Intro"
import LinkList from "../Links/LinkList"
import { PUBLIC_BUCKET } from "../../routes/paths"

const PageLinks = () => (
    <div>
        <Intro
            title="Mine kære"
            text="Adjø er til os alle, da vi alle skal dø en dag. Men måske vigtigst for dine pårørende. De svar, du giver her, kan blive meget værdifulde for dem, der betyder mest for dig."
            illustration={`${PUBLIC_BUCKET}/illustrations/mine_kære.png`}
            color="purple"
        />
        <LinkList />
    </div>
)

export default PageLinks
