import { useState } from "react"
import { useSnackbar } from "notistack"

import WithUser from "../hoc/WithUser"
import useDocumentsForPrint from "../hooks/Q11e/useDocumentsForPrint"
import PrintItem from "./PrintItem"
import GuideItem from "./GuideItem"
import { Bought } from "./styles"
import {
    BORNETESTAMENTE_DISPLAY_NAME,
    BORNETESTAMENTE_NAME,
    BORNETESTAMENTE_VERSION,
    FREMTIDSFULDMAGT_DISPLAY_NAME,
    FREMTIDSFULDMAGT_NAME,
    FREMTIDSFULDMAGT_VERSION,
    GIFTCARD_DISPLAY_NAME,
    GIFTCARD_NAME,
    GIFTCARD_VERSION,
} from "../hooks/Q11e/constants"
import { cloudfunctions } from "../../firebase"
import downloadFile from "./downloadHelper"

interface Props {
    user: { uid: string }
}

const LegalDocuments = ({ user }: Props) => {
    const [downloading, setDownloading] = useState<string>()
    const { documents, documentsLoading } = useDocumentsForPrint(user.uid)
    const { enqueueSnackbar } = useSnackbar()

    const handleClick = (documentName: string, documentId: string) => () => {
        let documentVersion = -1
        let fileName = ""
        if (documentName === FREMTIDSFULDMAGT_NAME) {
            documentVersion = FREMTIDSFULDMAGT_VERSION
            fileName = FREMTIDSFULDMAGT_DISPLAY_NAME + ".pdf"
        } else if (documentName === BORNETESTAMENTE_NAME) {
            documentVersion = BORNETESTAMENTE_VERSION
            fileName = BORNETESTAMENTE_DISPLAY_NAME + ".pdf"
        } else if (documentName === GIFTCARD_NAME) {
            documentVersion = GIFTCARD_VERSION
            fileName = GIFTCARD_DISPLAY_NAME + ".pdf"
        }

        download(documentName, documentVersion, documentId, fileName)
    }

    const download = async (documentName: string, documentVersion: number, documentId: string, fileName: string) => {
        if (downloading) return
        setDownloading(documentId)
        try {
            await cloudfunctions.httpsCallable("printFremtidsfuldmagt_v4")({
                documentName,
                documentVersion,
                documentId,
            })
            await downloadFile(fileName, user.uid)
            enqueueSnackbar(`Dokumentet "${fileName}" er downloadet.`)
        } catch (error) {
            enqueueSnackbar("Kunne ikke downloade dokumentet. Prøv igen senere.")
        }
        setDownloading(undefined)
                
    }

    if (
        documentsLoading ||
        !documents ||
        documents[FREMTIDSFULDMAGT_NAME].length + documents[BORNETESTAMENTE_NAME].length + documents[GIFTCARD_NAME].length === 0
    ) {
        return null
    }

    return (
        <>
            <Bought variant="body2" align="center">
                Købte dokumenter
            </Bought>
            <div className="grid-x grid-padding-x--small">
                {documents[FREMTIDSFULDMAGT_NAME].map((document) =>
                    <PrintItem
                        key={document.id}
                        color="white"
                        subtitle={(document.answers.q1 as IQ11eRouteStateTextAnswer).textAnswer}
                        title="Fremtidsfuldmagt"
                        date={(document.payment.date as any).toDate()}
                        loading={downloading === document.id}
                        onClick={handleClick(FREMTIDSFULDMAGT_NAME, document.id)}
                    />
                )}
                <GuideItem />
                {documents[BORNETESTAMENTE_NAME].map((document) => (
                    <PrintItem
                        key={document.id}
                        color="white"
                        title="Børnetestamente"
                        date={(document.payment.date as any).toDate()}
                        loading={downloading === document.id}
                        onClick={handleClick(BORNETESTAMENTE_NAME, document.id)}
                    />
                ))}
                {documents[GIFTCARD_NAME].map((document) => (
                    <PrintItem
                        key={document.id}
                        color="blue"
                        subtitle={(document.answers.q1 as IQ11eRouteStateRadioAnswer).radioAnswerTxt}
                        title="Gavekort"
                        date={(document.payment.date as any).toDate()}
                        loading={downloading === document.id}
                        onClick={handleClick(GIFTCARD_NAME, document.id)}
                    />
                ))}
            </div>
        </>
    )
}

export default WithUser(LegalDocuments)
