import { firestore, storage } from "../firebase"

/**
 * @param {string} userID - The user's ID
 * @param {(string | undefined)} heirloomID - An optional ID of an existing heirloom document
 * @return {DocumentReference} A reference to either a new or an existing heirloom document
 */
export const getOrCreateHeirloomDocRef = (userID: string, heirloomID?: string) =>
    heirloomID
        ? firestore.doc(`users/${userID}/heirlooms/${heirloomID}`)
        : firestore.collection(`users/${userID}/heirlooms`).doc()

export const createHeirloomStorageRef = (userID: string, heirloomID: string) =>
    storage.ref().child(`users/${userID}/heirlooms/${heirloomID}.jpg`)

export const getHeirloom = async (userID: string, heirloomID: string): Promise<IHeirloom> => {
    const docSnap = await firestore.collection("users").doc(userID).collection("heirlooms").doc(heirloomID).get()

    return docSnap.data() as IHeirloom
}

export const deleteHeirloom = (userID: string, heirloomID: string) =>
    firestore.doc(`users/${userID}/heirlooms/${heirloomID}`).delete()
