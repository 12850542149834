const PageAbout = () => (
    <>
        <h2>Om Adjø</h2>
        <p>Du skal dø.</p>
        <p>
            Ja, det er måske lidt direkte. Og vi er da også kede af at skulle sige det. Men der er altså ingen vej
            udenom. Du skal dø. På et eller andet tidspunkt. Og nej. Du har sikkert ikke lyst til at skulle tale om det
            lige nu. Det er der ingen, der har. Men det er jo netop dér, hunden ligger begravet. So to speak.
        </p>
        <p>
            For meget få af os taler om døden. Altså før det er for sent. Og det er en skam. Lidt mere åbenhed ville
            nemlig også gøre det lidt nemmere at tage afsked. Med ro i sindet. Og orden i sagerne. De sager som ikke
            længere er dine. Men andres.
        </p>
        <p>
            For hvem skal arve hvad? Og hvorfor? Vil du i jorden? Ilden? Eller sættes fri og lade vinden bestemme, hvor
            du ender. Og hvad med din sidste fest? Skal den være som begravelser er flest. Eller går du ud med et brag.
        </p>
        <p>Beslutningen er din. Og så alligevel ikke.</p>
        <p>
            For hvis du ikke fortæller nogen, hvad du ønsker, så bliver det aldrig til mere end det. Et ønske. Og det er
            jo netop derfor, det er så vigtigt at tale om døden. Så du kan få styr på tingene og sagt ordentligt farvel…
            på gensyn… adjø… so long… arrivederci… goodbye-rut… ses på grillen… hasta la vista, baby… Eller hvad du nu
            finder på.
        </p>
        <p>
            Faktum er nemlig, at der er lige så mange måder at sige farvel på, som der er mennesker på denne jord. Så få
            det nu gjort.
        </p>
        <p>Det behøver ikke være sværere end et simpelt...</p>
        <p>Adjø!</p>
    </>
)

export default PageAbout
