import { useEffect, useState } from "react"
import { getCharity } from "../../../api/CharityManager"

const useCharity = (name: string): ICharity | undefined => {
    const [charity, setCharity] = useState<ICharity>()

    useEffect(() => {
        getCharity(name).then(setCharity)
    }, [name])

    return charity
}

export default useCharity
