import { Component } from "react"
import { TextField } from "@mui/material"

import { sendEmailVerification, cloudfunctions } from "../../firebase"

import LinkSent from "./LinkSent"
import VerificationEmailSent from "../Profile/VerificationEmailSent"

class NewNewLinkForm extends Component {
    state = {
        name: "",
        email: "",
        loading: false,
        verificationEmailSent: false,
        createdLink: undefined,
        err: undefined,
    }

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value })

    handleSubmit = async (event) => {
        event.preventDefault()

        if (this.state.loading || this.state.createdLink) {
            return
        }

        this.setState({ loading: true, createdLink: undefined, err: undefined })

        try {
            const response = await cloudfunctions.httpsCallable("link_create_v5")({
                inviteeEmail: this.state.email,
                inviteeName: this.state.name,
            })

            this.setState({
                name: "",
                email: "",
                createdLink: response.data,
            })
        } catch (err) {
            this.setState({ err: err.details })
        } finally {
            this.setState({ loading: false })
        }
    }

    handleCancel = () => {
        window.history.back()
    }

    sendEmailVerificationClicked = async () => {
        this.setState({ loading: true })
        await sendEmailVerification()
        this.setState({ loading: false, verificationEmailSent: true })
    }

    render() {
        const { loading, name, email, createdLink, verificationEmailSent, err } = this.state

        if (verificationEmailSent) {
            return <VerificationEmailSent handleClick={this.handleCancel} />
        } else if (createdLink) {
            return <LinkSent email={createdLink.invitee_email} handleClick={this.handleCancel} />
        } else if (err) {
            return (
                <>
                    <h3>{err.title}</h3>
                    <p>{err.msg}</p>
                    {err.title === "Godkend din e-mail" && (
                        <div className="info">
                            <button disabled={loading} onClick={this.sendEmailVerificationClicked}>
                                {loading ? "Vent..." : "Klik her hvis du ikke har fået nogen e-mail"}
                            </button>
                        </div>
                    )}
                    <div className="la-modal__actions">
                        <button
                            className="Button"
                            style={{ marginLeft: "auto" }}
                            disabled={loading}
                            onClick={() => this.setState({ err: undefined })}
                        >
                            OK
                        </button>
                    </div>
                </>
            )
        }

        return (
            <form className="la-form" onSubmit={this.handleSubmit}>
                <h3>Del dine ønsker med dine kære</h3>
                <p>
                    Du er allerede godt i gang med Adjø.
                    <br />
                    Næste skridt er at lade dine kære kende dine ønsker. Start med at invitere den første person du vil
                    dele med.
                </p>
                <TextField
                    variant="outlined"
                    required
                    value={name}
                    onChange={this.handleChange}
                    label="Personens navn"
                    name="name"
                    type="text"
                    margin="dense"
                />
                <TextField
                    variant="outlined"
                    required
                    value={email}
                    autoComplete="email"
                    onChange={this.handleChange}
                    label="Personens e-mail"
                    name="email"
                    type="email"
                    margin="normal"
                />
                <div className="la-modal__actions" style={{ marginBottom: 0 }}>
                    <button
                        type="button"
                        className="Button Button--flat"
                        aria-labelledby="close-modal"
                        onClick={this.handleCancel}
                    >
                        Spring over
                    </button>
                    <input
                        type="submit"
                        className="Button"
                        value={loading ? "Vent..." : "Send invitation"}
                        disabled={loading}
                        style={{ cursor: loading ? "wait" : "pointer" }}
                    />
                </div>
            </form>
        )
    }
}

export default NewNewLinkForm
