import { useEffect, useState } from "react"

import { getQ11ePreview } from "../../../api/Q11eContentManager"
import { BORNETESTAMENTE_NAME, BORNETESTAMENTE_VERSION } from "./constants"

const useBornetestamentePreview = (): IQ11ePreview | undefined => {
    const [preview, setPreview] = useState<IQ11ePreview>()

    useEffect(() => {
        getQ11ePreview(BORNETESTAMENTE_NAME, BORNETESTAMENTE_VERSION).then(setPreview)
    }, [])

    return preview
}

export default useBornetestamentePreview
