export const FREMTIDSFULDMAGT_NAME = "fremtidsfuldmagt"
export const FREMTIDSFULDMAGT_VERSION = 4
export const FREMTIDSFULDMAGT_DISPLAY_NAME = "Fremtidsfuldmagt"

export const BORNETESTAMENTE_NAME = "bornetestamente"
export const BORNETESTAMENTE_VERSION = 1
export const BORNETESTAMENTE_DISPLAY_NAME = "Børnetestamente"

export const TESTAMENTE_NAME = "testamente"
export const TESTAMENTE_VERSION = 1

export const GIFTCARD_NAME = "giftcard"
export const GIFTCARD_VERSION = 1
export const GIFTCARD_DISPLAY_NAME = "Gavekort"