import { useEffect, useState } from "react"
import { ThemeProvider } from "@mui/material/styles"
import { SnackbarProvider } from "notistack"
import * as serviceWorkerRegistration from "../../serviceWorkerRegistration"

import Prompt from "../dialogs/Prompt"
import Compose from "./Compose"
import Loader from "../Loader/Loader"
import Routes from "../../routes/Routes"
import UserProvider from "../../providers/UserProvider"
import CategoriesProvider from "../../providers/CategoriesProvider"
import CategoriesLifeProvider from "../../providers/CategoriesLifeProvider"
import WishesProvider from "../../providers/WishesProvider"
import LifeProvider from "../../providers/LifeProvider"
import LinksProvider from "../../providers/LinksProvider"
import HeirloomsProvider from "../../providers/HeirloomsProvider"
import { theme } from "../../styles/theme"

const Application = () => {
    const [registration, setRegistration] = useState<ServiceWorkerRegistration>()
    const [alertOpen, setAlertOpen] = useState(false)
    const [isUpdating, setIsUpdating] = useState(false)

    useEffect(() => {
        if (process.env.NODE_ENV === "development") {
            document.body.classList.add("development")
        }
    }, [])

    useEffect(() => {
        serviceWorkerRegistration.register({ onUpdate: setRegistration })
    }, [])

    useEffect(() => {
        setAlertOpen(!!registration)
    }, [registration])

    const updateServiceWorker = () => {
        setIsUpdating(true)
        const registrationWaiting = registration!.waiting
        if (registrationWaiting) {
            registrationWaiting.addEventListener("statechange", function () {
                if (this.state === "activated") {
                    window.location.reload()
                }
            })
            registrationWaiting.postMessage({ type: "SKIP_WAITING" })
        }
    }

    return (
        <div className="Application">
            <ThemeProvider theme={theme}>
                <SnackbarProvider anchorOrigin={{ vertical: "bottom", horizontal: "center" }}>
                    <Compose
                        components={[
                            UserProvider,
                            CategoriesProvider,
                            CategoriesLifeProvider,
                            WishesProvider,
                            LifeProvider,
                            LinksProvider,
                            HeirloomsProvider,
                        ]}
                    >
                        <Loader>
                            <Routes />
                        </Loader>
                    </Compose>
                </SnackbarProvider>
            </ThemeProvider>
            {registration && (
                <Prompt
                    title="Opdatering tilgængelig"
                    body="En ny version af Adjø er tilgængelig. Klik OK for at aktivere den med det samme."
                    hideCancel={true}
                    isOpen={alertOpen}
                    handleClose={() => setAlertOpen(false)}
                >
                    <button className="Button" disabled={isUpdating} onClick={updateServiceWorker}>
                        {isUpdating ? "Vent..." : "OK"}
                    </button>
                </Prompt>
            )}
        </div>
    )
}

export default Application
