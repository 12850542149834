import { Component, createContext } from "react"
import { auth, getUserReference } from "../firebase"

import Loading from "../components/Loader/Loading"

export const UserContext = createContext({ user: null })

class UserProvider extends Component {
    state = {
        user: null,
        loading: true,
    }

    unsubscribeFromAuth = null
    unsubscribeFromUser = null

    componentDidMount = async () => {
        this.unsubscribeFromAuth = auth.onAuthStateChanged(async (authUser) => {
            if (authUser) {
                const userRef = await getUserReference(authUser.uid)
                // Subscribe to user
                this.unsubscribeFromUser = userRef.onSnapshot((snapshot) => {
                    this.setState({
                        user: {
                            uid: snapshot.id,
                            email: authUser.email,
                            ...snapshot.data(),
                        },
                        loading: false,
                    })
                })
            } else {
                if (this.unsubscribeFromUser) {
                    this.unsubscribeFromUser()
                }
                this.setState({ loading: false, user: null })
            }
        })
    }

    componentWillUnmount = () => {
        if (this.unsubscribeFromAuth) {
            this.unsubscribeFromAuth()
        }
    }

    render() {
        const { user } = this.state
        const { children } = this.props
        return (
            <>
                {this.state.loading ? (
                    <Loading />
                ) : (
                    <UserContext.Provider value={user}>{children}</UserContext.Provider>
                )}
            </>
        )
    }
}

export default UserProvider
