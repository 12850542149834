import { useState, useEffect } from "react"
import { Dialog } from "@mui/material"

import { firestore } from "../../firebase"
import WithUser from "../hoc/WithUser"
import WithWishes from "../hoc/WithWishes"
import WithCategories from "../hoc/WithCategories"
import Intro from "../Intro/Intro"
import Module from "../Module/Module"
import HomeCard from "./HomeCard"
import { getImportantQuestions, shouldDisplayCard } from "../../api/ImportantQuestionsManager"
import * as PATHS from "../../routes/paths"
import { ImportantQuestions } from "../../model/ImportantQuestion"

interface Props {
    user: any
    categories: any
    wishes: any
}

const PageHome = ({ user, categories, wishes }: Props) => {
    const [modalOpen, setModalOpen] = useState(false)
    const [loading, setLoading] = useState(false)
    const [importantQuestions, setImportantQuestions] = useState<ImportantQuestions>()

    useEffect(() => {
        if (!user["referrer"]) {
            setModalOpen(true)
        }
    }, [user])

    useEffect(() => {
        getImportantQuestions().then((iqs) => {
            if (!iqs) return
            if (shouldDisplayCard(iqs.data, categories, wishes)) {
                setImportantQuestions(iqs)
            }
        })
    }, [categories, wishes])

    const handleSubmit = async (referrer: number) => {
        if (loading) return
        setLoading(true)
        await firestore.collection("users").doc(user.uid).update({ referrer: referrer })
        setLoading(false)
        setModalOpen(false)
    }

    return (
        <>
            <Intro
                type="greeting"
                text="Dine informationer er dine. Adjø værner og beskytter dine informationer og deler dem ikke med tredjemand. Kun i tilfælde af at du i afsnittet Mine Kære, aktivt har bedt om at dele dine informationer, vil dine udvalgte kunne se dem."
                illustration={`${PATHS.PUBLIC_BUCKET}/illustrations/ill/ill_reading_no_wall.png`}
                color="white"
            />
            <div className="list">
                {importantQuestions && (
                    <Module url="/important-questions/" color={importantQuestions.display.color}>
                        <HomeCard
                            title={importantQuestions.display.title}
                            text={importantQuestions.display.body}
                            btnText={importantQuestions.display.btn}
                            illustration={`${PATHS.PUBLIC_BUCKET}/illustrations/ill/${importantQuestions.display.img}.png`}
                        />
                    </Module>
                )}
                <Module url={PATHS.BYE} color="yellow">
                    <HomeCard
                        title="Mit farvel"
                        text="Har du tænkt over, hvordan din begravelse skal være?"
                        btnText="Planlæg dit farvel"
                        illustration={`${PATHS.PUBLIC_BUCKET}/illustrations/mit_farvel.png`}
                    />
                </Module>
                <Module url={PATHS.LIFE} color="pink">
                    <HomeCard
                        title="Mit liv"
                        btnText="Udfyld oplysninger"
                        text="Kan du gøre det lettere i en svær tid?"
                        illustration={`${PATHS.PUBLIC_BUCKET}/illustrations/mit_liv_v2.png`}
                    />
                </Module>
                <Module url={PATHS.LINKS} color="purple">
                    <HomeCard
                        title="Mine kære"
                        text="Hvem skal kende dine ønsker, når du er væk?"
                        btnText="Del dine ønsker"
                        illustration={`${PATHS.PUBLIC_BUCKET}/illustrations/mine_kære.png`}
                    />
                </Module>
                <Module url={PATHS.TESTAMENT} color="green">
                    <HomeCard
                        title="Min arv"
                        text="Hvad efterlader du dine efterladte med?"
                        btnText="Få styr på arven"
                        illustration={`${PATHS.PUBLIC_BUCKET}/illustrations/min_arv.png`}
                    />
                </Module>
            </div>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className={`la-modal referral-survey${loading ? " referral-survey--loading" : ""}`}>
                    <h3>Hvor har du hørt om Adjø?</h3>
                    <button className="Button" onClick={() => handleSubmit(1)} disabled={loading}>
                        Sociale medier
                    </button>
                    <button className="Button" onClick={() => handleSubmit(2)} disabled={loading}>
                        Avis / radio / TV
                    </button>
                    <button className="Button" onClick={() => handleSubmit(3)} disabled={loading}>
                        Invitation fra mine kære
                    </button>
                    <button className="Button" onClick={() => handleSubmit(4)} disabled={loading}>
                        Andet
                    </button>
                    <div className="la-modal__actions">
                        <button
                            className="Button Button--flat"
                            aria-labelledby="close-modal"
                            onClick={() => setModalOpen(false)}
                            disabled={loading}
                        >
                            {loading ? "Vent..." : "Luk"}
                        </button>
                    </div>
                </div>
            </Dialog>
        </>
    )
}

export default WithUser(WithCategories(WithWishes(PageHome)))
