import { useState } from "react"
import { Dialog } from "@mui/material"

import Module from "../Module/Module"
import NewLinkForm from "./NewLinkForm"

import icons from "../../assets/icons.svg"
const plusPersonIcon = icons + "#plusperson"
const shareIcon = icons + "#share"

const NewLink = () => {
    const [modalOpen, setModalOpen] = useState(false)

    return (
        <div className="cell link-preview-wrapper">
            <Module color="black" handleClick={() => setModalOpen(true)}>
                <div className="link-preview link-preview--new">
                    <div>
                        <p>Tilføj person</p>
                        <div className="link-preview__status">
                            <svg>
                                <use xlinkHref={shareIcon} />
                            </svg>
                            <span>Del dine ønsker</span>
                        </div>
                    </div>
                    <svg className="link__img">
                        <use xlinkHref={plusPersonIcon} />
                    </svg>
                </div>
            </Module>
            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="la-modal">
                    <NewLinkForm modalOpen={modalOpen} onCancel={() => setModalOpen(false)} />
                </div>
            </Dialog>
        </div>
    )
}

export default NewLink
