import { Component } from "react"
import { TextField } from "@mui/material"

import { cloudfunctions, sendEmailVerification } from "../../firebase"
import LinkSent from "./LinkSent"
import VerificationEmailSent from "../Profile/VerificationEmailSent"
import WithUser from "../hoc/WithUser"

class NewLinkForm extends Component {
    initialState = {
        name: "",
        email: "",
        loading: false,
        verificationEmailSent: false,
        createdLink: undefined,
        err: undefined,
    }

    state = this.initialState

    handleChange = (event) => this.setState({ [event.target.name]: event.target.value })

    handleSubmit = async (event) => {
        event.preventDefault()

        if (this.state.loading || this.state.createdLink) {
            return
        }

        this.setState({ loading: true, createdLink: undefined, err: undefined })

        try {
            const response = await cloudfunctions.httpsCallable("link_create_v5")({
                inviteeEmail: this.state.email,
                inviteeName: this.state.name,
            })

            this.setState({
                name: "",
                email: "",
                createdLink: response.data,
            })
        } catch (err) {
            this.setState({ err: err.details })
        } finally {
            this.setState({ loading: false })
        }
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (this.props.modalOpen !== prevProps.modalOpen) {
            if (!this.props.modalOpen && this.state.success) {
                // Reset form when closing modal after success
                this.setState(this.initialState)
            }
        }
    }

    handleCancel = () => {
        this.setState(this.initialState, () => {
            this.props.onCancel()
        })
    }

    sendEmailVerificationClicked = async () => {
        this.setState({ loading: true })
        await sendEmailVerification()
        this.setState({ loading: false, verificationEmailSent: true })
    }

    render() {
        const { modalOpen } = this.props

        const { loading, name, email, createdLink, verificationEmailSent, err } = this.state

        if (verificationEmailSent) {
            return <VerificationEmailSent handleClick={this.handleCancel} />
        } else if (createdLink) {
            return <LinkSent email={createdLink.invitee_email} handleClick={this.handleCancel} />
        } else if (err) {
            return (
                <>
                    <h2>{err.title}</h2>
                    <p>{err.msg}</p>
                    {err.title === "Godkend din e-mail" && (
                        <div className="info">
                            <button disabled={loading} onClick={this.sendEmailVerificationClicked}>
                                {loading ? "Vent..." : "Klik her hvis du ikke har fået nogen e-mail"}
                            </button>
                        </div>
                    )}
                    <div className="la-modal__actions">
                        <button className="Button" disabled={loading} onClick={() => this.setState({ err: undefined })}>
                            OK
                        </button>
                    </div>
                </>
            )
        }

        return (
            <form className="la-form" onSubmit={this.handleSubmit}>
                <h2>Send invitation til deling</h2>
                <p>Angiv navn og e-mail på den person du vil dele dine ønsker med.</p>
                <TextField
                    autoFocus
                    variant="outlined"
                    required={modalOpen}
                    value={name}
                    onChange={this.handleChange}
                    label="Personens navn"
                    name="name"
                    type="text"
                    margin="dense"
                />
                <TextField
                    variant="outlined"
                    required={modalOpen}
                    value={email}
                    autoComplete="email"
                    onChange={this.handleChange}
                    label="Personens e-mail"
                    name="email"
                    type="email"
                    margin="normal"
                />
                <div className="la-modal__actions">
                    <button
                        type="button"
                        className="Button Button--flat"
                        aria-labelledby="close-modal"
                        onClick={this.handleCancel}
                    >
                        Fortryd
                    </button>
                    <input
                        type="submit"
                        className="Button"
                        value={loading ? "Vent..." : "Send invitation"}
                        disabled={loading}
                        style={{ cursor: loading ? "wait" : "pointer" }}
                    />
                </div>
            </form>
        )
    }
}

export default WithUser(NewLinkForm)
