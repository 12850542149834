import { firestore } from "../firebase"

export const getQ11ePreview = async (name: string, version: number): Promise<IQ11ePreview | undefined> => {
    const docId = name + "_" + version
    const query = firestore.collection("q11e").doc(docId)

    const docSnap = await query.get()
    if (docSnap.exists) {
        const q11e = docSnap.data() as IQ11e
        return {
            id: q11e.id,
            name: q11e.name,
            version: q11e.version,
            displayName: q11e.displayName,
            color: q11e.color,
            intro1: q11e.intro1,
            intro2: q11e.intro2,
        }
    } else {
        console.error("No q11e matching name and version was found.")
    }
}

export const getQuestion = async (q11eId: string, questionId: string): Promise<IQ11eQuestion | undefined> => {
    const docSnap = await firestore.collection("q11e").doc(q11eId).collection("questions").doc(questionId).get()

    if (docSnap.exists) {
        return docSnap.data() as IQ11eQuestion
    }
}

export const getAnswers = async (q11eId: string, questionId: string): Promise<IQ11eAnswer[]> => {
    const docSnaps = await firestore
        .collection("q11e")
        .doc(q11eId)
        .collection("answers")
        .where("sourceQuestionId", "==", questionId)
        .get()

    let answers: IQ11eAnswer[] = []
    docSnaps.forEach((docSnap) => {
        answers.push(docSnap.data() as IQ11eAnswer)
    })

    return answers
}

export const getStorefront = async (q11eId: string): Promise<IQ11eStorefront | undefined> => {
    const docSnap = await firestore.collection("q11e").doc(q11eId).collection("storefront").doc("v1").get()

    if (docSnap.exists) {
        return docSnap.data() as IQ11eStorefront
    }
}

export const getStore = async (q11eId: string): Promise<IQ11eStore | undefined> => {
    const docSnap = await firestore.collection("q11e").doc(q11eId).collection("store").doc("v1").get()

    if (docSnap.exists) {
        return docSnap.data() as IQ11eStore
    }
}
