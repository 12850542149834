import { MouseEvent, useState } from "react"
import { NavLink, useLocation } from "react-router-dom"
import { ArrowBackRounded, MoreHorizRounded } from "@mui/icons-material"

import NavItemsMain from "./NavItemsMain"
import NavItemsMore from "./NavItemsMore"

const Nav = () => {
    const location = useLocation()
    const [isMore, setMore] = useState(false)

    const closeSidebar = (event: MouseEvent) => {
        const target = event.target as HTMLElement
        if (target.closest("Button")) return

        const sidebar = document.getElementById("Sidebar")!
        if (sidebar.classList.contains("Sidebar--open")) {
            sidebar.classList.remove("Sidebar--open")
        }

        const header = document.getElementById("Header")!
        if (header.classList.contains("Sidebar--open")) {
            header.classList.remove("Sidebar--open")
        }
    }

    const handleMoreClicked = () => setMore(!isMore)

    return (
        <nav className="Nav" onClick={closeSidebar}>
            {isMore ? (
                <>
                    <NavLink
                        to={location.pathname}
                        className="NavItem NavItem--lightgrey rounded-borders"
                        onClick={handleMoreClicked}
                    >
                        <ArrowBackRounded />
                        <span>Tilbage</span>
                    </NavLink>
                    <NavItemsMore />
                </>
            ) : (
                <>
                    <NavItemsMain />
                    <NavLink
                        to={location.pathname}
                        className="NavItem NavItem--lightgrey rounded-borders"
                        onClick={handleMoreClicked}
                    >
                        <MoreHorizRounded />
                        <span>Mere</span>
                    </NavLink>
                </>
            )}
        </nav>
    )
}

export default Nav
