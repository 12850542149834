import { Link, useParams } from "react-router-dom"
import { ArrowBackRounded, ArrowForwardRounded } from "@mui/icons-material"

import { slugify } from "../../util"

import WithCategories from "../hoc/WithCategories"
import Intro from "../Intro/Intro"
import QuestionList from "../Questions/QuestionList"
import { BYE, PUBLIC_BUCKET } from "../../routes/paths"

interface Params {
    parentTopicParam: string
    topicParam: string
}

interface Props {
    categories: any[]
}

const Topic = ({ categories }: Props) => {
    const { parentTopicParam, topicParam } = useParams<Params>()
    const parentTopic = categories.find((category) => slugify(category.title) === parentTopicParam)
    const category = parentTopic
    const topic = parentTopic.topics.find((topic: any) => slugify(topic.title) === topicParam)

    // Prev / next topic
    const indexOfTopic = parentTopic.topics.indexOf(topic)
    const previousTopic = parentTopic.topics[indexOfTopic - 1]
    const nextTopic = parentTopic.topics[indexOfTopic + 1]
    const slug = BYE
    const prevTopicLink = previousTopic
        ? slug + parentTopicParam + "/" + slugify(previousTopic.title)
        : slug + parentTopicParam + "/"
    const prevTopicTxt = previousTopic ? "Tilbage" : "Til oversigten"
    const nextTopicLink = nextTopic
        ? slug + parentTopicParam + "/" + slugify(nextTopic.title)
        : slug + parentTopicParam + "/"
    const nextTopicTxt = nextTopic ? "Næste emne" : "Til oversigten"

    return (
        <>
            {topic ? (
                <>
                    <Intro
                        title={topic.title}
                        text={topic.desc}
                        illustration={`${PUBLIC_BUCKET}/scribbles/svg/scribble_${topic.img}.svg`}
                        illustrationPad
                        color={"yellow"}
                    />
                    <QuestionList questions={topic.questions} categoryId={category.id} topic={topic} />
                    <div className="Topic__nav">
                        <Link className="Topic__nav--prev Button Button--outline Button--icon" to={prevTopicLink}>
                            <ArrowBackRounded style={{ fontSize: 30 }} />
                            <span>{prevTopicTxt}</span>
                        </Link>
                        <Link className="Topic__nav--next Button Button--icon" to={nextTopicLink}>
                            <span>{nextTopicTxt}</span>
                            <ArrowForwardRounded style={{ fontSize: 30 }} />
                        </Link>
                    </div>
                </>
            ) : (
                "Siden kunne ikke findes..."
            )}
        </>
    )
}

export default WithCategories(Topic)
