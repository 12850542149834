import { ReactChild } from "react"
import { NavLink } from "react-router-dom"
import classNames from "classnames"

import icons from "../../assets/icons.svg"

const renderIcon = (icon: string) => {
    switch (icon) {
        case "home":
            return icons + "#home"
        case "flower":
            return icons + "#flower"
        case "suitcase":
            return icons + "#suitcase"
        case "heart":
            return icons + "#heart"
        case "inheritance":
            return icons + "#inheritance"
        case "user":
            return icons + "#user"
        case "dots":
            return icons + "#dots"
        case "print":
            return icons + "#print"
        case "about":
            return icons + "#about"
        case "partners":
            return icons + "#partners"
        case "collabs":
            return icons + "#collab"
        case "policy":
            return icons + "#policy"
        case "trading-terms":
            return icons + "#trading-terms"
        case "feedback":
            return icons + "#feedback"
        default:
            return icons + "#home"
    }
}

interface Props {
    url: string
    icon?: string
    children?: ReactChild
    title: string
    color: string
    isExact?: boolean
    isActive?: (match: any, location: any) => boolean
}

const NavItem = ({ url, icon, children, title, color, isExact, isActive }: Props) => {
    const navItemClasses = classNames("NavItem rounded-borders", {
        "NavItem--red": color === "red",
        "NavItem--pink": color === "pink",
        "NavItem--purple": color === "purple",
        "NavItem--yellow": color === "yellow",
        "NavItem--green": color === "green",
        "NavItem--lightblue": color === "lightblue",
        "NavItem--lightgrey": color === "lightgrey",
    })

    return (
        <NavLink to={url} className={navItemClasses} exact={isExact} isActive={isActive} activeClassName="current-page">
            {children ||
                (icon && (
                    <svg>
                        <use xlinkHref={renderIcon(icon)} />
                    </svg>
                ))}
            <span>{title}</span>
        </NavLink>
    )
}

export default NavItem
