import { useEffect, useState } from "react"
import { useRouteMatch } from "react-router-dom"
import { Dialog } from "@mui/material"

import LinkPreview from "./LinkPreview"
import NewLink from "./NewLink"
import PendingLink from "./PendingLink"
import WithLinks from "../hoc/WithLinks"

interface Props {
    links: ILink[]
}

const LinkList = ({ links }: Props) => {
    const match = useRouteMatch()
    const [modalOpen, setModalOpen] = useState(false)
    const [link, setLink] = useState<ILink>()

    useEffect(() => {
        if (!modalOpen) {
            setLink(undefined)
        }
    }, [modalOpen])

    useEffect(() => {
        if (link) {
            const newLink = links.find((l) => l.id === link.id)
            setLink(newLink)
        }
    }, [links, link])

    const showPendingLink = (link: ILink) => {
        setLink(link)
        setModalOpen(true)
    }

    return (
        <>
            <div className="grid-x grid-padding-x--small">
                {links
                    .filter((link) => link.status === 0)
                    .map((link) => (
                        <LinkPreview key={link.id} link={link} onClick={showPendingLink} />
                    ))}
                {links
                    .filter((link) => link.status !== 0)
                    .map((link) => (
                        <LinkPreview key={link.id} link={link} url={`${match.url}/${link.id}`} />
                    ))}
                <NewLink />
            </div>

            <Dialog open={modalOpen} onClose={() => setModalOpen(false)}>
                <div className="la-modal">
                    {link && <PendingLink link={link} onDismiss={() => setModalOpen(false)} />}
                </div>
            </Dialog>
        </>
    )
}

export default WithLinks(LinkList)
