import classNames from "classnames"

import usePublishedCharities from "../hooks/Charity/usePublishedCharities"
import useCharityDonations from "../hooks/Charity/useCharityDonations"
import useOtherCharities from "../hooks/Charity/useOtherCharities"
import useTotalCharityDonationAmount from "../hooks/Charity/useTotalCharityDonationAmount"
import Module from "../Module/Module"
import { CHARITIES, PUBLIC_BUCKET } from "../../routes/paths"
import WithUser from "../hoc/WithUser"

const isPhone = process.env.REACT_APP_IS_PHONE

interface Props {
    user: any
}

const CharityTopicCard = ({ user }: Props) => {
    const publishedCharities = usePublishedCharities()
    const charityDonations = useCharityDonations(user?.uid)
    const otherCharities = useOtherCharities(user.uid)
    const totalCharityDonationAmount = useTotalCharityDonationAmount(
        publishedCharities,
        charityDonations,
        otherCharities
    )

    const indicatorClasses = classNames({
        "TopicCard__indicator--phone": isPhone,
        TopicCard__indicator: !isPhone,
        "rounded-borders": !isPhone,
        "bg-green": totalCharityDonationAmount > 0,
        "bg-purple": totalCharityDonationAmount === 0,
    })

    return (
        <Module url={CHARITIES} color="white">
            <div className="TopicCard">
                <div className="TopicCard__content">
                    <h3 className={isPhone && "h3--phone"}>Velgørenhed</h3>
                    <div className={indicatorClasses}>
                        <span>{totalCharityDonationAmount} DKK</span>
                    </div>
                </div>
                <figure className="TopicCard__illustration">
                    <img src={`${PUBLIC_BUCKET}/scribbles/svg/scribble_velgorenhed.svg`} alt="" />
                </figure>
            </div>
        </Module>
    )
}

export default WithUser(CharityTopicCard)
