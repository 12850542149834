import { useEffect, useState } from "react"
import { firestore } from "../../../firebase"

const useQ11eLabelState = (q11eName: string, q11eVersion: string, userId?: string): IQ11eLabelState | undefined => {
    const [q11eLabelState, setQ11eLabelState] = useState<IQ11eLabelState>()

    useEffect(() => {
        if (!userId) return

        const q11eId = q11eName + "_" + q11eVersion

        const subscribe = () =>
            firestore
                .collection("users")
                .doc(userId)
                .collection("q11eState")
                .doc(q11eId)
                .onSnapshot((docSnap) => {
                    const labelState = docSnap.data() as IQ11eLabelState
                    setQ11eLabelState(labelState)
                })

        return subscribe()
    }, [q11eName, q11eVersion, userId])

    return q11eLabelState
}

export default useQ11eLabelState
