import { useParams } from "react-router-dom"
import classNames from "classnames"
import { auth } from "../../firebase"
import icons from "../../assets/icons.svg"
import { setAnswer } from "../../api/QuestionnaireRouteManager"

interface Params {
    questionnaireId: string
    questionId: string
}

interface Props {
    answer: IAnswer
    selected: boolean
}

const QuestionnaireAnswerRadio = ({ answer, selected }: Props) => {
    const { questionnaireId, questionId } = useParams<Params>()

    const radioOff = icons + "#radio-off"
    const radioOn = icons + "#radio-on"

    const handleClick = () => {
        setAnswer(auth.currentUser!.uid, questionnaireId, questionId, answer.id)
    }

    const classes = classNames("questionnaire-answer", {
        "questionnaire-answer--selected": selected,
    })

    return (
        <div className={classes} onClick={handleClick}>
            <label>{answer.txt}</label>
            <svg>
                <use xlinkHref={selected ? radioOn : radioOff} />
            </svg>
        </div>
    )
}

export default QuestionnaireAnswerRadio
