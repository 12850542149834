import { Error } from "./model"

export const resolveError = (errorCode: string): Error => {
    if (errorCode === "auth/wrong-password" || errorCode === "auth/user-not-found") {
        return {
            title: "Ugyldigt login",
            txt: "Den indtastede e-mail og/eller adgangskode er forkert.\nPrøv igen.",
        }
    } else if (errorCode === "auth/too-many-requests") {
        return {
            title: "Fejl",
            txt: "Du har tastet forkert adgangskode for mange gange.\nVent 5 minutter før du kan forsøge igen.",
        }
    } else {
        return {
            title: "Uventet fejl",
            txt: "Der skete en uventet fejl.\nKontakt venligst vores support afdeling.",
        }
    }
}
