import { useState } from "react"
import { Dialog } from "@mui/material"
import classNames from "classnames"

import InlineLoader from "../Loader/InlineLoader"
import Guide from "../Guide/Guide"

interface Props {
    isAwaitingFile: boolean
    handlePrintClicked: () => void
}

const FremtidsfuldmagtSteps = ({ isAwaitingFile, handlePrintClicked }: Props) => {
    const [guideName, setGuideName] = useState<string>()
    const [guideModalOpen, setGuideModalOpen] = useState(false)

    const handleGuideClicked = (guideName: string) => {
        setGuideName(guideName)
        setGuideModalOpen(true)
    }

    const handleGuideClosed = () => {
        setGuideName(undefined)
        setGuideModalOpen(false)
    }

    const printBtnClasses = classNames("Button Button--green q11e-storefront__btn", {
        "Button--loading-inline": isAwaitingFile,
    })

    return (
        <>
            <h3>Næste skridt?</h3>
            <p className="q11e-storefront__txt">Din fremtidsfuldmagt er klar. Følg nu disse 3 trin:</p>
            <button className={printBtnClasses} onClick={handlePrintClicked}>
                {(isAwaitingFile && <InlineLoader />) || (
                    <>
                        <span className="button-enumeration">1.</span>
                        <span>Gem og åbn dokument</span>
                    </>
                )}
            </button>
            <div className="q11e-storefront__dots">
                <span>&#183;</span>
                <span>&#183;</span>
                <span>&#183;</span>
            </div>
            <button
                className="Button Button--green q11e-storefront__btn"
                onClick={() => handleGuideClicked("fremtidsfuldmagt_tinglysning")}
            >
                <span className="button-enumeration">2.</span>
                <span>Tinglys dokument</span>
            </button>
            <div className="q11e-storefront__dots">
                <span>&#183;</span>
                <span>&#183;</span>
                <span>&#183;</span>
            </div>
            <button
                className="Button Button--green q11e-storefront__btn"
                onClick={() => handleGuideClicked("fremtidsfuldmagt_notar")}
            >
                <span className="button-enumeration">3.</span>
                <span>Underskriv foran notar</span>
            </button>
            {guideName && (
                <Dialog className="guide-dialog" open={guideModalOpen} onClose={handleGuideClosed}>
                    <Guide open={guideModalOpen} name={guideName} onModalClose={handleGuideClosed} />
                </Dialog>
            )}
        </>
    )
}

export default FremtidsfuldmagtSteps
