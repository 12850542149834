import { WishesContext } from "../../providers/WishesProvider"

// This is to give the actual component a name when debugging
const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component"
}

const WithWishes = (Component) => {
    const WrappedComponent = (props) => {
        return <WishesContext.Consumer>{(wishes) => <Component wishes={wishes} {...props} />}</WishesContext.Consumer>
    }
    WrappedComponent.displayName = `WithWishes(${getDisplayName(WrappedComponent)})`
    return WrappedComponent
}

export default WithWishes
