import { LinksContext } from "../../providers/LinksProvider"

const getDisplayName = (WrappedComponent) => {
    return WrappedComponent.displayName || WrappedComponent.name || "Component"
}

const WithLinks = (Component) => {
    const WrappedComponent = (props) => (
        <LinksContext.Consumer>{(links) => <Component links={links} {...props} />}</LinksContext.Consumer>
    )

    WrappedComponent.displayName = `WithLinks(${getDisplayName(WrappedComponent)})`

    return WrappedComponent
}

export default WithLinks
