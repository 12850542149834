import classNames from "classnames"

import Header from "../Header/Header"
import Sidebar from "../Sidebar/Sidebar"
import TabBar from "../TabBar/TabBar"

interface Props {
    children: any
}

const Dashboard = ({ children }: Props) => {
    const isPhone = process.env.REACT_APP_IS_PHONE
    const DashboardInnerClassNames = classNames("Dashboard__inner", {
        "Dashboard__inner--phone": isPhone,
    })

    return (
        <div className="Dashboard bg-lightergray">
            <Header />
            <Sidebar />
            <main className={DashboardInnerClassNames}>{children}</main>
            {isPhone && <TabBar />}
        </div>
    )
}

export default Dashboard
